<template>
    <div class="p-6 lg:p-8 bg-white rounded-lg mb-8">
        <p class="text-xl font-bold text-primary-500 mb-2.5">V. Medication History 服用中のお薬</p>
        <!-- <p class="text-xs font-bold text-primary-500 mb-6">病歴</p> -->
        <p class="text-darkgray-800">If you are taking any medication, please fill out the table below</p>
        <p class="text-xs text-darkgray-800 mb-5">薬を服用している場合は、以下の表に記入してください。</p>
        <div 
            class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-4 bg-primary-50 rounded-2xl p-5 lg:p-7 mb-2" 
            v-for="(history, index) in item" :key="index"
        >
            <div v-if="index >= '1'" class="col-span-full text-right">
                <small v-if="!disableInputs" @click="remove(index)" class="text-red-500 cursor-pointer text-sm">Remove</small>
            </div>
            <div class="col-span-full lg:col-span-4">
                <text-input
                    v-model="history.medication_name"
                    id="name-of-medication"
                    type="text"
                    placeholder="Name of Medication / 薬の名前"
                    :disabled="disableInputs"
                />
            </div>
            <div class="col-span-full lg:col-span-4">
                <text-input
                    v-model="history.illness_symptom_name"
                    id="name-of-illness"
                    type="text"
                    placeholder="Name of illness/symptom / 病名・症状"
                    :disabled="disableInputs"
                />
            </div>
            <div class="col-span-full lg:col-span-4">
                <MultiselectInput
                    :disabled="disableInputs"
                    id="schedule-of-intake"
                    v-model="history.intake_schedule"
                    placeholder="Schedule of intake / 服薬の頻度・スケジュール"
                    :options="schedules"
                />
            </div>
        </div>
        <p v-if="!disableInputs" @click="addRow" class="font-bold text-primary-500 hover:text-primary-700 transition cursor-pointer text-sm w-fit">
            Add another +
        </p>
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import { ref } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: false
    },
})

const item = ref(props.form)

const addRow = () => {
    item.value.push({
        medication_name: null,
        illness_symptom_name: null,
        intake_schedule: null,
    })
}

const remove = (index : string) => {
    item.value.splice(index, 1);
}

const schedules = (['1', '2', '3', '4', '5'])


</script>