<template>
    <portal-layout
    :defaultTitle="false">
        <template #title>
            <h1 class="text-4xl text-primary-700 font-bold">Japan Standard Health Checkup</h1> 
        </template>
        <div class="bg-white rounded-lg mb-8">
            <p class="text-xl font-bold lg:mb-5 px-5 pt-5">Schedule Logs</p>
            <div v-if="scheduleLogs.length > 0" class="space-y-4 divide-y divide-gray-40 lg:divide-y-0">
                <div v-for="(scheduleLog, index) in scheduleLogs" :key="index" class="flex flex-wrap lg:space-x-10 p-5 lg:pt-0">
                    <div class="w-full lg:w-[150px]">
                        <p class="font-bold">{{ formatDate(scheduleLog.created_date, false) }}</p>
                    </div>
                    <div class="w-full lg:w-[calc(100%-190px)]">
                        <p class="text-[#323232]">{{ formatRescheduledDateText(scheduleLog) }}</p>
                        <p class="text-[#323232]">Reason: {{ scheduleLog.description ?? "N/A" }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="p-5">
                This appointment has not been rescheduled.
            </div>
        </div>
        <div class="flex items-center space-x-2 font-bold text-[#23A2B4] cursor-pointer">
            <ChevronLeftIcon class="w-5 h-5" />
            <p @click="returnToPage()">Return to Appointment Information</p>
        </div>

        
        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import { format } from 'date-fns';

import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import patient from "@/classes/UserData";

interface scheduleLog {
    new_booking_date: string | Date,
    new_booking_start_time: string,
    new_booking_end_time: string,
    created_date: string | Date,
    description: string,
}

const route = useRoute();

const loader = ref(false);
const showError = ref(false);
const errorMsg = ref();

const booking_type = ref(route.query.booking_type);
const booking_id_of_coporate_booking = ref(route.query.booking_id_of_coporate_booking as unknown as string);
const bookingId = ref(route.params.bookingId as unknown as string);
const scheduleLogs = ref<scheduleLog[]>([]);

const formatRescheduledDateText = (scheduleLog: scheduleLog) => {
    let textDisplay = "N/A";
    
    if(scheduleLog.new_booking_start_time && scheduleLog.new_booking_end_time) {
        textDisplay = `This appointment has been rescheduled to ${formatDate(scheduleLog.new_booking_date)} at ${formatTime(scheduleLog.new_booking_start_time)} - ${formatTime(scheduleLog.new_booking_end_time)} by the Admin.`;
    } else {
        textDisplay = `This appointment has been rescheduled to ${formatDate(scheduleLog.new_booking_date)} by the Admin.`;
    }

    return textDisplay;
}

const formatDate = (date: string | Date, longFormat = true) => {
    if(date) {
        if(longFormat) {
            return format(new Date(date), 'LLLL dd, yyyy');
        } else {
            return format(new Date(date), 'LLL dd, yyyy');
        }
    }
    return 'TBA';
}

const formatTime = (time : string) => {
    return time ? format(new Date(time), 'h:mm a') : 'TBA';
}

const returnToPage = () => {
    if(booking_type.value == 'Individual') {
        window.location.assign(`/appointment/show/${bookingId.value}`);
    } else {
        window.location.assign(`/appointment/show/${booking_id_of_coporate_booking.value}`);
    }
}

const fetchScheduleLogs = () => {
    loader.value = true
    
    patient.getAppointmentScheduleLogs(booking_type.value, bookingId.value)
        .then((result: any) => {
            loader.value = false;

            if(result.data.response.length > 0) {
                scheduleLogs.value = result.data.response;
                
                if(result.data.response.result_status == 500) {
                    showError.value = true;
                    errorMsg.value = "Something went wrong. Please try again later";
                }
            }
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted(() => { fetchScheduleLogs(); })
</script>