<template>
    <portal-layout
    :notifCount="0"
    :defaultTitle="false">
        <template #title>
            <div class="w-full flex justify-between items-center">
                <h1 class="text-4xl text-primary-700 font-bold">Notifications</h1> 
                <div class="relative">
                    <BellIcon class="w-7 h-7 cursor-pointer" @click.prevent="markReadAll" />
                    <div v-if="unreadCounts > 0" class="has-notif w-4 h-4 absolute right-0 top-0 border-4 border-white rounded-full bg-red-700"></div>
                </div>
            </div>
        </template>
        <div class="bg-white rounded-lg min-h-[calc(100%-100px)]">
            <template v-for="(item, index) in items" :key="index">
                <div
                class="p-5 border-b last:border-b-0 border-[#EEEEEE] relative notification-card cursor-pointer bg-white"
                :class="item.read_at ? '' : 'new'"
                @click.prevent="markRead(item.id)"
                >
                    <p class="text-xs text-gray-400 font-bold mb-2 date">{{ item.created_at }}</p>
                    <p class="text-gray-400 title mb-2">{{ item.data.title }}</p>
                    <p class="desc text-gray-400">{{ item.data.message }}</p>
                </div>
            </template>
        </div>

        
    <PageLoader v-if="loader" />

    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import { BellIcon } from '@heroicons/vue/24/outline';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import PatientNotification from "@/classes/PatientNotification";

import { useRouter } from 'vue-router';
import { onMounted, ref, computed } from 'vue';

const router = useRouter();

// Data
const loader    = ref(false);
const showError = ref(false);
const errorMsg  = ref(null);

const items = ref(null);
const item = ref(null);
const unreadCounts = ref(0);

// Methods
const indexPatientNotification = () => {
    loader.value = true;

    PatientNotification.indexPatientNotification()
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const markRead = (id) => {
    loader.value = true;

    PatientNotification.readPatientNotification(id)
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            item.value = response.data.notification;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
            
            // Redirect After Read
            redirect(item.value);
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}


const markReadAll = () => {
    loader.value = true;

    PatientNotification.readAllPatientNotification()
        .then((response: any) => {
            loader.value = false;

            items.value = response.data.notifications;
            unreadCounts.value = items.value.filter(x => x.read_at == null).length;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const redirect = (item : any) => {
    if(item.data.subject_type) {
        switch (item.data.subject_type) {
            case 'Booking':
                window.location.replace(`/appointment/show/${item.data.subject_id}`);
                break;
            case 'Pending Booking':
                window.location.replace(`/booking`);
                break;
            case 'Patient':
                window.location.replace(`/account-details`);
                break;
            default:
                break;
        }
    }
}

onMounted( () => {
    indexPatientNotification();
});

</script>