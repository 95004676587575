<template>
    <user-layout>
        <div class="w-[90%] lg:w-[390px] mx-auto py-14">
            <div class="mb-4 space-y-4 text-center">
                <h1 class="font-bold text-primary-500">Confirm Booking</h1>
                <!-- @TODO : update copywriting -->
                <!-- <p class="text-darkgray-800">Praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.</p> -->
            </div>
            <div>
                <BookingSummary 
                totalColor="text-primary-500"
                @book-now="submitBooking"
                />
                <p @click.prevent="router.push('/booking/select-date-and-time')" class="mt-4 text-center cursor-pointer text-primary-500">Edit Appointment</p>
            </div>
        </div>

        <GeneralModal
        width="w-[548px]"
        check-icon
        title="Booking Confirmed!"
        action-text="View Appointment"
        cancel-text="Book another"
        :show="show"
        @close="bookAnother"
        @confirm="viewBoking" 
        >
            <template #content>
                <p>Your booking appointment has been received.</p>
                <br>
                <p>A medical questionnaire has been provided in your appointment details, which you may access by clicking the button below. Please ensure that this form has been filled before your appointment.</p>
                <br>
                <p>Thank you!</p>
            </template>
        </GeneralModal>
        
    <PageLoader v-if="loader" />
    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    :errors="errors"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import BookingSummary from '@/components/cards/BookingSummary.vue';
import GeneralModal from '@/components/GeneralModal.vue';
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import Storage from '@/helpers/storage';
import booking from "@/classes/Booking";
import { useBookingStore } from '@/store/booking';
import { getActivePinia } from "pinia"

// Router Instance
const router = useRouter();

// State Instance
const bookingStoreState = useBookingStore();

// Local Storage Instance
const bookingLocalStorage = new Storage();
const bookingLocalStoragePayload = ref(JSON.parse(bookingLocalStorage.get('bookingPayload')));
const bookingLocalStorageSummary = ref(JSON.parse(bookingLocalStorage.get('bookingSummary')));
const bookingLocalStorageCanSubmitBooking = ref(JSON.parse(bookingLocalStorage.get('canSubmitBooking')));

// Data
const loader = ref(false);
const showError = ref(false);
const errorMsg = ref(null);
const errors      = ref([])
const show = ref(false);
const bookingId = ref(null)

const submitBooking = () => {
    loader.value = true;
    
    booking.submitBooking(bookingStoreState.bookingPayload)
    .then((result: any) => {
            loader.value = false;
            
            
            if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value = "Something went wrong. Please try again later";
                
                return;
            }
            
            show.value = true;
            bookingId.value = result.data.response.response.booking_id;
            
            // Clear State and Local Storage After Success Booking
            bookingLocalStorage.clearItems(['bookingPayload', 'bookingSummary', 'canSubmitBooking']);
            getActivePinia()._s.forEach(store => store.$reset());
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 422) {
                errors.value = err.response.data.errors;
            }

            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchDrafts = () => {
    bookingStoreState.bookingPayload = bookingLocalStoragePayload.value || bookingStoreState.bookingPayload;
    bookingStoreState.bookingSummary = bookingLocalStorageSummary.value || bookingStoreState.bookingSummary;
    bookingStoreState.canSubmitBooking = bookingLocalStorageCanSubmitBooking.value || bookingStoreState.canSubmitBooking;
}

const bookAnother = () => {
    window.location.href = '/booking'
}
const viewBoking = () => {
    show.value = false
    window.location.replace(`/appointment/show/${bookingId.value}`);
}


onMounted( () => {
    fetchDrafts();
});

</script>