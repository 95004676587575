<template>
    <booking-layout
        previous-step="2"
        step="3"
        @back="router.push('/booking/choose-package')"
        hide-back-button
    >
        <div class="flex flex-col" >
            <div 
                v-if="bookingStoreState.disableDates.length != 7"
                class="flex flex-wrap lg:mb-10"
            >
                <div class="w-full lg:w-7/12 lg:pr-4">
                    <CalendarPicker
                        v-if="selectedDate"
                        :min-date="minDate"
                        :fully-booked-dates="fullyBookedDates"
                        hide-offset-dates
                        highlight-disabled-days
                        @updateMonth="updateMonth"
                        :disabledWeekDays="bookingStoreState.disableDates"
                        v-model="selectedDate"
                    />
                    <div class="flex items-center px-5 py-4 mt-4 rounded-2xl bg-primary-50">
                        <InformationCircleIcon class="w-5 h-5 mr-2 text-primary-500"/>
                        <p class="text-sm">Slots available: <strong>{{ slotsAvailable }}</strong></p>
                    </div>
                </div>
                <div class="w-full mt-4 lg:mt-0 lg:w-5/12 space-y-3.5">
                    <AccordionComponent
                    v-for="(timeSlot, index) in timeSlots"
                    :key="index"
                    :disabled="timeSlot.reduce((a, b) => a + b.available_slots, 0) == 0 || timeSlot.every(obj => obj.is_blocked === true)"
                    center-title>
                        <template #title>
                            <p class="text-sm font-bold">{{ index }}</p>
                        </template>
                        <template #content>
                            <div class="grid grid-cols-2 gap-4">
                                <radio-group
                                label-class="text-sm"
                                :options="timeSlot"
                                v-model="selectedTime"
                                name="options"
                                />
                            </div>
                        </template>
                    </AccordionComponent>
                </div>
            </div>

            <div 
                v-else
                class="flex flex-col"
            >
                <div class="flex flex-wrap lg:mb-10">
                    <div class="w-full lg:w-7/12 lg:pr-4">
                        <p class="text-xl ">
                            <b class="text-red-600"> Error: </b>The selected package or service(s) has no common available dates for booking. Please book package or service(s) individually to be able to proceed.
                        </p>
                    </div>
                </div>
            </div>
            <div class="hidden w-full mt-auto lg:block ">
                <button-component
                secondary
                :show-icon="false"
                text="Go Back" 
                @click="router.push('/booking/choose-package')"
                />
            </div>
        </div>
        

        <PageLoader v-if="loader" />
        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </booking-layout>
</template>

<script setup lang="ts">
import BookingLayout from '@/layouts/BookingLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import AccordionComponent from '@/components/AccordionComponent.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue';
// import { ref } from 'vue';

import { InformationCircleIcon } from "@heroicons/vue/24/outline";

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import Storage from '@/helpers/storage';

import { useRouter } from 'vue-router';
import { onMounted, watch, ref, computed } from 'vue';

import booking from "@/classes/Booking";
import { useBookingStore } from '@/store/booking';
import { getDay, addDays } from 'date-fns'
import { daysOfTheWeek } from "@/helpers/PackageRiderHelper"
// Router Instance
const router = useRouter();

// State Instance
const bookingStoreState = useBookingStore();

// Local Storage Instance
const bookingLocalStorage = new Storage();
const bookingLocalStoragePayload = ref(JSON.parse(bookingLocalStorage.get('bookingPayload')));
const bookingLocalStorageSummary = ref(JSON.parse(bookingLocalStorage.get('bookingSummary')));

// Data
const loader = ref(false);
const showError = ref(false);
const errorMsg = ref(null);
const fullyBookedDates = ref();
const timeSlots = ref();
const slotsAvailable = ref();
const reservationCapacityCount = ref();
const selectedDate = ref();
const selectedTime = ref();
const disabledWeekDays = daysOfTheWeek.map((date) => date.value);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref<string|null>(null);
const to = ref<string|null>(null);

// Methods
const fetchTimeSlots = () => {
    loader.value = true;

    const payload = {
        branch_id: bookingStoreState.bookingPayload.branch_id,
        selected_date: new Date(selectedDate.value).toISOString().split('T')[0],
        from: from.value,
        to: to.value,
        package: bookingStoreState.bookingPayload.package_name,
        riders: bookingStoreState.bookingPayload.riders,
    }
    
    booking.getTimeSlots(payload)
        .then((result: any) => {
            loader.value = false
            fullyBookedDates.value = result.data.response.fully_booked_dates;         
            timeSlots.value = result.data.response.time_slots;         
            slotsAvailable.value = result.data.response.slots_available;         
            reservationCapacityCount.value = result.data.response.reservation_capacity_count;         
        })
        .catch((err) => {
            if(!('noSchedule' in err.response.data.errors)){
                loader.value = false;
                showError.value = true;
                errorMsg.value = err.response.data.message;
            } 
            
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}



const fetchDrafts = () => {
    bookingStoreState.bookingPayload = bookingLocalStoragePayload.value || bookingStoreState.bookingPayload;
    bookingStoreState.bookingSummary = bookingLocalStorageSummary.value || bookingStoreState.bookingSummary;

    selectedDate.value = bookingStoreState.bookingPayload.booking_date ? bookingStoreState.bookingPayload.booking_date : minAvailableDate();
    selectedTime.value = bookingStoreState.bookingPayload.booking_start_time && bookingStoreState.bookingPayload.booking_end_time ? `${bookingStoreState.bookingPayload.booking_start_time}-${bookingStoreState.bookingPayload.booking_end_time}` : null;

    bookingStoreState.bookingSummary.selectedDate = selectedDate.value;
    bookingStoreState.bookingSummary.selectedTime = selectedTime.value;

    if(!bookingStoreState.bookingPayload.package_id && !bookingStoreState.bookingPayload.patient_id) {
        bookingStoreState.bookingSummary.selectedDate = null;
        router.push('/booking/choose-package')
    }
}

const saveDrafts = () => {
    bookingLocalStorage.set('bookingPayload', JSON.stringify(bookingStoreState.bookingPayload));
    bookingLocalStorage.set('bookingSummary', JSON.stringify(bookingStoreState.bookingSummary));
}

const updateMonth = (val?:any = null):void => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch TimeSlots for Active Month
    fetchTimeSlots();
}

const fetchDisabledDates = () => {
    loader.value = true;

    const payload = {
        package: bookingStoreState.bookingPayload.package_name,
        riders: bookingStoreState.bookingPayload.riders,
    }
    
    booking.getGetAvailableDates(payload)
        .then(({data}: any) => {
            loader.value = false
            bookingStoreState.disableDates = data.response;  
        
            saveDrafts();
            router.push('/booking/select-date-and-time');
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const firstDayOfMonth = (val:any) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

const minAvailableDate = ():Date => {
    const availableDates = disabledWeekDays.filter((o) => bookingStoreState.disableDates.indexOf(o) === -1);

    return setMinAvailableDate(availableDates[0]);
} 

const setMinAvailableDate = (dayOfWeek: number, fromDate:Date = new Date()):Date => {
    const fromISODay = getDay(fromDate);
    
    const offsetDays = 1 + dayOfWeek + fromISODay;

    return addDays(fromDate, offsetDays);
} 

watch(() => selectedTime.value, (val) => {
    if(val) {
        bookingStoreState.bookingPayload.booking_start_time = val.split('-')[0];
        bookingStoreState.bookingPayload.booking_end_time = val.split('-')[1];
        bookingStoreState.bookingSummary.selectedTime = val;
        bookingStoreState.canSubmitBooking = true;

        saveDrafts();
    }
});

watch(() => selectedDate.value, () => {

    // Reset 
    selectedTime.value = null;
    bookingStoreState.bookingPayload.booking_start_time = null;
    bookingStoreState.bookingPayload.booking_end_time = null;
    bookingStoreState.bookingSummary.selectedTime = null;
    bookingStoreState.canSubmitBooking = false;

    // Get Timeslots for the month
    updateMonth();
    
    bookingStoreState.bookingSummary.selectedDate = new Date(selectedDate.value).toISOString().split('T')[0];
    bookingStoreState.bookingPayload.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];

    saveDrafts();
});

onMounted( () => {
    // fetchTimeSlots();
    fetchDrafts()
    fetchDisabledDates()    
});

</script>