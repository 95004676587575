<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">VIII. Specific Health Assessment / 特定健康診断</p>
        <!-- <p class="mb-6 text-xs font-bold text-primary-500"> 特定健康診断</p>
        <p>Check which is applicable to the following questions:</p>
        <p class="mb-6 text-xs">✓ 次の質問に当てはまるものにチェックを入れてください。</p> -->

        <!-- <p class="font-bold">B. Habit and Exercise</p>
        <p class="mb-4 text-xs">生活習慣と運動</p> -->

        <div class="pl-5 mb-6" v-for="question in item" :key="question.index">
            <div class="w-full mb-2 lg:mb-0 lg:pr-10">
                <ol class="list-decimal" :start="question.index+1">
                    <li>
                        <p v-html="question.description"></p>
                    </li>
                </ol>
                <p v-if="question.jpnlabel" class="text-xs" v-html="question.jpnlabel"></p>
            </div>
            <div class="w-full mt-2.5">
                <div class="flex lg:flex-row flex-col lg:items-center justify-between gap-2.5">
                    <radio-group
                        custom-class="w-1/2"
                        :name="question.label"
                        :isDisabled="disableInputs"
                        v-model="question.status"
                        :options="getOptions(question.index)"
                        :errors="errors[`specific_health_assessment.${question.index}.status`]"
                    />

                    <!-- :modelValue="disableInputs ? item.habit_and_exercise[item.habit_and_exercise[key]?.index]?.status : ''"
                        @update="MedicalService.addDataToArray(item.habit_and_exercise, i.label, $event, key)" -->
                </div>
                <p
                    v-if="errors[`specific_health_assessment.${question.index}.status`]" 
                    class="mt-2 text-sm text-red-600"
                >
                    {{ errors[`specific_health_assessment.${question.index}.status`][0] }}
                </p>
            </div>
        </div>

       <!--<div class="pl-5 mb-6">
            <ol class="list-decimal" start="6">
                <li>
                    <p>How fast do you usually eat? 人と比較して食べる速度が速いですか</p>
                </li>
            </ol>
            <div class="lg:columns-2 lg:block flex lg:flex-row flex-col lg:items-center justify-between lg:space-y-2.5 gap-2.5 mt-2.5">
                <radio-group
                    :isDisabled="disableInputs"
                    name="speed-when-eating-options"
                    :options="[
                        { id: 'Fast', label: 'Fast / 早い', value: 'Fast'},
                        { id: 'Slow', label: 'Slow / 遅い', value: 'Slow'},
                        { id: 'Normal', label: 'Normal / ふつう', value: 'Normal'},
                    ]"
                    :modelValue="item.eating_and_drinking[3]?.status"
                    @update="MedicalService.addDataToArray(item.eating_and_drinking, 'Average speed when eating a meal', $event, 3)"
                />
            </div>
        </div>

        <div class="pl-5 mb-6" v-for="(i, key) in eatingAndDrinking" :key="i.label">
            <div class="w-full mb-2 lg:mb-0 lg:pr-10">
                <ol class="list-decimal" :start="key + 7">
                    <li>
                        <p v-html="i.label"></p>
                    </li>
                </ol>
                <p v-if="i.jpnlabel" class="text-xs" v-html="i.jpnlabel"></p>
            </div>
            <div class="w-full mt-2.5">
                <div class="flex lg:flex-row flex-col lg:items-center justify-between gap-2.5">
                    <radio-group
                        custom-class="w-1/2"
                        :name="i.label"
                        :isDisabled="disableInputs"
                        :modelValue="disableInputs ? item.eating_and_drinking[item.eating_and_drinking[key]?.index]?.status : ''"
                        :options="MedicalService.getDefaultOptions(`eating-habit-${i.label}`)"
                        @update="MedicalService.addDataToArray(item.eating_and_drinking, i.label, $event, key)"
                    />
                </div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-decimal" start="10">
                <li>
                    <p>How often do you drink alcohol (sake, soju, beer, Western liquor, etc.) ? 飲酒頻度はどれくらいですか</p>
                </li>
            </ol>
            <div class="lg:columns-2 lg:block flex lg:flex-row flex-col lg:items-center justify-between lg:space-y-2.5 gap-2.5 mt-2.5">
                <radio-group
                    :isDisabled="disableInputs"
                    :options="[
                        { id: 'frequency-everyday', label: 'Everyday / 毎日', value: 'Everyday'},
                        { id: 'frequency-rarely', label: 'Rarely / ほとんど飲まない（飲めない）', value: 'Rarely'},
                        { id: 'frequency-occasionally', label: 'Occasionally / ときどき', value: 'Occasionally'},
                    ]"
                    name="frequency-options"
                    :modelValue="item.eating_and_drinking[5]?.status"
                    @update="MedicalService.addDataToArray(item.eating_and_drinking, 'Frequency of drinking alcohol (sake, soju, beer, Western liquor, etc.)', $event, 5)"
                />
            </div>
        </div>

        <div class="pl-5 mb-6">
            <div class="w-full mb-2 lg:mb-0 lg:pr-10">
                <ol class="list-decimal" :start="11">
                    <li>
                        <p>How much alcohol is consumed per day on drinking days? 飲酒日の1日あたりの飲酒量はどれくらいですか</p>
                    </li>
                </ol>
                <p class="text-sm">*Approximately 500ml of beer:1 glass of whisky double(60ml), 2 glasses of wine(240ml), 35% soju(80ml) ビール約500ml ：ウィスキーダブル1杯(60ml)、ワイン2杯(240ml)、焼酎35度(80ml)</p>
            </div>
            <div class="lg:columns-2 lg:block flex lg:flex-row flex-col lg:items-center justify-between lg:space-y-2.5 gap-2.5 mt-2.5">
                <radio-group
                    :isDisabled="disableInputs"
                    name="alcohol-options"
                    :options="[
                        { id: 'alcohol-less', label: 'Less than 500ml of beer / ビール500ml未満', value: 'Less than 500ml of beer'},
                        { id: 'alcohol-2', label: '1 liter to less than 1.5 liters of beer / ビール1L以上1.5L未満', value: '1liter to less than 1.5liters of beer'},
                        { id: 'alcohol-1', label: '500ml to less than 1 1liter of beer / ビール500ml以上1L未満', value: '500ml to less than 1liter  of beer'},
                        { id: 'alcohol-3', label: '1.5 liters or more of beer / ビール1.5L以上', value: '1.5 liters or more of beer'},
                    ]"
                    :modelValue="item.eating_and_drinking[6]?.status"
                    @update="MedicalService.addDataToArray(item.eating_and_drinking, 'Amount of alcohol consumed per day on drinking days', $event, 6)"
                />
            </div>
        </div>

        <div class="pl-5 mb-6" v-for="(i, key) in selfImprovement" :key="i">
            <div class="w-full mb-2 lg:mb-0 lg:pr-10">
                <ol class="list-decimal" :start="key + 12">
                    <li>
                        <p v-html="i.label"></p>
                    </li>
                </ol>
                <p v-if="i.jpnlabel" class="text-xs" v-html="i.jpnlabel"></p>
            </div>
            <div class="w-full mt-2.5">
                <div class="flex lg:flex-row flex-col lg:items-center justify-between gap-2.5">
                    <radio-group
                        custom-class="w-1/2"
                        :isDisabled="disableInputs"
                        :options="MedicalService.getDefaultOptions(`self-${key}`)"
                        :name="i.label"
                        :modelValue="disableInputs ? item.self_improvement[item.self_improvement[key]?.index]?.status : ''"
                        @update="MedicalService.addDataToArray(item.self_improvement, i.label, $event, key)"
                    />
                </div>
            </div>
        </div> -->


        <!-- <div class="mb-10">
            <p class="font-bold">A. Medication and Diagnosis</p>
            <p class="mb-4 text-xs" q>投薬と診断</p>
            <div class="flex flex-wrap mb-5 lg:mb-3.5" v-for="(i, key) in medicationAndDiagnosis" :key="key">
                <div class="w-full mb-2 lg:mb-0 lg:w-9/12 lg:pr-10">
                    <p>{{ i.label }}  {{  item.medication_and_diagnosis.status }}</p>
                    <p class="text-sm">{{ i.jpnlabel }}</p>
                </div>
                <div class="w-full lg:w-3/12">
                    <div class="flex items-center justify-between">
                        <radio-group
                            custom-class="w-1/2 lg:w-fit"
                            :name="i.label"
                            :isDisabled="disableInputs"
                            :modelValue="disableInputs ? item.medication_and_diagnosis[item.medication_and_diagnosis[key]?.index]?.status : ''"
                            :options="MedicalService.getDefaultOptions(`medication-${i.label}`)"
                            @update="MedicalService.addDataToArray(item.medication_and_diagnosis, i.label, $event, key)"
                        />
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="mb-10">
            <p class="font-bold">C. Eating and Drinking</p>
            <p class="mb-4 text-xs">飲食</p>

            <div class="mt-6">
                <p>Which of the following conditions apply when chewing and eating food?</p>
                <p class="mb-3 text-xs">食べ物を噛んだり食べたりするとき、次の条件のうちどれが当てはまりますか?</p>
                <div class="space-y-2">
                    <radio-group
                        :isDisabled="disableInputs"
                        :options="[
                            { id: 'eat-and-chew', label: 'I can eat and chew properly / ちゃんと噛んで食べられる', value: 'I can eat and chew properly'},
                            { id: 'having-difficulty', label: 'I am having difficulty since there are areas of concern such as teeth, gums and occlusion / 歯、歯ぐき、かみ合わせなど気になるところがあり困っている', value: 'I am having difficulty since there are areas of concern such as teeth, gums and occlusion'},
                            { id: 'hardly-bite', label: 'I can hardly bite / なかなか噛めない', value: 'I can hardly bite'},
                        ]"
                        :modelValue="item.eating_and_drinking[2]?.status"
                        @update="MedicalService.addDataToArray(item.eating_and_drinking, 'Which of the following conditions apply when chewing and eating food?', $event, 2)"
                        name="eating-food-options"
                    />
                </div>
            </div>

            <div class="mt-6">
                <p>Intake of snacks and sweet drinks in addition to the three meals of breakfast, lunch and dinner</p>
                <p class="mb-3 text-xs">朝・昼・晩の3食に加え、間食や甘い飲み物をとる頻度</p>
                <div class="space-y-2">
                    <radio-group
                        :isDisabled="disableInputs"
                        name="intake-options"
                        :options="[
                            { id: 'intake-everyday', label: 'Everyday / 毎日', value: 'Everyday'},
                            { id: 'intake-occasionally', label: 'Occasionally / ときどき', value: 'Occasionally'},
                            { id: 'intake-rarely', label: 'Rarely / めったにない', value: 'Rarely'},
                        ]"
                        :modelValue="item.eating_and_drinking[4]?.status"
                        @update="MedicalService.addDataToArray(item.eating_and_drinking, 'Intake of snacks and sweet drinks in addition to the three meals of breakfast, lunch and dinner', $event, 4)"
                    />
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup lang="ts">
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import { onMounted, ref } from 'vue';
import MedicalService from '@/classes/MedicalService';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: true
    },
})

const item = ref(props.form)

const getOptions = (index:number): any => {
    switch (index) {
        case 5:
            return [
                { id: 'Fast', label: 'Fast / 早い', value: 'Fast'},
                { id: 'Slow', label: 'Slow / 遅い', value: 'Slow'},
                { id: 'Normal', label: 'Normal / ふつう', value: 'Normal'},
            ];
        case 9:
            return [
                { id: 'frequency-everyday', label: 'Everyday / 毎日', value: 'Everyday'},
                { id: 'frequency-rarely', label: 'Rarely / ほとんど飲まない（飲めない）', value: 'Rarely'},
                { id: 'frequency-occasionally', label: 'Occasionally / ときどき', value: 'Occasionally'},
            ];
        case 10:
            return [
                { id: 'volume-less-than-500', label: 'Less than 500ml of beer/ビール500ml未満', value: 'Less than 500ml of beer'},
                { id: 'volume-500-to-1000', label: '500ml to less than 1 1liter of beer/ビール500ml以上1L未満', value: '500ml to less than 1 1liter of beer'},
                { id: 'volume-1000-to-1500', label: '1 liter to less than 1.5 liters of beer/ビール1L以上1.5L未満', value: '1 liter to less than 1.5 liters of beer'},
                { id: 'volume-1500-or-more', label: '1.5 liters or more of beer/ビール1.5L以上', value: '1.5 liters or more of beer'},
            ];
        default:
            return [
                { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                { id: 'no', label: 'No/いいえ', value: 'No'},
            ];
    }
}

onMounted(() => {
    // console.log(item.value.length);
    // if(item.value.length <= 0){
    //     item.value = formValues;
    // }
})
</script>