<template>
    <user-layout>
        <div class="pb-32 pt-16 w-[90%] mx-auto">
            <div  v-if="setPassword">
                <h1 class="mb-5 text-4xl font-bold text-center text-primary-500 lg:text-5xl" >
                    Set Password
                </h1>
                <p class="text-center mb-7">To setup your password, type in your password in the provided fields below.</p>

            </div>
            <div v-else>
                <h1 class="mb-5 text-4xl font-bold text-center text-primary-500 lg:text-5xl">
                    Forgot Password
                </h1>
                <p class="text-center mb-7">To change your password, type in your new password in the provided fields below.</p>
            </div>
           
            <form class="w-full lg:w-[350px] mx-auto" @submit.prevent="submit">
                <div class="mb-5">
                    <password-input
                        v-model="form.password"
                        id="new-password"
                        placeholder="New Password"
                        helper
                        :error="errors.password ? errors.password[0] : ''"
                    />
                </div>
                <div class="mb-8">
                    <password-input
                       v-model="form.password_confirmation"
                        id="confirm-password"
                        placeholder="Confirm New Password"
                    />
                </div>
                <div class="text-center">
                    <button-component
                        :disabled="loader"
                        :text="setPassword ? 'Set Password' : 'Change Password'"
                        :type="'submit'"
                        @click="submit()"
                    />
                </div>
            </form>

            <div class="mt-8 text-center">
                <p class="text-darkgray-400">Dont have an account yet? <a href="/signup" class="font-bold text-primary-500">Sign up now</a></p>
            </div>
        </div>
        <PageLoader v-if="loader" />

        <GeneralModal
            width="w-[416px]"
            title="Password successfully changed!"
            content="You may now login to your account."
            action-text="Login"
            :show="show"
            @close="show = false"
            @confirm="router.push('/login'); show = false"
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import GeneralModal from '@/components/GeneralModal.vue';

import AuthService from '@/classes/AuthService';
import { useRouter, useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';

const loader    = ref(false)
const showError = ref(false)
const errorMsg  = ref()
const errors    = ref([])
const show      = ref(false);
const setPassword = ref(false);
const router    = useRouter();
const route    = useRoute();

const form = ref({
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
})

const fetchParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    form.value.token = params.token ?? null
    form.value.email = params.email ?? null
}

const submit = async () => {
    try {
        loader.value = true
        const response = await AuthService.changePassword(form.value)
        loader.value = false
        show.value = true

        setTimeout(() => {
            window.location.replace('/login');
        }, 1500);

    }catch(error) {
        loader.value = false
        loader.value = false;
        showError.value = true;
        errorMsg.value = error.response.data.message
        errors.value = error.response.data.errors
    }

}

onMounted(() => {
    fetchParams()

    if('setPassword' in route.query){
        setPassword.value = true
    }
})

</script>