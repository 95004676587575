<template>
    <booking-layout
    step="1"
    :selected="selectedBranchId"
    @proceed="proceed">
        <div class="flex flex-col h-full">
            <div class="grid w-full grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-7">
                <BranchCard
                v-for="(item, index) in branches"
                :key="index"
                :item="item"
                :selectedBranchId="selectedBranchId"
                @click.prevent="selectedBranch(item.id)"
                />
                
                <ComingSoonBranchCard 
                />
            </div>
            <div class="hidden w-full mt-auto text-right lg:block">
                <button-component
                :disabled="!selectedBranchId"
                text="Continue"
                @click.prevent="proceed"
                />
            </div>
        </div>

    <PageLoader v-if="loader" />

    <ErrorModal
    width="w-[548px]"
    title="Error"
    action-text="Close"
    cancel-text="Go back"
    :show="showError"
    @close="showError = false"
    @confirm="showError = false"
    >
        <template #content>
            {{ errorMsg }}
        </template>
    </ErrorModal>

    </booking-layout>
</template>

<script setup lang="ts">
import BookingLayout from '@/layouts/BookingLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import BranchCard from '@/components/cards/BranchCard.vue';
import ComingSoonBranchCard from '@/components/cards/ComingSoonBranchCard.vue';
// import { CheckIcon } from "@heroicons/vue/24/solid";

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import Storage from '@/helpers/storage';

import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';

import booking from "@/classes/Booking";
import { useBookingStore } from '@/store/booking';

// Router Instance
const router = useRouter();

// State Instance
const bookingStoreState = useBookingStore();

// Local Storage Instance
const bookingLocalStorage = new Storage();
const bookingLocalStoragePayload = ref(JSON.parse(bookingLocalStorage.get('bookingPayload')));
const bookingLocalStorageSummary = ref(JSON.parse(bookingLocalStorage.get('bookingSummary')));
const patientDataLocalStorage = ref(JSON.parse(bookingLocalStorage.get('patient')));

// Data
const loader = ref(false);
const showError = ref(false);
const errorMsg = ref(null);
const selectedBranchId = ref();
const branches = ref();

// Methods
const fetchBranches = () => {
    loader.value = true;

    booking.getBranches()
        .then((result: any) => {
            loader.value = false;
            branches.value = result.data.response;            
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const fetchDrafts = () => {
    bookingStoreState.bookingPayload = bookingLocalStoragePayload.value || bookingStoreState.bookingPayload;
    bookingStoreState.bookingSummary = bookingLocalStorageSummary.value || bookingStoreState.bookingSummary;
    
    bookingStoreState.bookingPayload.patient_id = patientDataLocalStorage.value.patient_id;
    selectedBranchId.value = bookingStoreState.bookingPayload.branch_id;
}

const saveDrafts = () => {
    bookingLocalStorage.set('bookingPayload', JSON.stringify(bookingStoreState.bookingPayload));
    bookingLocalStorage.set('bookingSummary', JSON.stringify(bookingStoreState.bookingSummary));
}

const selectedBranch = (branchId: any) =>  {
    const filteredBranch = branches.value.find(x => x.id == branchId);

    bookingStoreState.bookingPayload.branch_id = filteredBranch.id;
    bookingStoreState.bookingPayload.branch_name = filteredBranch.name;
    bookingStoreState.bookingPayload.branch_address = filteredBranch.address_line;
    bookingStoreState.bookingSummary.selectedBranch = filteredBranch.name;

    selectedBranchId.value = bookingStoreState.bookingPayload.branch_id;

}

const proceed = () => {
    if(selectedBranchId.value) {
        saveDrafts();
        router.push('/booking/choose-package');
    }
}

onMounted( () => {
    fetchBranches();
    fetchDrafts();
});


</script>