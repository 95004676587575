<template>
    <div class="w-full">
        <!-- <label :for="id" class="block text-sm text-gray-900">{{
            label
        }}</label> -->
        <div
            class="relative rounded-md"
            :class="label ? 'mt-1' : ''"
        >
            <input
                :id="id"
                :type="showPassword ? 'text' : 'password'"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                ref="inputField"
                class="
                    frm-input
                    block
                    w-full
                    text-sm
                    h-12
                    disabled:bg-gray-100
                    focus:outline-none
                    focus:ring-0
                    bg-zinc-50
                    px-5
                    text-gray-800 
                    border-gray-900/[.04]
                    placeholder-gray-20 
                    focus:border-primary-500 
                    rounded-full
                    peer
                    pt-4
                    pb-1
                "
                :class="error ? 'border border-red-300' : ''"
                placeholder=" "
            />
            <label 
            :for="id" 
            @click="focusInput()"
            class="
                absolute 
                duration-300 
                transform 
                -translate-y-2.5
                top-3.5
                left-5
                scale-[.60] 
                origin-[0] 
                text-sm
                text-gray-20
                cursor-text	
                peer-focus:text-primary-500 
                peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 
                peer-focus:scale-[.60] 
                peer-focus:-translate-y-2.5"
            >{{
                placeholder
            }}</label>
            <div
                @click="showPassword = !showPassword"
                class="
                    cursor-pointer
                    absolute
                    inset-y-0
                    right-0
                    pr-4
                    flex
                    items-center
                "
            >
                <template v-if="!showPassword">
                    <EyeIcon
                        class="h-5 w-5 text-darkgray-400"
                        aria-hidden="true"
                    />
                </template>
                <template v-else>
                    <EyeSlashIcon
                        class="h-5 w-5 text-darkgray-400"
                        aria-hidden="true"
                    />
                </template>
            </div>

            <div class="group absolute -right-7 top-3.5 hidden lg:block" v-if="helper">
                <InformationCircleIcon 
                @mouseenter="showHelper = true"
                @mouseleave="showHelper = false"
                class="w-5 cursor-pointer text-gray-500"/>

                <div 
                class="text-xs text-gray-500 mt-1 pl-2 bg-white p-2 border rounded-lg w-fit
                absolute top-1/2 -translate-y-1/2 left-6 transition duration-300"
                :class="showHelper ? 'opacity-100' : 'opacity-0'">
                    <p class="text-xs">Password must be:</p>
                    <ul class="list-disc pl-4 whitespace-nowrap">
                        <li>minimum of 8 characters</li>
                        <li>at least 1 uppercase</li>
                        <li>at least 1 lowercase</li>
                        <li>at least 1 number</li>
                        <li>at least 1 special character</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p> -->

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>

        
    </div>
</template>

<script setup lang="ts">
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/solid";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { ref } from 'vue';

defineProps({
    modelValue: {
        default: null,
        required: true,
    },
    id: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    helper: {
        type: Boolean,
        default: false,
    },
    error: {
        type: String,
        default: null,
    },
});

const showPassword = ref<boolean>(false);

const inputField = ref()
const focusInput = () => {
    inputField.value.focus();
};

const showHelper = ref<boolean>(false);

</script>