<template>
    <div>
        <!-- no record -->
        <div class="p-5 bg-white rounded-lg text-darkgray-800" v-if="!showForm">
            <p class="text-xl font-bold mb-2.5">You do not have a medical record yet.</p>
                <!-- Todo: Replace copywriting once available -->
                <p class="mb-6 text-sm">Please fill up the medical record.</p>
                <button-component 
                    @click="showForm = true"
                    text="Fill up now"
                />
        </div>

        <div v-if="showForm">
            <form @submit.prevent="submit">
                <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
                    <p class="mb-6 text-xl font-bold text-primary-500">Patient Details</p>
                    <!-- START patient Details -->
                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-4">
                            <text-input
                                v-model="payload.suffix"
                                id="suffix"
                                type="text"
                                placeholder="Suffix"
                                :error="errors.suffix ? errors.suffix[0] : ''"
                            />
                        </div>
                        <div class="hidden col-span-4 lg:block"></div>
                        <div class="hidden col-span-4 lg:block"></div>
                        <div class="col-span-full lg:col-span-4">
                            <text-input
                                v-model="payload.first_name"
                                id="first-name"
                                type="text"
                                placeholder="First Name"
                                :error="errors.first_name ? errors.first_name[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-4">
                            <text-input
                                v-model="payload.middle_name"
                                id="middle-name"
                                type="text"
                                placeholder="Middle Name"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-4">
                            <text-input
                                v-model="payload.last_name"
                                id="last-name"
                                type="text"
                                placeholder="Last Name"
                                :error="errors.last_name ? errors.last_name[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                name="sex"
                                id="sex"
                                v-model="payload.sex"
                                placeholder="Sex"
                                :options="sexes"
                                :error="errors.sex ? errors.sex[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.nationality"
                                id="nationality"
                                type="text"
                                placeholder="Nationality"
                                :error="errors.nationality ? errors.nationality[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <CalendarPicker 
                                v-model="payload.birthdate"
                                id="date-of-birth"
                                :inline="false"
                                placeholder="Date of Birth"
                                :error="errors.birthdate ? errors.birthdate[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.telephone_number"
                                id="telephone"
                                type="text"
                                placeholder="Telephone Number"
                                :error="errors.telephone_number ? errors.telephone_number[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                name="civil_status"
                                id="civil_status"
                                v-model="payload.civil_status"
                                placeholder="Civil Status"
                                :options="civilStatuses"
                                :error="errors.civil_status ? errors.civil_status[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.email_address"
                                id="email"
                                type="email"
                                placeholder="Email Address"
                                :error="errors.email_address ? errors.email_address[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="cellphone_number"
                                name="cellphone_number"
                                :add_on_left="true"
                                add_on_text="+63"
                                @keypress="numberonly"
                                :placeholder="'Cellphone Number'"
                                :maxlength=10
                                v-model="payload.cellphone_number"
                                :error="errors.cellphone_number ? errors.cellphone_number[0] : ''"
                            />
                        </div>
                    </div>

                    <div class="my-8 border-b border-black/[.04]"></div>

                    <!-- START Local Address -->
                    <p class="mb-6 text-xl font-bold text-primary-500">Local Address</p>
                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="province"
                                v-model="payload.province_code"
                                placeholder="Province"
                                :options="provinces"
                                @click="clearCity"
                                :error="errors.province ? errors.province[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="city"
                                v-model="payload.city_code"
                                placeholder="City/Municipality"
                                :options="cities"
                                @click="clearBgy"
                                :error="errors.city ? errors.city[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="barangay"
                                v-model="payload.barangay_code"
                                placeholder="Barangay"
                                :options="barangays"
                                :error="errors.barangay ? errors.barangay[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="street"
                                v-model="payload.street"
                                type="text"
                                placeholder="Street"
                                :error="errors.street ? errors.street[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="bldg"
                                v-model="payload.building_number"
                                type="text"
                                placeholder="No./Bldg."
                                :error="errors.building_number ? errors.building_number[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="zipcode"
                                v-model="payload.zip_code"
                                type="text"
                                :maxlength=4
                                @keypress="numberonly"
                                placeholder="ZIP Code"
                                :error="errors.zip_code ? errors.zip_code[0] : ''"
                            />
                        </div>
                    </div>
                    <!-- END of Local Address -->

                    <div class="my-8 border-b border-black/[.04]"></div>

                    <!-- START Permanent Address -->
                    <div class="flex flex-wrap mb-6 lg:flex-nowrap lg:justify-between space-y-1.5 lg:space-y-0">
                        <p class="w-full text-xl font-bold text-primary-500 lg:w-fit">Permanent Address</p>
                        <checkbox-input
                            id="check"
                            label="Same as Local Address"
                            v-model="sameAsLocal"
                        />
                    </div>
                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="permanent_province"
                                v-model="payload.permanent_province_code"
                                placeholder="Province"
                                :options="permanentProvinces"
                                @click="clearPermanentCity"
                                :error="errors.permanent_province ? errors.permanent_province[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="permanent_city"
                                v-model="payload.permanent_city_code"
                                placeholder="City"
                                :options="permanentCities"
                                @click="clearPermanentBgy"
                                :error="errors.permanent_city ? errors.permanent_city[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                id="permanent_barangay"
                                v-model="payload.permanent_barangay_code"
                                placeholder="Barangay"
                                :options="permanentBarangays"
                                :error="errors.permanent_barangay ? errors.permanent_barangay[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="permanent_street"
                                v-model="payload.permanent_street"
                                type="text"
                                placeholder="Street"
                                :error="errors.permanent_street ? errors.permanent_street[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="permanent_bldg"
                                v-model="payload.permanent_building_number"
                                type="text"
                                placeholder="No./Bldg."
                                :error="errors.permanent_building_number ? errors.permanent_building_number[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                id="permanent_zipcode"
                                v-model="payload.permanent_zip_code"
                                type="text"
                                :maxlength=4
                                @keypress="numberonly"
                                placeholder="ZIP Code"
                                :error="errors.permanent_zip_code ? errors.permanent_zip_code[0] : ''"
                            />
                        </div>
                    </div>
                    <!-- END of Permanent Address -->

                    <div class="my-8 border-b border-black/[.04]"></div>

                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                            v-model="payload.philhealth_number"
                            id="philhealth"
                            type="text"
                            placeholder="Philhealth No."
                            :error="errors.philhealth_number ? errors.philhealth_number[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                v-model="payload.hmo_id"
                                id="hmo"
                                placeholder="HMO"
                                :options="hmoOptions"
                                :error="errors.hmo ? errors.hmo[0] : ''"
                            />
                        </div>
                        <div class="relative col-span-full lg:col-span-6">
                            <label for="senior_citizen"
                            class="flex justify-between cursor-pointer relative rounded-full bg-zinc-50 border border-gray-900/[.04] frm-input w-full text-sm h-12 disabled:bg-gray-100 focus:outline-none focus:ring-0 px-5 pt-4 pb-1">
                                <span
                                    class="absolute top-3 left-5 z-[1] duration-300 origin-[0] text-gray-20 focus-within:text-primary-500"
                                    :class="uploadedFileName1 ? 'scale-[.60] -translate-y-2.5' : 'scale-100 translate-y-0'"
                                >
                                    Senior Citizen ID (optional)
                                </span>
                                <input 
                                    id="senior_citizen" 
                                    type="file" 
                                    class="hidden" 
                                    @change="uploadSRID"
                                    accept="image/*"
                                >
                                <span v-if="uploadedFileName1">{{ uploadedFileName1 }}</span>
                               
                            </label>
                            <div v-if="uploadedFileName1" class="absolute top-3 right-5 z-[2]">
                                <button type="button" class="border-0" @click="removeSRID()">
                                    <XMarkIcon
                                        class="w-5 h-5 transition text-gray-20 hover:text-red-500"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                        <div class="relative col-span-full lg:col-span-6">
                            <label for="pwd_id"
                            class="flex justify-between cursor-pointer relative rounded-full bg-zinc-50 border border-gray-900/[.04] frm-input w-full text-sm h-12 disabled:bg-gray-100 focus:outline-none focus:ring-0 px-5 pt-4 pb-1">
                                <span
                                    class="absolute top-3 left-5 z-[1] duration-300 origin-[0] text-gray-20 focus-within:text-primary-500"
                                    :class="uploadedFileName2 ? 'scale-[.60] -translate-y-2.5' : 'scale-100 translate-y-0'"
                                >
                                    PWD ID (optional)
                                </span>
                                <input 
                                    id="pwd_id" 
                                    type="file" 
                                    class="hidden" 
                                    @change="uploadPWDID"
                                    accept="image/*"
                                >
                                <span v-if="uploadedFileName2">{{ uploadedFileName2 }}</span>

                            </label>
                            <div v-if="uploadedFileName2" class="absolute top-3 right-5 z-[2]">
                                <button type="button" class="border-0" @click="removePWDID()">
                                    <XMarkIcon
                                        class="w-5 h-5 transition text-gray-20 hover:text-red-500"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- START of Occupation -->
                <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
                    <p class="mb-6 text-xl font-bold text-primary-500">Occupation</p>
                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.job_title"
                                id="job-title"
                                type="text"
                                placeholder="Title"
                                :error="errors.job_title ? errors.job_title[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.company_name"
                                id="company-name"
                                type="text"
                                placeholder="Company Name"
                                :error="errors.company_name ? errors.company_name[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.company_contact_no"
                                id="contact-number"
                                type="text"
                                placeholder="Contact Number"
                                :error="errors.company_contact_no ? errors.company_contact_no[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.company_email"
                                id="company-email"
                                type="text"
                                placeholder="Company Email Address"
                                :error="errors.company_email ? errors.company_email[0] : ''"
                            />
                        </div>
                        <div class="col-span-full">
                            <text-input
                                v-model="payload.company_address"
                                id="address"
                                type="text"
                                placeholder="Address"
                                :error="errors.company_address ? errors.company_address[0] : ''"
                            />
                        </div>
                    </div>
                </div>
                <!-- END of Occupation -->

                <!-- START of Emergency Contact -->
                <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
                    <p class="mb-6 text-xl font-bold text-primary-500">Person to notify in case of emergency</p>
                    <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-5 gap-y-7">
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.contact_person_name"
                                id="emergency-name"
                                type="text"
                                placeholder="Name"
                                :error="errors.contact_person_name ? errors.contact_person_name[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <MultiselectInput
                                v-model="payload.contact_person_relationship"
                                id="relationship"
                                placeholder="Relationship"
                                :options="medical.getRelationships()"
                                :error="errors.contact_person_relationship ? errors.contact_person_relationship[0] : ''"
                            />
                            
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <!-- <text-input
                                v-model="payload.contact_person_number"
                                id="emergency-contact-number"
                                type="text"
                                placeholder="Contact Number"
                                :error="errors.contact_person_number ? errors.contact_person_number[0] : ''"
                            /> -->
                            <text-input
                                id="contact_person_number"
                                name="contact_person_number"
                                :add_on_left="true"
                                add_on_text="+63"
                                @keypress="numberonly"
                                :placeholder="'Contact Number'"
                                :maxlength=10
                                v-model="payload.contact_person_number"
                                :error="errors.contact_person_number ? errors.contact_person_number[0] : ''"
                            />
                        </div>
                        <div class="col-span-full lg:col-span-6">
                            <text-input
                                v-model="payload.contact_person_email"
                                id="emergency-email"
                                type="text"
                                placeholder="Email Address"
                                :error="errors.contact_person_email ? errors.contact_person_email[0] : ''"
                            />
                        </div>
                    </div>
                </div>
                <!-- END of Emergency Contact -->

                <button-component 
                    :type="`submit`"
                    text="Save"
                />
            </form>
        </div>

        <GeneralModal
            width="w-[548px]"
            title="Confirm Information"
            action-text="Submit"
            cancel-text="Go back"
            :show="show"
            @close="show = false"
            @confirm="submitForm()"
        >
            <template #content>
                <p>Please be informed that any changes that you wish to apply to your medical record will require you to contact Shinagawa.</p>
                <br>
                <p>If all the information you input is final, click “Submit”.</p>
            </template>
        </GeneralModal>

        <!-- Success Response -->
        <GeneralModal
            width="w-[416px]"
            title="Success!"
            content="Medical General Medical Questionnaire submitted successfully."
            action-text="Ok"
            :show="showSuccess"
            @close="showSuccess = false"
            @confirm="showSuccess = false"
        />

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
    </div>
</template>

<script setup lang="ts">
import CalendarPicker from '@/components/CalendarPicker.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import FileInput from '@/components/inputs/FileInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CheckboxInput from "@/components/inputs/CheckboxInput.vue";
import GeneralModal from '@/components/GeneralModal.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
// import { useRouter } from 'vue-router';
// const router = useRouter();
import patient from "@/classes/UserData";
import location from "@/classes/LocationService";
import medical from "@/classes/MedicalService";
import auth from "@/classes/AuthService";
import Storage from '@/helpers/storage';
import { numberonly } from '@/helpers/NumberHelper';
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref, watch } from 'vue';

const storage = new Storage();

const loader      = ref(false)
const show        = ref(false);
const showForm    = ref(false);
const showSuccess = ref(false);
const showError   = ref(false)
const errorMsg    = ref(null)
const errors      = ref([])
const sameAsLocal = ref()

const provinces = ref([]);
const cities    = ref([]);
const barangays = ref([]);

const permanentProvinces = ref([]);
const permanentCities    = ref([]);
const permanentBarangays = ref([]);

const hmoOptions = ref([]);

const patientData   = patient.getCurrentUser()
const sexes         = patient.getGender();
const civilStatuses = patient.getCivilStatuses();

const uploadedFileName1 = ref('')
const uploadedFileName2 = ref('')

const payload = ref({
    // Patient Details
    patient_id: patientData?.patient_id ?? null,
    last_name: patientData?.last_name ?? null,
    first_name: patientData?.first_name ?? null,
    middle_name: patientData?.middle_name ?? null,
    suffix: patientData?.suffix ?? null,
    cellphone_number: patientData?.cellphone_number ?? null,
    telephone_number: patientData?.telephone_number ?? null,
    civil_status: patientData?.civil_status ?? null,
    sex: patientData?.sex ?? null,
    nationality: patientData?.nationality ?? null,
    // Patient Address
    religion: patientData?.religion ?? null,
    job_title: patientData?.job_title ?? null,
    birthdate: patientData?.birthdate ?? null,
    birthplace: patientData?.birthplace ?? null,
    email_address: patientData?.email_address ?? null,
    building_number: patientData?.building_number ?? null,
    street: patientData?.street ?? null,
    barangay: patientData?.barangay ?? null,
    barangay_code: patientData?.barangay_code ?? null,
    city: patientData?.city ?? null,
    city_code: patientData?.city_code ?? null,
    province: patientData?.province ?? null,
    province_code: patientData?.province_code ?? null,
    region: patientData?.region ?? null,
    region_code: patientData?.region_code ?? null,
    zip_code: patientData?.zip_code ?? null,
    philhealth_number: patientData?.philhealth_number ?? null,
    hmo_id: patientData?.hmo_id ?? null,
    senior_citizen_id_base_64: patientData?.senior_citizen_id_base_64 ?? null,
    pwd_id_base_64: patientData?.pwd_id_base_64 ?? null,
    // Emergency Contact Person Details
    company_name: patientData?.company_name ?? null,
    company_email: patientData?.company_email ?? null,
    company_address: patientData?.company_address ?? null,
    contact_person_name: patientData?.contact_person_name ?? null,
    contact_person_relationship: patientData?.contact_person_relationship ?? null,
    contact_person_number: patientData?.contact_person_number ?? null,
    contact_person_email: patientData?.contact_person_email ?? null,
    company_contact_no: patientData?.company_contact_no ?? null,
    // Permanent address
    permanent_building_number: patientData?.permanent_building_number ?? null,
    permanent_street: patientData?.permanent_street ?? null,
    permanent_barangay: patientData?.permanent_barangay ?? null,
    permanent_barangay_code: patientData?.permanent_barangay_code ?? null,
    permanent_city: patientData?.permanent_city ?? null,
    permanent_city_code: patientData?.permanent_city_code ?? null,
    permanent_province: patientData?.permanent_province ?? null,
    permanent_province_code: patientData?.permanent_province_code ?? null,
    permanent_region: patientData?.permanent_region ?? null,
    permanent_region_code: patientData?.permanent_region_code ?? null,
    permanent_zip_code: patientData?.permanent_zip_code ?? null,
    // updated_by: null`
});


const uploadSRID = (e: Event) => {
    const file:File  = e.target.files[0];

    createBase64Image(file, 'senior_citizen_id_base_64')

    uploadedFileName1.value = removeFakePath((e.target as HTMLInputElement).value);
}


const removeSRID = () => {
    payload.value.senior_citizen_id_base_64 = null;
    uploadedFileName1.value = null;
}

const uploadPWDID = (e: Event) => {
    const file:File  = e.target.files[0];

    createBase64Image(file, 'pwd_id_base_64')

    uploadedFileName2.value = removeFakePath((e.target as HTMLInputElement).value);
}

const removeFakePath = (path:string):string => {
    return path.replace(/.*\\fakepath\\/g, "");
}

const removePWDID = () => {
    payload.value.pwd_id_base_64 = null;
    uploadedFileName2.value = null;
}

const createBase64Image = (FileObject:File, item:string)=> {

    const reader = new FileReader();
    
    reader.onload = (event) => {
        payload.value[item] = event.target.result;
    }
    
    reader.readAsDataURL(FileObject);
}

const submit = () => show.value = true

const submitForm = () => {
    patient.updateMedicalRecord(payload.value)
        .then((result: any) => {
            loader.value      = false;
            show.value        = false;
            showSuccess.value = true
            storage.set('patient', JSON.stringify(result.data.response.response))

            setTimeout(() => {
                window.location.reload()
            }, 3000);
        })
        .catch((err) => {
            loader.value    = false;
            show.value      = false;
            showError.value = true;
            errorMsg.value  = err.response.data.message;

            if(err.response.status == 422) {
                errors.value = err.response.data.errors;
            }
            errorResponse(err)
        })
}

const errorResponse = (err: object) => {
    loader.value = false

    if(err?.response.status == 401) {
        auth.redirectToLogin()
    }
}

const fetchData = () => {
    loader.value = true

    location.fetchProvinces()
        .then((result: any) => {
            loader.value = false
            provinces.value = result.data
            permanentProvinces.value = result.data
        })
        .catch((err: any) => errorResponse(err) )
}

const fetchCity = () => {
    loader.value = true

    location.fetchCities(`code=${payload.value.province_code}&level=Mun`)
    .then((result: any) => {
        loader.value = false
        cities.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const formatHmos = (hmos: Array<number | string | null>) => {
    hmos.forEach(hmo => {
        hmoOptions.value.push({ id: hmo.hmo_id, label: hmo.hmo_name, value: hmo.hmo_id})
    });
}

const fetchHmos = async () => {
    try {
        loader.value = true;
        const response = await medical.getHmos();
        loader.value = false;
        
        formatHmos(response?.data);
    }
    catch(error: any) {
        loader.value    = false;
        showError.value = true;
        errorMsg.value  = error
    }
}

/**
 * 
 * Local Address Functions
 * 
 */
const fetchBrgy = () => {
    loader.value = true

    location.fetchBarangays(`code=${payload.value.city_code}&level=Bgy`)
    .then((result: any) => {
        loader.value = false
        barangays.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const clearCity = () => {
    payload.value.city = null
    payload.value.city_code = null
    payload.value.barangay = null
    payload.value.barangay_code = null
}

const clearBgy = () => {
    payload.value.barangay = null
    payload.value.barangay_code = null
}

/**
 * 
 * Local Address Functions
 * 
 */
const fetchPermanentCity = () => {
    loader.value = true
    location.fetchCities(`code=${payload.value.permanent_province_code}&level=Mun`)
    .then((result: any) => {
        loader.value = false
        permanentCities.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const fetchPermanentBrgy = () => {
    loader.value = true
    location.fetchBarangays(`code=${payload.value.permanent_city_code}&level=Bgy`)
    .then((result: any) => {
        loader.value = false
        permanentBarangays.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const clearPermanentCity = () => {
    payload.value.permanent_city = null
    payload.value.permanent_city_code = null
    payload.value.permanent_barangay = null
    payload.value.permanent_barangay_code = null
}

const clearPermanentBgy = () => {
    payload.value.permanent_barangay = null
    payload.value.permanent_barangay_code = null
}

watch(
      () => payload.value.barangay_code,
      (val) => {
        payload.value.barangay = barangays.value.find(x => x.code == val)?.label;
      },
      { immediate: true }
    );
 
watch(
      () => payload.value.city_code,
      (val) => {
        payload.value.city = cities.value.find(x => x.code == val)?.label;
        barangays.value = [];
        fetchBrgy()
      },
      { immediate: true }
    );

watch(
      () => payload.value.province_code,
      (val) => {
        payload.value.province = provinces.value.find(x => x.code == val)?.label;
        cities.value = [];
        barangays.value = [];
        fetchCity()
      },
      { immediate: true }
    );

// Permanenet Address
watch(
      () => payload.value.permanent_barangay_code,
      (val) => {
        payload.value.permanent_barangay = sameAsLocal.value ? payload.value.permanent_barangay : permanentBarangays.value.find(x => x.code == val)?.label;
      },
      { immediate: true }
    );

watch(
      () => payload.value.permanent_city_code,
      (val) => {
            payload.value.permanent_city = sameAsLocal.value ? payload.value.permanent_city : permanentCities.value.find(x => x.code == val)?.label;
            permanentBarangays.value = [];
            fetchPermanentBrgy()
      },
      { immediate: true }
    );

watch(
      () => payload.value.permanent_province_code,
      (val) => {
        if(val) {
            payload.value.permanent_province = sameAsLocal.value ? payload.value.permanent_province : permanentProvinces.value.find(x => x.code == val)?.label;
            permanentCities.value = [];
            permanentBarangays.value = [];
            fetchPermanentCity()
        }
        
      },
      { immediate: true }
    );

watch(
      () => sameAsLocal.value,
      (val) => {

        payload.value.permanent_building_number = null;
        payload.value.permanent_street = null;
        payload.value.permanent_barangay = null;
        payload.value.permanent_barangay_code = null;
        payload.value.permanent_city = null;
        payload.value.permanent_city_code = null;
        payload.value.permanent_province = null;
        payload.value.permanent_province_code = null;
        payload.value.permanent_region = null;
        payload.value.permanent_region_code = null;
        payload.value.permanent_zip_code = null;
        
        if(val 
            && payload.value.province 
            && payload.value.province_code 
            && payload.value.city
            && payload.value.city_code
            && payload.value.barangay
            && payload.value.barangay_code
            && payload.value.building_number
            && payload.value.street) {

            console.log(payload.value.province)
            console.log(payload.value.province_code)
            console.log(payload.value.city)
            console.log(payload.value.city_code)
            console.log(payload.value.barangay)
            console.log(payload.value.barangay_code)

            Object.assign(payload.value, {
                permanent_building_number: payload.value.building_number,
                permanent_street: payload.value.street,
                permanent_barangay: payload.value.barangay,
                permanent_barangay_code: payload.value.barangay_code,
                permanent_city: payload.value.city,
                permanent_city_code: payload.value.city_code,
                permanent_province: payload.value.province,
                permanent_province_code: payload.value.province_code,
                permanent_region: payload.value.region,
                permanent_region_code: payload.value.region_code,
                permanent_zip_code: payload.value.zip_code,
            })
        }
      },
    //   { immediate: true }
    );


onMounted(() => {
    fetchData()
    fetchHmos()
})
</script>