<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">IV. Allergies アレルギー</p>

        <div class="grid grid-cols-1 mb-10 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full">
                <p class="mb-2">Do you have any allergies? アレルギーはありますか：</p>
                <div class="grid grid-cols-1 space-y-2 lg:grid-cols-12">
                    <radio-group
                        :options="[
                            { id: 'no', label: 'No/いいえ', value: 'no'},
                            { id: 'yes', label: 'Yes/はい', value: 'yes'},
                        ]"
                        name="has_allergies"
                        customClass="col-span-6"
                        :disabled="disableInputs"
                        v-model="item.has_allergies"
                    />
                    <div class="col-span-6"></div>
                    <div 
                        v-if="item.has_allergies"
                        class="col-span-6 mt-3 space-y-3 pl-7"
                    >
                        <text-input
                            id="medication"
                            type="text"
                            placeholder="Medication/薬"
                            :disabled="disableInputs"
                            v-model="item.medication"
                        />

                        <text-input
                            id="food"
                            type="text"
                            placeholder="Food/食べ物"
                            :disabled="disableInputs"
                            v-model="item.food"
                        />
                        <text-input
                            id="specify_other"
                            type="text"
                            placeholder="Other/その他"
                            :disabled="disableInputs"
                            v-model="item.specify_other"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import { ref, watch } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Array,
        required: true
    },
    disableInputs: {
        type: Boolean,
        default: false
    }
})

const item = ref(props.form)

watch(
    () => item.value.has_allergies,
    (val: boolean) => {
        if (val == false) {
            item.value.medication = null;
            item.value.food = null;
            item.value.specify_other = null;
        }
    }
);

</script>