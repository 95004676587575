<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
            class="w-full flex items-center px-4 py-2 outline-none ring-0 space-x-3"
            >
                <UserCircleIcon
                    class="h-6 w-6 text-primary-500"
                    aria-hidden="true"
                />
                <span class="text-primary-700 font-bold whitespace-nowrap">{{ `${patientData.first_name} ${patientData.last_name}` }}</span>
                <ChevronDownIcon
                    class="h-5 w-5 text-gray-10/30"
                    aria-hidden="true"
                />
            </MenuButton>
        </div>

        <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
            class="absolute left-0 bottom-10 mt-2 w-full origin-top-right rounded-[5px] bg-white border border-gray-30"
            >
                <div class="px-4">
                    <MenuItem v-slot="{ active }">
                        <button
                            :class="[
                            active ? 'text-black' : 'text-darkgray-400',
                            'pt-2',
                            ]"
                            @click="router.push('/account-details')"
                        >
                            Account Details
                        </button>
                    </MenuItem>
                </div>
                <div class="px-4">
                    <MenuItem v-slot="{ active }">
                        <button
                            :class="[
                            active ? 'text-black' : 'text-darkgray-400',
                            'pt-2',
                            ]"
                            @click="router.push('/change-password')"
                        >
                            Change Password
                        </button>
                    </MenuItem>
                </div>
                <div class="px-4 mt-5">
                    <MenuItem v-slot="{ active }">
                        <button
                            @click="logout"
                            :class="[
                                active ? 'text-black' : 'text-darkgray-400','py-2',
                            ]"
                        >
                            Logout
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { UserCircleIcon, ChevronDownIcon } from '@heroicons/vue/24/outline'
import AuthService from "@/classes/AuthService";
import patient from "@/classes/UserData";
import { useRouter } from 'vue-router';

import { computed } from 'vue';

const router = useRouter();

const patientData = computed(() => patient.getCurrentUser())

const logout = () => {
    AuthService.logout()
}

</script>