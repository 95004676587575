<template>
    <booking-layout
    previous-step="1"
    step="2"
    :selected="selectedPackageId"
    @proceed="proceed"
    @back="back">
        <div class="flex flex-col">
            <div>
                <!-- @TODO : update copywriting -->
                <!-- <div class="">
                    <p>Praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi.</p>
                    <br>
                    <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.</p>
                </div> -->
                <div class="mt-6 mb-8 border-b border-gray-40"></div>
                <div class="mb-10" v-if=" packages.length > 0">
                    <p class="mb-3 text-xl font-bold">Our Packages and Inclusions</p>
                    <!-- <p class="mb-4">At vero eos et accusamus et iusto odio dignissimos ducimus qui.</p> -->
                    <div 
                        v-for="(item, index) in packages"
                        :key="index"
                        class="rounded-2xl bg-white mb-2.5"
                    >
                        <div 
                            class="flex items-center justify-between w-full px-5 py-4 cursor-pointer"
                            @click="toggleItem(index + '-package')"
                        >
                            <div class="flex items-center justify-between w-full py-2">
                                <div class="text-left">
                                    <p class="mb-1 font-bold text-primary-500">{{ item.name }}</p>
                                    <!-- <p class="">{{ item.description }}</p> -->
                                </div>
                                <div>
                                    <p class="font-bold text-primary-700">{{ formatter.format(item.price) }}</p>
                                </div>
                            </div>

                            <ChevronDownIcon
                            class="w-5 h-5 ml-4 text-primary-500"
                            />
                        </div>
                        <div 
                            v-if="isActive === index + '-package'"
                            class="px-5 pt-0 pb-6"
                        >
                            <div class="w-full space-y-2">
                                <ul class="pl-6 list-disc">
                                    <li v-for="(i, idx) in item.items" :key="idx">{{ i.item_name }}</li>
                                </ul>
                                <div class="mt-4 text-right">
                                    <button-component
                                        :show-icon="false"
                                        select-button
                                        :text="selectedPackageId == item.id ? 'Selected' : 'Select'" 
                                        :selected="selectedPackageId == item.id"
                                        @click.prevent="selectedPackage(item.id)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-10" v-if="services.length > 0">
                    <p class="mb-3 text-xl font-bold">Our Other Individual Services</p>
                    <p class="mb-4">You may avail these individually or as add-on. If none, you may skip to the next step.</p>
                    <div class="flex flex-wrap -m-2">
                        <div class="w-full px-2 py-1 lg:w-1/2"
                        v-for="(service, index) in services"
                        :key="index">
                            <!-- accordion -->
                            <div class="bg-white rounded-2xl">
                                <div class="flex items-center justify-between w-full px-5 py-4 cursor-pointer" 
                                @click="toggleItem(index + '-service')">
                                    <p class="font-bold text-primary-500">{{ service.name }}</p>

                                    <ChevronDownIcon
                                    class="w-5 h-5 ml-4 text-primary-500"
                                    />
                                </div>
                                <div 
                                v-if="isActive === index + '-service'"
                                class="px-5 pt-0 pb-6">
                                    <div class="w-full space-y-2">
                                        <div class="flex items-center justify-between">
                                            <div class="relative flex">
                                                <div class="flex items-center h-4 mt-0.5">
                                                    <input
                                                    class="w-4 h-4 border-2 rounded-full cursor-pointer text-primary-500 border-primary-200 focus:ring-0 focus:ring-offset-0 focus:outline-0 focus:outline-offset-0 checked:border-primary-500"
                                                    :id="`parent-service-${service.id}`"
                                                    type="checkbox"
                                                    :value="service.id"
                                                    v-model="selectedAllRiders">
                                                    <div class="ml-3 text-sm">
                                                        <label :for="`parent-service-${service.id}`" class="text-sm cursor-pointer">All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="font-bold whitespace-nowrap">{{ formatter.format(service.items.reduce((a, b) => a + b.item_price, 0)) }}</p>
                                        </div>
                                        <div class="flex items-center justify-between" v-for="item, idx in service.items" :key="idx">
                                            <div class="relative flex">
                                                <div class="flex items-center h-4 mt-0.5">
                                                    <input
                                                    class="
                                                        cursor-pointer
                                                        h-4
                                                        w-4
                                                        text-primary-500
                                                        border-primary-200
                                                        border-2
                                                        focus:ring-0
                                                        focus:ring-offset-0	
                                                        focus:outline-0
                                                        focus:outline-offset-0	
                                                        checked:border-primary-500
                                                        rounded-[3px]
                                                    "
                                                    :id="`service-${item.item_id}`"
                                                    type="checkbox"
                                                    :value="item.item_id"
                                                    v-model="selectedRiders">
                                                    <div class="ml-3 text-sm">
                                                        <label :for="`service-${item.item_id}`" class="text-sm cursor-pointer">{{ item.item_name  }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="whitespace-nowrap">{{ formatter.format(item.item_price) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mb-10" v-if="allergyTests[0]?.items.length">
                    <p class="mb-4 text-xl font-bold">Allergy Test</p>
                    <div class="flex flex-wrap -m-2">
                        <div class="w-full px-2 py-1 lg:w-1/2"
                        v-for="(allergyTest, index) in allergyTests"
                        :key="index">
                            <div class="bg-white rounded-2xl">
                                <div class="flex items-center justify-between w-full px-5 py-4 cursor-pointer"
                                @click="toggleItem(index + '-allergy')">
                                    <p class="font-bold text-primary-500">{{ allergyTest.name }}</p>

                                    <ChevronDownIcon
                                    class="w-5 h-5 ml-4 text-primary-500"
                                    />
                                </div>
                                <div 
                                v-if="isActive === index + '-allergy'"
                                class="px-5 pt-0 pb-6">
                                <div class="w-full space-y-2">
                                        <div class="flex items-center justify-between">
                                            <div class="relative flex">
                                                <div class="flex items-center h-4 mt-0.5">
                                                    <input
                                                    class="w-4 h-4 border-2 rounded-full cursor-pointer text-primary-500 border-primary-200 focus:ring-0 focus:ring-offset-0 focus:outline-0 focus:outline-offset-0 checked:border-primary-500"
                                                    :id="`parent-allergyTest-${allergyTest.id}`"
                                                    type="checkbox"
                                                    :value="allergyTest.id"
                                                    v-model="selectedAllRiders">
                                                    <div class="ml-3 text-sm">
                                                        <label :for="`parent-allergyTest-${allergyTest.id}`" class="text-sm cursor-pointer">All</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="font-bold whitespace-nowrap">{{ formatter.format(allergyTest.items.reduce((a, b) => a + b.item_price, 0)) }}</p>
                                        </div>
                                        <div class="flex items-center justify-between" v-for="item, idx in allergyTest.items" :key="idx">
                                            <div class="relative flex">
                                                <div class="flex items-center h-4 mt-0.5">
                                                    <input
                                                    class="
                                                        cursor-pointer
                                                        h-4
                                                        w-4
                                                        text-primary-500
                                                        border-primary-200
                                                        border-2
                                                        focus:ring-0
                                                        focus:ring-offset-0	
                                                        focus:outline-0
                                                        focus:outline-offset-0	
                                                        checked:border-primary-500
                                                        rounded-[3px]
                                                    "
                                                    :id="`service-${item.item_id}`"
                                                    type="checkbox"
                                                    :value="item.item_id"
                                                    v-model="selectedRiders">
                                                    <div class="ml-3 text-sm">
                                                        <label :for="`service-${item.item_id}`" class="text-sm cursor-pointer">{{ item.item_name  }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <p class="whitespace-nowrap">{{ formatter.format(item.item_price) }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
            <div class="justify-between hidden w-full mt-auto text-right lg:flex">
                <button-component
                secondary
                :show-icon="false"
                text="Go Back" 
                @click.prevent="back"
                />

                <button-component
                    :disabled="!canContinue"
                    text="Continue" 
                    @click.prevent="proceed"
                />
            </div>
        </div>

    <PageLoader v-if="loader" />
    
    <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
    >
        <template #content>
            <div v-html="errorMsg"/>
        </template>
    </ErrorModal>
    
    </booking-layout>
</template>

<script setup lang="ts">
import BookingLayout from '@/layouts/BookingLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import AccordionComponent from '@/components/AccordionComponent.vue';

import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import Storage from '@/helpers/storage';

import { useRouter } from 'vue-router';
import { onMounted, ref, watch } from 'vue';

import booking from "@/classes/Booking";
import { useBookingStore } from '@/store/booking';
import { formatter } from "@/helpers/NumberHelper";

import { ChevronDownIcon } from '@heroicons/vue/20/solid'
// Router Instance
const router = useRouter();

// State Instance
const bookingStoreState = useBookingStore();

// Local Storage Instance
const bookingLocalStorage = new Storage();
const bookingLocalStoragePayload = ref(JSON.parse(bookingLocalStorage.get('bookingPayload')));
const bookingLocalStorageSummary = ref(JSON.parse(bookingLocalStorage.get('bookingSummary')));

// Data
const loader = ref(false);
const showError = ref(false);
const errorMsg = ref(null);
const selectedPackageId = ref(null)
const canContinue = ref(false)

const packages = ref<any>([]);
const services = ref<any>([]);
const allergyTests = ref<any>([]);

const selectedRiders = ref([]);
const selectedAllRiders = ref([]);
const oldSelectedAllRiders = ref([]);

// Methods
const fetchPackages = () => {
    loader.value = true

    booking.getPackages()
        .then((result: any) => {
            loader.value = false
            packages.value = result.data.response;     
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchServices = () => {
    loader.value = true

    booking.getServices()
        .then((result: any) => {
            loader.value = false
            services.value = result.data.response;            
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchAllergyTests = () => {
    loader.value = true

    booking.getAllergyTests()
        .then((result: any) => {
            loader.value = false
            allergyTests.value = result.data.response;            
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const fetchDrafts = () => {
    bookingStoreState.bookingPayload = bookingLocalStoragePayload.value || bookingStoreState.bookingPayload;
    bookingStoreState.bookingSummary = bookingLocalStorageSummary.value || bookingStoreState.bookingSummary;
    
    selectedPackageId.value = bookingStoreState.bookingPayload.package_id;
    if(selectedPackageId.value || bookingStoreState.bookingPayload.other_charges_line) {
        canContinue.value = true;
    }

    if(!bookingStoreState.bookingPayload.branch_id && !bookingStoreState.bookingPayload.patient_id) {
        router.push('/booking')
    }
}

const saveDrafts = () => {
    bookingLocalStorage.set('bookingPayload', JSON.stringify(bookingStoreState.bookingPayload));
    bookingLocalStorage.set('bookingSummary', JSON.stringify(bookingStoreState.bookingSummary));
}

const fetchDisabledDates = () => {
    loader.value = true;

    const payload = {
        package: bookingStoreState.bookingPayload.package_name,
        riders: bookingStoreState.bookingPayload.riders,
    }
    
    booking.getGetAvailableDates(payload)
        .then(({data}: any) => {
            loader.value = false
            bookingStoreState.disableDates = data.response;  
            saveDrafts();
            router.push('/booking/select-date-and-time');
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const selectedPackage = (packageId: any) =>  {
    if(selectedPackageId.value == packageId) {
        bookingStoreState.bookingPayload.package_id = 0;
        bookingStoreState.bookingPayload.package_name = null;
        bookingStoreState.bookingPayload.package_fee = 0;
        bookingStoreState.bookingSummary.selectedPackage = null;
        bookingStoreState.bookingSummary.packageFee = null;
        bookingStoreState.bookingSummary.totalFee = bookingStoreState.bookingSummary.miscFee + bookingStoreState.bookingSummary.packageFee;
    
        selectedPackageId.value = null;
        
        if(bookingStoreState.bookingPayload.other_charges_line === null) {
            canContinue.value = false;
        }
    } else {
        const filteredPackage = packages.value.find(x => x.id == packageId);
        
        bookingStoreState.bookingPayload.package_id = filteredPackage.id;
        bookingStoreState.bookingPayload.package_name = filteredPackage.name;
        bookingStoreState.bookingPayload.package_fee = filteredPackage.price;
        bookingStoreState.bookingSummary.selectedPackage = filteredPackage.name;
        bookingStoreState.bookingSummary.packageFee = filteredPackage.price;
        bookingStoreState.bookingSummary.totalFee = bookingStoreState.bookingSummary.miscFee + bookingStoreState.bookingSummary.packageFee;
    
        selectedPackageId.value = bookingStoreState.bookingPayload.package_id;
        
        if(selectedPackageId.value && bookingStoreState.bookingPayload.other_charges_line === null) {
            canContinue.value = true;
        }
    }
}

watch(() => selectedRiders.value, (val) => {
    const riders = services.value.concat(allergyTests.value);
    let filteredRiders = [];
    const otherChargesLine = [];
    const rider_item_ids = [];
    const rider_names = [];
    
    riders.forEach(rider => {
        filteredRiders = filteredRiders.concat(rider.items.filter(obj => val.includes(obj.item_id)));
    });

    filteredRiders.forEach(filteredRider => {
        const obj = {
            item_id: null,
            price: null,
        };
        obj.item_id = filteredRider.item_id;
        obj.price = filteredRider.item_price;
        otherChargesLine.push(obj)
        rider_item_ids.push(filteredRider.item_id)
    });

    riders.forEach(rider => {
        const item = rider.items.filter(obj => rider_item_ids.includes(obj.item_id));
        if(item.length > 0) {
            item.forEach(a => rider_names.push(a.item_name));
        }
    });

    bookingStoreState.bookingPayload.other_charges_line = otherChargesLine;
    bookingStoreState.bookingPayload.riders = rider_names;
    bookingStoreState.bookingPayload.other_charges_fee = filteredRiders.reduce((a, b) => a + b.item_price, 0);
    bookingStoreState.bookingSummary.selectedAddOns = filteredRiders;
    bookingStoreState.bookingSummary.miscFee = filteredRiders.reduce((a, b) => a + b.item_price, 0);
    bookingStoreState.bookingSummary.totalFee = bookingStoreState.bookingSummary.miscFee + bookingStoreState.bookingSummary.packageFee;

    if(bookingStoreState.bookingPayload.other_charges_line.length > 0) {
        canContinue.value = true;
    } else if(!bookingStoreState.bookingPayload.package_id){
        canContinue.value = false;
    }

});

watch(() => selectedAllRiders.value, (val) => {
    // TODO : Unselect All
    // checkForUnAllRiders();

    const riders = services.value.concat(allergyTests.value);
    const selectedParentRiders = riders.filter(obj => val.includes(obj.id));

    // selectedRiders.value = [];
    selectedParentRiders.forEach(selectedParentRider => {
        const riderIds = selectedParentRider.items.map(a => a.item_id)
        selectedRiders.value = selectedRiders.value.concat(riderIds);
    });

    oldSelectedAllRiders.value = selectedAllRiders.value;
});

const checkForUnAllRiders = () => {
    console.log(oldSelectedAllRiders.value);
}

const proceed = () => {
    if(canContinue.value) {
        fetchDisabledDates()
    }
}

const back = () => {
    router.push('/booking');
}

const isActive = ref(null);
const toggleItem = (index: any) => {
    if(isActive.value == index) {
        isActive.value = null;
    } else {
        isActive.value = index;
    }
}

onMounted( () => {
    fetchPackages();
    fetchServices();
    fetchAllergyTests();
    fetchDrafts();
});
</script>