<template>
    <user-layout
    color="light">
        <div class="w-[90%] mx-auto py-10" ref="top">
            <div class="flex items-center justify-between w-full mb-14">
                <h1 class="text-4xl font-bold text-primary-700">
                    General Medical Questionnaire
                </h1>
                <div class="flex items-center space-x-8">
                    <div class="relative cursor-pointer" @click.prevent="router.push('/account-details')">
                        <UserCircleIcon class="w-7 h-7" />
                    </div>
                    <div class="relative cursor-pointer" @click.prevent="router.push('/notifications')">
                        <BellIcon class="w-7 h-7" />
                        <div class="absolute top-0 right-0 w-4 h-4 bg-red-700 border-4 border-white rounded-full has-notif"></div>
                    </div>
                </div>
            </div>

            <p v-if="step == 1" class="mb-5 leading-loose">*By submitting your information below, you agree for SHSC, its subsidiary and/or its affiliate authorized third party contractors to process your information as set forth in our Privacy Notice found on our company website. / 以下にお客様の情報を送信することにより、SHSC、その子会社および/関連会社が認定した第三者請負業者が、当社のウェブサイトに掲載されているプライバシー通知に記載されているとおりにお客様の情報を処理することに同意したものとみなされます。</p>

            <template v-if="showForm">
                <p class="mb-2 font-bold">Part {{ step == 1 ? '1' : '2' }} of 2</p>
                <!-- <div @click.prevent="backToAppointment">
                <a  class="flex items-center font-bold cursor-pointer text-primary-500">
                    <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                    Return
                </a>
            </div> -->

                <div class="space-y-9" v-if="step == 1">
                    <BookingInformation 
                        :form          = "booking_information"
                        :disableInputs = "true"
                    />

                    <PersonalInformation 
                        :form          = "patientForm"
                        :provinces     = "provinces"
                        :cities        = "cities"
                        :barangays     = "barangays"
                        :errors        = "errors"
                        :disableInputs = "disableInputs"
                        @fetchCity     = "fetchCity"
                        @fetchBrgy     = "fetchBrgy"
                        @clearCity     = "clearCity"
                        @clearBgy      = "clearBgy"
                    />

                    <CompanyInformation 
                        :form          = "for_company_health_checkup"
                        :errors        = "errors"
                        :disableInputs = "disableInputs"
                    />

                    <MedicalHistory 
                        :disableInputs="disableInputs"
                        :form="medical_history" 
                        :illnesses="illnesses"
                    />

                    <AllergiesInformation 
                        v-if="showForm"
                        :form="allergies"
                        :errors = "errors"
                        :disableInputs="disableInputs"
                    />

                    <MedicationHistory 
                        :form="medication_history" 
                        :disableInputs="disableInputs"
                    />
                    <!-- <FamilyHistory
                    :form="family_history" 
                    :illnesses="illnesses"
                    :disableInputs="disableInputs"
                /> -->

                    <ObjectiveSymptoms 
                        :form="objective_symptoms" 
                        :disableInputs="disableInputs"
                    />

                    <OccupationForm 
                        :form="occupation_employment" 
                        :disableInputs="disableInputs"
                    />
                </div>

                <div class="space-y-9" v-show="step == 2">
                    <SpecificHealthAssessment 
                        :form="specific_health_assessment" 
                        :errors="errors"
                        :disableInputs="disableInputs"
                    />
                    <OBGYNHistory 
                        :form="obgyne_history" 
                        :errors="errors"
                        :disableInputs="disableInputs"
                    />
                    <!-- <HypersensitivityReactions 
                    :form="hypersensitivity_reaction" 
                    :disableInputs="disableInputs"
                /> -->
                </div>

                <div class="mt-16 text-center">
                    <ButtonComponent 
                        v-if="step == 1"
                        @click="scrollToTop(2)"
                        :text="disableInputs ? 'Next' : 'Go to next step'"
                    />

                    <div class="space-x-5" v-if="step == 2">
                        <ButtonComponent
                            secondary 
                            :show-icon="false"
                            @click="scrollToTop(1)"
                            text="Go back"
                        />
                        <ButtonComponent v-if="!disableInputs"
                            @click="show = true"
                            text="Submit"
                        />
                    </div>
                </div>
            </template>
            
            <template v-else>
                <div>
                    Loading form
                </div>
            </template>

            

        </div>

        <GeneralModal
            width="w-[548px]"
            title="Confirm Information"
            action-text="Submit"
            cancel-text="Go back"
            :show="show"
            :loader="loader"
            @close="show = false"
            @confirm="submit"
        >
            <template #content>
                <p>Please be informed that any changes after submission of this form cannot be applied.</p>
                <br>
                <p>If all the information you input is final, click “Submit”.</p>
            </template>
        </GeneralModal>

        <GeneralModal
            width="w-[416px]"
            title="Success!"
            content="Medical General Medical Questionnaire submitted successfully."
            action-text="Ok"
            :show="showSuccess"
            @close="showSuccess = false"
            @confirm="showSuccess = false"
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />
        <PageLoader v-if="!showForm" />
    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import GeneralModal from '@/components/GeneralModal.vue';

import BookingInformation from './components/BookingInformation.vue';
import PersonalInformation from './components/PersonalInformation.vue';
import CompanyInformation from './components/CompanyInformation.vue';
import AllergiesInformation from './components/AllergiesInformation.vue';
import MedicalHistory from './components/MedicalHistory.vue';
import MedicationHistory from './components/MedicationHistory.vue';
import FamilyHistory from './components/FamilyHistory.vue';
import ObjectiveSymptoms from './components/ObjectiveSymptoms.vue';

import OBGYNHistory from './components/OBGYNHistory.vue';
import OccupationForm from './components/OccupationForm.vue';
import HypersensitivityReactions from './components/HypersensitivityReactions.vue';
import SpecificHealthAssessment from './components/SpecificHealthAssessment.vue';

import PageLoader from "@/components/PageLoader.vue";
import location from "@/classes/LocationService";
import auth from "@/classes/AuthService";
import MedicalService from "@/classes/MedicalService";
import ErrorModal from '@/components/ErrorModal.vue';
import patient from "@/classes/UserData";

import { ref, onMounted, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { BellIcon, UserCircleIcon } from '@heroicons/vue/24/outline';

import { 
    IPatientForm, IMedicalHistory, IMedicationHistory, 
    IFamilyHistory, IObgyneHistory, IOccupationEmployment, 
    IHypersensitivityReaction, ISpecificHealthAssessment,
    ICompanyHealthCheckup, IAllergies, IChildrenDelivery
} from '@/interfaces/PatientInterface'


const route = useRoute();
const router = useRouter();

const errorMsg    = ref()
const errors      = ref([])
const showError   = ref(false)
const loader      = ref(false)
const show        = ref(false);
const showSuccess = ref(false);
const showForm = ref(false);
const step        = ref(1);
const top         = ref();

/**
 * ************************************
 * Form Data
 * ************************************
 */
const patientData                  = ref({})
const hyperSensitivityReactionData = ref({})
const medicalHistoryData           = ref({})
const medicationHistoryData        = ref({})
const familyHistoryData            = ref({})
const obgyneHistoryData            = ref({})
const objectiveSymptomsData        = ref({})
const occupationEmploymentData     = ref({})
const specificHealthAssessmentData  = ref({})
const allergiesData  = ref({})
const forCompanyHealthCheckup  = ref({})
const bookingId   = route.params.bookingId;

const booking_information = ref({
    booking_date: null,
    patient_id: null,
})

const patientForm = ref<IPatientForm>({
  suffix           : null,
  first_name       : null,
  middle_name     : null,
  last_name       : null,
  province        : null,
  city            : null,
  barangay        : null,
  street          : null,
  building_number : null,
  zip_code        : null,
  sex             : null,
  birthdate       : null,
  telephone_number: null,
  cellphone_number: null,
  nationality     : null,
  email_address   : null,
  no_visits       : null,
  hospital        : null,
  doctor          : null,

  company_name: null,
  company_no: null,
  hmo_name: null,
  hmo_no: null,
  rank_and_file: null,
  supervisory: null,
  managerial: null,
  top_management: null,
  
  number_of_clinic_visits: null,
  family_hospital: null,
  family_doctor: null,
});

const medical_history = ref<IMedicalHistory>({
//   last_checkup: {
//     date : null,
//     where: null,
//     other: null,
//   },
//   family_hospital_doctor : null,
//   specify_family_hospital: null,
  illness_history: [{
    is_checked    : false,
    description   : null,
    status        : null,
    family_member : [],
  }]
});

const medication_history = ref<IMedicationHistory[]>([
  {
    medication_name: null,
    illness_symptom_name: null,
    intake_schedule: null
  }
]);

const allergies = ref<IAllergies>({
    has_allergies: false,
    medication: null,
    food: null,
    specify_other: null,
});

const for_company_health_checkup = ref<ICompanyHealthCheckup>({
    company_name: null,
    company_no: null,
    hmo_name: null,
    hmo_no: null,
    rank_and_file: null,
    supervisory: null,
    managerial: null,
    top_management: null,
    test_result_option: null,
    specify_others_option: null,
});

const family_history = ref<IFamilyHistory[]>([
    {
        illness_or_condition: null,
        age_first_detected: null,
        relationship: null,
    }
])

const objective_symptoms = ref([])

const obgyne_history = ref<IObgyneHistory>({
    first_menstruation_age: null,
    is_menopause_reached: null,
    menopause_age:  null,
    is_period_regular: null,
    period_day_interval:  null,
    bleeding_duration_days:  null,
    period_flow: null,
    is_period_pain_experienced: null,
    yes_pain_experinced: null,
    are_painkillers_used: null,
    medication_name: null,
    last_period_start_date: null,
    last_period_end_date: null,
    had_sexual_intercourse: null,
    is_stds : null,
    stds_type : [],
    is_administering_hormone : null,
    specify_hormone : null,
    is_pregnant : null,
    weeks_pregnant :  null,
    been_pregnant : null,
    has_miscarriage_abortion : null,
    miscarriage_count :  null,
    abortion_count :  null,
    has_child : null,
    children_delivery : [],
    is_breastfeeding : null,
    is_breast_surgery : null,
    is_breast_tested : null,
    breast_mammography : null,
    breast_ultrasound : null,
    breast_visual_palpation : null,
    had_pap_smear : null,
    last_pap_smear_date : null,
    pap_smear_result: null,
    pap_smear_result_details :null
})

const occupation_employment = ref<IOccupationEmployment>({
    job_title : null,
    days_per_week :null,
    hours_per_day :null,
})

const hypersensitivity_reaction = ref<IHypersensitivityReaction>({
    rash_after_injection : {
        status : null,
        details : null
    },
    sick_dizzy_after_injection : {
        status : null,
        details : null
    },
    rash_after_meds_application : {
        status : null,
        details : null
    }
})

const specific_health_assessment = ref<ISpecificHealthAssessment[]>([
    { 
        description: 'Are you currently smoking cigarettes habitually*? 現在、習慣的にタバコを吸っている（電子タバコも含む)',
        jpnlabel: '*,Habitual: 100 cigarettes or more in total, or smoking for 6 months or more, and smoking for the last 1 month / これまで合計100本以上または6ヶ月以上の喫煙歴のある方. 過去1ヶ月間、毎日または時々喫煙をしている方' ,
        status: null,
        index: 0 
    },
    { 
        description: 'Have you gained at least 10 kg or more since age 20? 20歳以降、10kg以上の体重の増加がありましたか',
        jpnlabel: null,
        status: null,
        index: 1 
    },
    { 
        description: 'Do you perform light sweating exercises for at least 30 minutes at a time for at least 2 days a week for at least 1 year? 1回30分以上の軽い運動を週2日以上、1年以上行っていますか',
        jpnlabel: null,
        status: null,
        index: 2 
    },
    { 
        description: 'Do you walk, or have equivalent physical activity for 1 hour or more daily? 毎日1時間以上歩く、または同等の身体活動がありますか',
        jpnlabel: null,
        status: null,
        index: 3
    },
    { 
        description: 'Do you walk faster than people of the same age and sex? ほぼ同じ年齢の同性と比較して歩く速度が速いですか',
        jpnlabel: null,
        status: null,
        index: 4
    },
    { 
        description: "How fast do you usually eat?",
        jpnlabel: "人と比較して食べる速度が速いですか",
        status: null,
        index: 5
    },
    { 
        description: 'Do you eat dinner 2 hours before bedtime at least 3 times a week? 就寝前の2時間以内に夕食をとることが週に3回以上ありますか',
        jpnlabel: null,
        status: null,
        index: 6
    },
    { 
        description: 'Do you eat snacks after dinner at least 3 times a week? 夕食後に間食をとることが週に3回以上ありますか',
        jpnlabel: null,
        status: null,
        index: 7
    },
    { 
        description: 'Do you skip breakfast more than 3 times a week? 朝食を抜くことが週に3回以上ありますか',
        jpnlabel: null,
        status: null,
        index: 8 
    },
    // { 
    //     description: 'Currently taking medication to lower blood pressure',
    //     jpnlabel: '現在、血圧を下げる薬を服用中,' ,
    //     status: null,
    //     index: 9 
    // },
    {
        description: "How often do you drink alcohol (sake, soju, beer, Western liquor, etc.) ? 飲酒頻度はどれくらいですか",
        jpnlabel: null,
        status: null,
        index: 9 
    },
    {
        description: "How much alcohol is consumed per day on drinking days? 飲酒日の1日あたりの飲酒量はどれくらいですか",
        jpnlabel: null,
        status: null,
        index: 10
    },
    {
        description: "Do you want to improve your lifestyle such as exercise and diet? 運動やダイエットなど、生活習慣を改善したいですか",
        jpnlabel: null,
        status: null,
        index: 11
    },
    {
        description: "If you have the opportunity to receive health guidance on improving lifestyle-related diseases, would you use it? 生活習慣病を改善する保健指導を受ける機会があれば利用しますか",
        jpnlabel: null,
        status: null,
        index: 12
    }
])

const illnesses = ref([]);

const fetchIllnessConditions = () => {
    MedicalService.fetchIllnessConditions()
    .then((result: any) => {
        illnesses.value = result.data
    })
}

/**
 * ************************************
 * Location Data
 * ************************************
 */
const provinces = ref();
const cities    = ref();
const barangays = ref();

/**
 * ************************************
 * Functions
 * ************************************
 */

const fetchData = () => {
    loader.value = true

    location.fetchProvinces()
        .then((result: any) => {
            loader.value = false
            provinces.value = result.data
        })
        .catch((err: any) => errorResponse(err) )
}

const fetchCity = () => {
    loader.value = true
    cities.value = [];

    location.fetchCities(`code=${patientForm.value.province || patientData.value.province}&level=Mun`)
    .then((result: any) => {
        loader.value = false
        cities.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const fetchBrgy = () => {
    loader.value = true
    barangays.value = []

    location.fetchBarangays(`code=${patientForm.value.city}&level=Bgy`)
    .then((result: any) => {
        loader.value = false
        barangays.value = result.data
    })
    .catch((err: any) => errorResponse(err) )
}

const clearCity = () => {
    if(disableInputs.value) return;

    patientForm.value.city = null
    patientForm.value.barangay = null
}

const clearBgy = () => {
    if(disableInputs.value) return;

    patientForm.value.barangay = null
}

const backToAppointment = () => {
    window.location.assign('appointment/show/' + bookingId)
}

const scrollToTop = (e: any) => {
    step.value = e;
    top.value.scrollIntoView({ behavior: "smooth" })
}
/**
 * ************************************
 * Submit Form
 * ************************************
 */

const submit = () => {
    loader.value = true;

    // convert address code to label
    const payload = Object.assign({ 
            booking_id : bookingId,
            hospital_id : null,
        },
        {
            personal_information: patientForm.value,
            medical_history: medical_history.value,
            medication_history: medication_history.value,
            family_history: family_history.value,
            subjective_symptoms: objective_symptoms.value,
            ob_gyn_history: obgyne_history.value,
            occupation: occupation_employment.value,
            hypersensitivity_reaction: hypersensitivity_reaction.value,
            specific_health_assessment: specific_health_assessment.value,
            
            for_company_health_checkup: for_company_health_checkup.value,
            allergies: allergies.value,
        });
        let submitForm;

    if(patientData.value) {
        submitForm = MedicalService.updateMedicalQuestionnaire(payload)
    } else {
        submitForm = MedicalService.submitMedicalQuestionnaire(payload);
    }
    
    submitForm.then((response: any) => {

        show.value = false
        loader.value = false;

        if(response?.data.message == 'Unauthorized') {
            showError.value = true;
            errorMsg.value = response.message
        }else{
            showSuccess.value = true;

        setTimeout(() => {
            show.value = false;
            showSuccess.value = false;
            window.location.assign('/appointment/show/' + bookingId)
        }, 3000);
        }
    })
    .catch((err: any) => {
        showError.value = true;
        loader.value = false;

        if(err.response.status == 422) {
            errorMsg.value = err.response.data.message
            errors.value = err.response.data.errors;
        }

        if(err.response.status == 401) {
            window.location.replace('/login')
        }
    })
}

const errorResponse = (err: object) => {
    loader.value = false

    if(err.response.status == 401) {
        auth.redirectToLogin()
    }
}

const appointment = ref(null);
const fetchBooking = () => {
    loader.value = true
    
    patient.getSelectedAppointment(bookingId)
        .then((result: any) => {
            loader.value = false;
            appointment.value = result.data.response;
            if(appointment.value) {
            booking_information.value.booking_date = appointment.value.booking_date;
            booking_information.value.patient_id = appointment.value.patient.hospital_no

            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value = "Something went wrong. Please try again later";
            }
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

/**
 * ************************************
 * Computed Properties
 * ************************************
 */

const disableInputs  = computed(() => {
    return patientData.value && hyperSensitivityReactionData.value ? true : false
})



/**
 * ************************************
 * Watchers
 * ************************************
 */
watch(
      () => patientForm.value.city,
      (val) => {
        barangays.value = [];
        if(val) fetchBrgy()
      },
      { immediate: true }
    );

watch(
      () => patientForm.value.province,
      (val) => {
        cities.value = [];
        barangays.value = [];
        if(val) fetchCity()
      },
      { immediate: true }
    );

onMounted( async() => {
    loader.value = true;

    if(!route.params.bookingId) window.location.replace('/appointment');

    const patientInfo = computed(() => patient.getCurrentUser())
    // if no Bookng ID return to /overview page
    fetchData()
    fetchIllnessConditions()
    fetchBooking()

    try {
        const booking_id = parseInt(route.params.bookingId);
        const response = await MedicalService.getPatientMedicalQuestionnaire(booking_id);
        console.log(response.data);
        patientData.value                  = response.data.personal_information;
        hyperSensitivityReactionData.value = response.data.hypersensitivity_reaction;
        medicalHistoryData.value           = response.data.medical_history;
        familyHistoryData.value            = response.data.family_history;
        medicationHistoryData.value        = response.data.medication_history; // array
        obgyneHistoryData.value            = response.data.ob_gyn_history;
        objectiveSymptomsData.value        = response.data.subjective_symptoms; // array
        occupationEmploymentData.value     = response.data.occupation;
        specificHealthAssessmentData.value  = response.data.specific_health_assessment;
        forCompanyHealthCheckup.value  = response.data.for_company_health_check_up;
        allergiesData.value  = response.data.allergies;

        const defaultInfo = patientData.value ?? {
            suffix       : patientInfo.value.suffix,
            first_name   : patientInfo.value.first_name,
            middle_name : patientInfo.value.middle_name,
            last_name   : patientInfo.value.last_name,
        }

        Object.assign(patientForm.value, defaultInfo);
        Object.assign(hypersensitivity_reaction.value, hyperSensitivityReactionData.value);
        Object.assign(medical_history.value, medicalHistoryData.value);
        Object.assign(medication_history.value, medicationHistoryData.value);
        Object.assign(family_history.value, familyHistoryData.value);
        Object.assign(obgyne_history.value, obgyneHistoryData.value);
        Object.assign(objective_symptoms.value, objectiveSymptomsData.value);
        Object.assign(occupation_employment.value, occupationEmploymentData.value);
        Object.assign(specific_health_assessment.value, specificHealthAssessmentData.value);
        Object.assign(for_company_health_checkup.value, forCompanyHealthCheckup.value);
        Object.assign(allergies.value, allergiesData.value);
        showForm.value = true
    } catch (error) {
        show.value      = false;
        showError.value = true;
        loader.value    = false;

        if (error?.response?.status === 422) {
            errorMsg.value = error.response.data.message;
            errors.value   = error.response.data.errors;
        }
    }
})

</script>