<template>
    <portal-layout
    title="Your Medical Record">

        <template #fields>
            <vue-barcode v-if="hasRecord"
                :value="patientData.hospital_no" 
                :options="{ displayValue: true }" 
                class="mr-6"
            />
        </template>
        
        <MedicalRecordForm v-if="!hasRecord"/>

        <div class="" v-if="hasRecord">
            <div class="p-5 bg-white rounded-lg mb-6">
                <p class="text-xl font-bold text-primary-500 mb-6">Patient Details</p>
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5 lg:mb-14">
                        <div class="flex justify-between">
                            <p>First Name</p>
                            <p class="font-bold">{{ patientData.first_name ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Middle Name</p>
                            <p class="font-bold">{{ patientData.middle_name ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Last Name</p>
                            <p class="font-bold">{{ patientData.last_name ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5 lg:mb-14">
                        <div class="flex justify-between">
                            <p>Date of Birth</p>
                            <p class="font-bold">{{ patientData.birthdate ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Sex</p>
                            <p class="font-bold">{{ patientData.sex ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Nationality</p>
                            <p class="font-bold">{{ patientData.nationality ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Civil Status</p>
                            <p class="font-bold">{{ patientData.civil_status ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Email Address</p>
                            <p class="font-bold">{{ patientData.email_address ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Telephone Number</p>
                            <p class="font-bold">{{ patientData.telephone_number ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Cellphone Number</p>
                            <p class="font-bold">+63 {{ patientData.cellphone_number ?? '-' }}</p>
                        </div>
                    </div>
                </div>

                <div class="my-8 border-b border-black/[.04]"></div>

                <p class="font-bold text-primary-500 mb-6">Local Address</p>
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Province</p>
                            <p class="font-bold">{{ patientData.province ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Barangay</p>
                            <p class="font-bold">{{ patientData.barangay ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>No./Bldg.</p>
                            <p class="font-bold">{{ patientData.building_number ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>City/Municipality</p>
                            <p class="font-bold">{{ patientData.city ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Street</p>
                            <p class="font-bold">{{ patientData.street ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>ZIP Code</p>
                            <p class="font-bold">{{ patientData.zip_code ?? '-' }}</p>
                        </div>
                    </div>
                </div>

                <div class="my-8 border-b border-black/[.04]"></div>

                <p class="font-bold text-primary-500 mb-6">Permanent Address</p>
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Province</p>
                            <p class="font-bold">{{ patientData.permanent_province ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Barangay</p>
                            <p class="font-bold">{{ patientData.permanent_barangay ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>No./Bldg.</p>
                            <p class="font-bold">{{ patientData.permanent_building_number ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>City/Municipality</p>
                            <p class="font-bold">{{ patientData.permanent_city ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Street</p>
                            <p class="font-bold">{{ patientData.permanent_street ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>ZIP Code</p>
                            <p class="font-bold">{{ patientData.permanent_zip_code ?? '-' }}</p>
                        </div>
                    </div>
                </div>

                <div class="my-8 border-b border-black/[.04]"></div>

                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>PhilHealth No</p>
                            <p class="font-bold">{{ patientData.philhealth_number ?? '-' }}</p>
                        </div>
                    </div>

                    <!-- <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>HMO</p>
                            <p class="font-bold">{{ patientData.hmo_id ?? '-' }}</p>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="p-5 bg-white rounded-lg mb-6">
                <p class="text-xl font-bold text-primary-500 mb-6">Occupation</p>
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Title</p>
                            <p class="font-bold">{{ patientData.job_title ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Contact Number</p>
                            <p class="font-bold">+63 {{ patientData.company_contact_no ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Company Email Address</p>
                            <p class="font-bold">{{ patientData.company_email ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Company Name</p>
                            <p class="font-bold">{{ patientData.company_name ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Address</p>
                            <p class="font-bold text-right max-w-[70%]">{{ patientData.company_address ?? '-' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-5 bg-white rounded-lg mb-2">
                <p class="text-xl font-bold text-primary-500 mb-6">Person to notify in case of emergency</p>
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-11 space-y-2.5 lg:space-y-0">
                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Name</p>
                            <p class="font-bold">{{ patientData.contact_person_name ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Contact Number</p>
                            <p class="font-bold">+63 {{ patientData.contact_person_number ?? '-' }}</p>
                        </div>
                    </div>

                    <div class="col-span-full lg:col-span-6 space-y-2.5">
                        <div class="flex justify-between">
                            <p>Relationship</p>
                            <p class="font-bold">{{ patientData.contact_person_relationship ?? '-' }}</p>
                        </div>
                        <div class="flex justify-between">
                            <p>Email Address</p>
                            <p class="font-bold">{{ patientData.contact_person_email ?? '-' }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="text-darkgray-400 text-sm">If you want to make any changes to any of the information shown above, you may contact us via our trunkline <a href="tel:(02) 736-85239" class="font-bold">(02) 736-85239</a>.</p>
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import MedicalRecordForm from './MedicalRecordForm.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import VueBarcode from '@chenfengyuan/vue-barcode';

import patient from "@/classes/UserData";
import { ref, computed } from 'vue';

const patientData = computed(() => patient.getCurrentUser())

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)

const hasRecord = computed(() => {
    return patientData.value.last_name !== null
        && patientData.value.first_name !== null
        && patientData.value.cellphone_number !== null
        && patientData.value.civil_status !== null
        && patientData.value.sex !== null
        && patientData.value.nationality !== null
        && patientData.value.job_title !== null
        && patientData.value.birthdate !== null
        && patientData.value.email_address !== null
        && patientData.value.barangay !== null
        && patientData.value.city !== null
        && patientData.value.province !== null
        && patientData.value.company_name !== null
        && patientData.value.company_address !== null
        && patientData.value.contact_person_name !== null
        && patientData.value.contact_person_relationship !== null
        && patientData.value.contact_person_number !== null
        && patientData.value.permanent_street !== null
        && patientData.value.permanent_barangay !== null
        && patientData.value.permanent_city !== null
        && patientData.value.permanent_province !== null
});

</script>