<template>
    <portal-layout
    :defaultTitle="false">
        <template #title>
                <h1 class="text-4xl text-primary-700 font-bold">Change Password</h1> 
        </template>
        <div class="bg-white rounded-lg px-5 py-9 w-full">
            <div class="md:w-1/2">
                <div class="mb-6">
                    <password-input
                        id="current-password"
                        placeholder="Current Password"
                        v-model="payload.current_password"
                    />
                </div>
                <div class="mb-6">
                    <password-input
                        id="new-password"
                        placeholder="New Password"
                        helper
                        v-model="payload.password"
                    />
                </div>
                <div class="mb-6">
                    <password-input
                        id="confirm-new-password"
                        placeholder="Confirm New Password"
                        v-model="payload.password_confirmation"
                    />
                </div>
                <div>
                    <button-component
                        :disabled="loader"
                        text="Save Changes"
                        :type="'submit'"
                        @click="showConfirmation = true"
                    />
                </div>
            </div>
            
        </div>

        
        <PageLoader v-if="loader" />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <SuccessModal
        :show="showSuccess"
        @cancel="showSuccess = false"
        @confirm="showSuccess = false"
        action-text="Confirm"
        title="Password Submitted!"
        content="Password has been updated successfully"
        no-btn
        />

        <ConfirmationModal
        :show="showConfirmation"
        @cancel="showConfirmation = false"
        @confirm="showConfirmation = false; updatePassword() "
        cancel-text="Cancel"
        action-text="Confirm"
        title="Submit Changes?"
        content="Are you sure you wish to update this password?"
        />
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import patient from "@/classes/UserData";

const router = useRouter();

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const errors      = ref([])
const showSuccess = ref(false)
const showConfirmation = ref(false)

const patientData = computed(() => patient.getCurrentUser())
const payload = ref({
    patient_id: patientData.value.patient_id,
    current_password: null,
    password: null,
    password_confirmation: null,
})

const updatePassword = () => {
    loader.value = true;

    patient.updatePassword(payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.data[0].status == 'success') {
                showSuccess.value = true;

                payload.value = {}

                setTimeout(() => {
                    window.location.replace('/account-details')
                }, 1000)
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 422) {
                errors.value = error.response.data.errors;
            }

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}


</script>