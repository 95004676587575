<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">I. Personal Information 個人情報</p>
        <!-- <p class="mb-6 text-xs font-bold text-primary-500">個人情報</p> -->
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-4">
                <text-input
                    id="suffix"
                    :disabled="disableInputs"
                    v-model="item.suffix"
                    type="text"
                    placeholder="Suffix / サフィックス(ある場合のみ)"
                    :error="errors['personal_information.suffix'] ? errors['personal_information.suffix'][0] : ''"
                />
            </div>
            <div class="hidden col-span-8 lg:block"></div>
            <div class="col-span-full lg:col-span-4">
                <text-input
                    id="last-name"
                    :disabled="disableInputs"
                    v-model="item.last_name"
                    type="text"
                    placeholder="Last Name / 姓"
                    :error="errors['personal_information.first_name'] ? errors['personal_information.first_name'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-4">
                <text-input
                    id="first-name"
                    :disabled="disableInputs"
                    v-model="item.first_name"
                    type="text"
                    placeholder="Given Name / 名"
                    :error="errors['personal_information.first_name'] ? errors['personal_information.first_name'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-4">
                <text-input
                    id="middle-name"
                    :disabled="disableInputs"
                    v-model="item.middle_name"
                    type="text"
                    placeholder="Middle Name / ミドルネーム(ある場合のみ"
                />
            </div>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <CalendarPicker 
                    :disabled="disableInputs"
                    :key="item.birthdate"
                    v-model="item.birthdate"
                    id="date-of-birth_pi" 
                    :inline="false"
                    placeholder="Date of Birth / 生年月日"
                    :error="errors['personal_information.birthdate'] ? errors['personal_information.birthdate'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    :add_on_left="true"
                    add_on_text="+63"
                    @keypress="numberonly"
                    :placeholder="'Cellphone Number /携帯電話番号'"
                    :maxlength=10
                    v-model="item.cellphone_number"
                    :error="errors.cellphone_number ? errors.cellphone_number[0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <MultiselectInput
                    id="province"
                    :disabled="disableInputs"
                    v-model="item.sex"
                    placeholder="Sex / 性別"
                    :options="gender"
                    :error="errors['personal_information.sex'] ? errors['personal_information.sex'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="email"
                    :disabled="disableInputs"
                    v-model="item.email_address"
                    type="email"
                    placeholder="Email Address / E-mailアドレス"
                    :error="errors['personal_information.email_address'] ? errors['personal_information.email_address'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="nationality"
                    :disabled="disableInputs"
                    v-model="item.nationality"
                    type="text"
                    placeholder="Nationality / 国籍"
                    :error="errors['personal_information.nationality'] ? errors['personal_information.nationality'][0] : ''"
                />
            </div>
            <!-- <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    id="telephone"
                    v-model="item.telephone_number"
                    @keypress="numberonly($event.target.value)"
                    type="text"
                    placeholder="Telephone Number / 自宅電話番号"
                />
            </div> -->
        </div>

        <div class="mb-5 mt-8 border-b border-black/[.04]"></div>

        <p class="mb-2 font-bold text-darkgray-800">Current Delivery Address / 住所</p>
        <!-- <p class="mb-2 text-xs font-bold text-darkgray-800"></p> -->
        <p class="text-darkgray-800">If you wish to send to another address, please ask at the reception on the day of your appointment.</p>
        <p class="mb-5 text-xs text-darkgray-800">ほかの送付先を希望する場合は当日レセプションに申し付けください。</p>
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <MultiselectInput
                    :disabled="disableInputs"
                    id="province"
                    @click="$emit('clearCity')"
                    v-model="item.province"
                    placeholder="Province"
                    :options="provinces"
                    :error="errors['personal_information.province'] ? errors['personal_information.province'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <MultiselectInput
                    id="city"
                    :disabled="disableInputs"
                    v-model="item.city"
                    @click="$emit('clearBgy')"
                    placeholder="City/Municipality"
                    :options="cities"
                    :error="errors['personal_information.city'] ? errors['personal_information.city'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <MultiselectInput
                    :disabled="disableInputs"
                    id="barangay"
                    v-model="item.barangay"
                    placeholder="Barangay"
                    :options="barangays"
                    :error="errors['personal_information.barangay'] ? errors['personal_information.barangay'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    id="street"
                    v-model="item.street"
                    type="text"
                    placeholder="Street"
                    :error="errors['personal_information.street'] ? errors['personal_information.street'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="bldg"
                    :disabled="disableInputs"
                    v-model="item.building_number"
                    type="text"
                    placeholder="No./Bldg."
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    :maxlength=4
                    v-model="item.zip_code"
                    @keypress="numberonly($event.target.value)"
                    type="text"
                    placeholder="ZIP Code"
                />
            </div>
        </div>

        <div class="mb-5 mt-8 border-b border-black/[.04]"></div>

        <div class="grid grid-cols-1 mb-10 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <p class="mb-2 font-bold">No. of visits to our clinic/当センター受診回数:</p>
                <div class="space-y-2">
                    <radio-group
                        :options="[
                            { id: 'first_time', label: '1st time/初めて', value: 'first_time'},
                            { id: 'two_or_more', label: '2 times or more/2回目以上', value: 'two_or_more'},
                        ]"
                        name="number_of_clinic_visits"
                        v-model="item.number_of_clinic_visits"
                        :disabled="disableInputs"
                    />
                </div>
            </div>
            <div class="col-span-full lg:col-span-6">
                <p class="mb-2 font-bold">Family hospitals and doctors/かかりつけの病院や医師:</p>
                <div class="space-y-2">
                    <text-input
                        id="family_hospital"
                        type="text"
                        placeholder="Hospital/病院"
                        v-model="item.family_hospital"
                        :disabled="disableInputs"
                    />
                    
                    <text-input
                        id="family_doctor"
                        type="text"
                        placeholder="Doctor/医師"
                        v-model="item.family_doctor"
                         :disabled="disableInputs"
                    />
                </div>
            </div>
        </div>

        <!-- <p class="font-bold text-darkgray-800">For Corporate Health Checkups</p>
        <p class="mb-4 text-xs font-bold text-darkgray-800">(企業健診の場合)</p>
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="company_name"
                    :disabled="disableInputs"
                    v-model="item.company_name"
                    type="text"
                    placeholder="Company Name / 会社名"
                    :error="errors['personal_information.company_name'] ? errors['personal_information.company_name'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="company_no"
                    :disabled="disableInputs"
                    v-model="item.company_no"
                    type="text"
                    placeholder="Company No. / 会社番号 :"
                    :error="errors['personal_information.company_no'] ? errors['personal_information.company_no'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="hmo_name"
                    :disabled="disableInputs"
                    v-model="item.hmo_name"
                    type="text"
                    placeholder="HMO Name / 保険会社名"
                    :error="errors['personal_information.hmo_name'] ? errors['personal_information.hmo_name'][0] : ''"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="hmo_no"
                    :disabled="disableInputs"
                    v-model="item.hmo_no"
                    type="text"
                    placeholder="HMO No. / 保険番号 :"
                    :error="errors['personal_information.hmo_no'] ? errors['personal_information.hmo_no'][0] : ''"
                />
            </div>
        </div>
        <p class="mt-4 font-bold text-darkgray-800">Job Category</p>
        <p class="mb-4 text-xs font-bold text-darkgray-800">職種</p>
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="rank_and_file"
                    v-model="item.rank_and_file"
                    :error="errors['personal_information.rank_and_file'] ? errors['personal_information.rank_and_file'][0] : ''"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.rank_and_file"
                >
                    <div class="ml-2">
                        <label for="rank_and_file" class="block leading-5 text-darkgray-400">
                            Rank and File / 一般社員
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="supervisory"
                    v-model="item.supervisory"
                    :error="errors['personal_information.supervisory'] ? errors['personal_information.supervisory'][0] : ''"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.supervisory"
                >
                    <div class="ml-2">
                        <label for="supervisory" class="block leading-5 text-darkgray-400">
                            Supervisory / 主任
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="managerial"
                    v-model="item.managerial"
                    :error="errors['personal_information.managerial'] ? errors['personal_information.managerial'][0] : ''"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.managerial"
                >
                    <div class="ml-2">
                        <label for="managerial" class="block leading-5 text-darkgray-400">
                            Managerial / 管理職
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="top_management"
                    v-model="item.top_management"
                    :error="errors['personal_information.top_management'] ? errors['personal_information.top_management'][0] : ''"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.top_management"
                >
                    <div class="ml-2">
                        <label for="top_management" class="block leading-5 text-darkgray-400">
                            Top Management / 経営者
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
        </div> -->
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import patient from "@/classes/UserData";
import { ref } from 'vue';
import { numberonly } from "@/helpers/NumberHelper";

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Array,
        required: true
    },
    provinces: {
        type: Object,
        required: true
    },
    cities: {
        type: Object,
        required: true
    },
    barangays: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        default: false
    }
})

const gender = patient.getGender()
const item = ref(props.form)

</script>