<template>
    <ion-app>
        <ion-router-outlet animated="false"/>
    </ion-app>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from "@/classes/AuthService";

const router = useRouter();

const checkAuthSession = () => {
    const onAuthRoute = router.currentRoute.value.meta.needsAuth
    if(!AuthService.isAuthenticated() && onAuthRoute) {
        AuthService.logout()
    }
}

onMounted( () => {
    checkAuthSession();
})
</script>
