<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="text-xl font-bold text-primary-500 mb-2.5">III. Medical History 病歴</p>
        <!-- <p class="mb-6 text-xs font-bold text-primary-500">病歴</p> -->
        <p class="text-darkgray-800">Check the box if you have or had in the past any of the following: </p>
        <p class="mb-5 text-xs text-darkgray-800">現在かかっている病気、または過去にかかった病気がある場合は、下記のボックスにチェックを入れてください。</p>
        <!-- <p class="text-darkgray-800">If you wish to send to another address, please ask at the reception on the day of your appointment.</p> -->
        <!-- <p class="mb-5 text-xs text-darkgray-800">ほかの送付先を希望する場合は当日レセプションに申し付けください。</p> -->
        <!-- <div class="w-full lg:w-1/2 lg:pr-4 mb-7">
            <CalendarPicker 
                :disabled="disableInputs"
                :inline="false"
                v-model="item.last_checkup.date"
                :key="item.last_checkup.date"
                placeholder="Date of your last check-up"
                helper="最後に受けた検診日(月/日/年)"
            />
        </div>
        <div class="grid grid-cols-1 mb-10 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <p class="font-bold">Where was your last check-up?</p>
                <p class="mb-3 text-xs font-bold">最後に受診された健診施設はどちらですか？。</p>
                <div class="space-y-2">
                    <radio-group
                        :isDisabled="disableInputs"
                        v-model="item.last_checkup.where"
                        :options="[
                            { id: 'shinagawa', label: 'Shinagawa Diagnostic and Preventive Care', value: 'Shinagawa Diagnostic and Preventive Care'},
                            { id: 'other_hospital', label: 'Another hospital/clinic (please specify):', value: 'other'},
                        ]"
                        name="last_checkup"
                    />
                </div>
                <div class="mt-3 lg:pl-7">
                    <text-input
                        :disabled="item.last_checkup.where != 'other' || disableInputs"
                        id="last-checkup"
                        type="text"
                        v-model="item.last_checkup.other"
                        placeholder="Type here"
                        helper="その他の病院／クリニック（詳細を入力してください）"
                    />
                </div>
            </div>
            <div class="col-span-full lg:col-span-6">
                <p class="font-bold">Do you have a family doctor/hospital?</p>
                <p class="mb-3 text-xs font-bold">かかりつけの医師や病院はありますか？</p>
                <div class="space-y-2">
                    <radio-group
                        :isDisabled="disableInputs"
                        v-model="item.family_hospital_doctor"
                        :options="[
                            { id: '1', label: 'No', value: 'No'},
                            { id: '2', label: 'Yes (please specify):', value: 'Yes'},
                        ]"
                        name="family_hospital_doctor"
                    />
                </div>
                <div class="mt-3 lg:pl-7">
                    <text-input
                        v-model="item.specify_family_hospital"
                        :disabled="item.family_hospital_doctor != 'Yes' || disableInputs"
                        id="family-doctor"
                        type="text"
                        placeholder="Type here"
                        helper="その他の病院／クリニック（詳細を入力してください）"
                    />
                </div>
            </div>
        </div> -->

        <div class="mb-4 space-y-1 text-darkgray-800">
            <p class="font-bold">Your illnesses/conditions</p>
            <!-- <p class="text-darkgray-800">Type/select your existing illness/condition, and indicate the age of when it was first detected and its current status.</p>
            <p class="text-darkgray-800">Skip this step if none.</p> -->
        </div>
        <div 
            class="grid grid-cols-1 p-5 mb-2 lg:grid-cols-12 gap-x-8 gap-y-5 bg-primary-50 rounded-2xl lg:p-7"
            v-for="(illness, index) in item.illness_history" :key="index"
        >
            <div v-if="index >= 1" class="text-right col-span-full">
                <small v-if="!disableInputs" @click="remove(index)" class="text-sm text-red-500 cursor-pointer">Remove</small>
            </div>

            <div class="col-span-full lg:col-span-6">
                <MultiselectInput
                    :disabled="disableInputs"
                    :id="`${illness}-${index}`"
                    v-model="illness.description"
                    placeholder="Choose illness/condition / 病名"
                    :options="illnesses"
                    @update:modelValue=" (value) => (illness.is_checked = value != null)"
                />
            </div>
            <!-- <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    v-model="illness.age"
                    id="age-first-detected"
                    type="text"
                    placeholder="Age first detected"
                />
            </div> -->
            <div class="col-span-full">
                <p class="mb-1 font-bold">Medical History/ご本人の病歴</p>
                <p class="mb-4">Right now, this illness/condition is...</p>
                <div class="flex justify-between lg:flex-row flex-col flex-wrap gap-2.5">
                    <radio-group
                        :isDisabled="disableInputs"
                        :id="`radio-${illness}-${index}`"
                        :name="`radio-${illness}-${index}`"
                        v-model="illness.status"
                        :options="illnessStatus"
                    />
                </div>
            </div>

            <div class="col-span-full">
                <p class="mb-1 font-bold">Family Medical History</p>
                <p class="mb-4">Is there anyone in your family who has/had this same illness/condition?</p>
                <div class="flex justify-between lg:flex-row flex-col flex-wrap gap-2.5">
                    <checkbox-input 
                        v-for="i in medicalHistoryOption"
                        :key="i.value"
                        :id="i.label"
                        :label="i.label"
                        :rounded="false"
                        text-color="text-darkgray-800"
                        :disabled="inMedicalHistory(illness, 'none') && i.value != 'none'"
                        :isChecked="inMedicalHistory(illness, i.value)"
                        @click="addRemoveMedicalHistory(illness, i.value)"
                    />

                </div>
            </div>
        </div>

        <p v-if="!disableInputs" @click="addIllness" class="mt-2 text-sm font-bold transition cursor-pointer text-primary-500 hover:text-primary-700 w-fit">Add another +</p>
    </div>
</template>

<script setup lang="ts">
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import { ref, watch } from 'vue';
import MedicalService from "@/classes/MedicalService";

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    illnesses: {
        type: Array,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: true
    },
})

const item = ref(props.form)
const illnessStatus = ref(MedicalService.getIllnessStatus());

const addIllness = () => {
    item.value.illness_history.push({
        is_checked: false,
        description: null,
        status: null,
        family_member: [],
    })
}

const remove = (index : number) => {
    item.value.illness_history.splice(index, 1);
}

const inMedicalHistory = (item: any, val : string): boolean => {
    return item.family_member.findIndex((member:any) => member == val) != -1
}

const addRemoveMedicalHistory = (item: any, val : string):void => {
    const index = item.family_member.findIndex((member:any) => member == val)
    const hasNone = inMedicalHistory(item, 'none');

    if(index != -1){
        item.family_member.splice(index, 1);
    } else {
        if(val == 'none'){
            item.family_member = [];
        }

        if(!hasNone){
            item.family_member.push(val);
        }
    }
    
}

const medicalHistoryOption = [
    { 
        label: 'None', value: 'none'
    },
    { 
        label: 'Father/父', value: 'father'
    },
    { 
        label: 'Mother/母', value: 'mother'
    },
    { 
        label: 'Sibling/きょうだい', value: 'sibling'
    },
]

// watch(
//       () => item.value.last_checkup.where,
//       (val) => {
//         if(val == 'Shinagawa Diagnostic and Preventive Care') {
//             item.value.last_checkup.other = null
//         }
//       },
//       { immediate: true }
//     );

watch(
      () => item.value.family_hospital_doctor,
      (val) => {
        if(val == 'No') {
            item.value.specify_family_hospital = null
        }
      },
      { immediate: true }
    );

</script>