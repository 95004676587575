<template>
    <div class="relative py-5 px-[18px] rounded-[15px] flex items-center space-x-5 bg-white cursor-pointer"
    :class="selectedBranchId == item.id ? 'ring-[3px] ring-primary-500' : ''">
        <img 
            :src="item.image_path ? item.full_image_path : '/assets/images/shinagawa-bgc-branch.jpeg'"
            class="w-[110px] h-[110px] object-cover rounded-[5px] flex-shrink-0"
        >
        <div class="space-y-1">
            <p class="font-bold">{{ item.name || 'Shinagawa BGC' }}</p>
            <a 
            target="_blank"
            rel="noopener noreferrer"
            class="flex space-x-1"
            href="https://goo.gl/maps/hZmbsFypDA9fj5k48">
                <MapPinIcon class="w-[18px] h-[18px] flex-shrink-0 text-primary-500 mt-0.5"/>
                <span class="text-sm">{{ item.address_line || '--' }}</span>
            </a>
            <a 
            target="_blank"
            rel="noopener noreferrer"
            class="flex space-x-1"
            :href="`tel:${item.mobile_number}`">
                <PhoneIcon class="w-[18px] h-[18px] flex-shrink-0 text-primary-500 mt-0.5"/>
                <span class="text-sm">{{ item.mobile_number || '--' }}</span>
            </a>
        </div>
        <div 
        class="absolute z-20 flex items-center justify-center w-5 h-5 rounded-full -top-2 -right-2 bg-primary-500"
        v-if="selectedBranchId == item.id">
            <CheckIcon 
            class="w-3 h-3 text-white"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
// import BookingLayout from '@/layouts/BookingLayout.vue';
// import ButtonComponent from '@/components/ButtonComponent.vue';
import { CheckIcon, MapPinIcon, PhoneIcon } from "@heroicons/vue/24/outline";

// import { useRouter } from 'vue-router';
import { computed } from 'vue';

// const router = useRouter();

defineProps({
    selectedBranchId: {
        type: Number,
        default: null,
    },
    item: {
        type: Object,
        default: null
    }
});

const storageUrl = computed(() => process.env.VUE_APP_API_STORAGE_URL);

</script>