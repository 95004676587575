<template>
    <user-layout
    :showFooter="false"
    >
        <div class="flex relative bg-zinc-50 min-h-[calc(100vh-85px)] lg:min-h-[calc(100vh-95px)] max-h-[calc(100vh-95px)]">
            <div class="
            frm-portal-sidebar
            w-[70%] lg:w-72 min-h-[calc(100vh-85px)] lg:min-h-[calc(100vh-95px)] max-h-[calc(100vh-95px)] bg-white border-r flex flex-col"
            :class="showMenu ? 'show' : ''">
                <XMarkIcon class="w-6 h-6 absolute top-4 right-4 text-primary-500 cursor-pointer lg:hidden"
                @click="showMenu = false"/>
                <div class="space-y-5 px-8 pt-11 pb-7 w-full">
                    <a href="/overview" class="flex items-center py-1"
                    :class="currentRouteName == 'OverviewIndex' ? 'text-primary-500 font-bold' : 'text-darkgray-400'">
                        <OverviewIcon class="mr-4"/>
                        <span>Overview</span>
                    </a>
                    <a href="/test-results" class="flex items-center py-1"
                    :class="currentRouteName == 'TestResultsIndex' || currentRouteName == 'TestResultsView' ? 'text-primary-500 font-bold' : 'text-darkgray-400'">
                        <TestResultIcon class="mr-4"/>
                        <span>Test Results</span>
                    </a>
                    <a href="/appointment" class="flex items-center py-1"
                    :class="currentRouteName == 'AppointmentIndex' || currentRouteName == 'AppointmentShow' ? 'text-primary-500 font-bold' : 'text-darkgray-400'">
                        <AppointmentIcon class="mr-4"/>
                        <span>Appointments</span>
                    </a>
                    <a href="/medical-record" class="flex items-center py-1"
                    :class="currentRouteName == 'MedicalRecordIndex' ? 'text-primary-500 font-bold' : 'text-darkgray-400'">
                        <MedicalRecordIcon class="mr-4"/>
                        <span>Medical Record</span>
                    </a>
                </div>
                <div class="border-b border-t border-black/04 px-8 py-5 w-full hidden lg:block">
                    <button-component
                    class="w-full"
                    :show-icon="false"
                    text="+ Book Now"
                    @click="router.push('/booking')"
                    />
                </div>
                <div class="mt-auto p-8 w-full">
                    <div class="">
                        <MenuButton />
                        <!-- <UserCircleIcon class="w-6 h-6 text-primary-500"/> -->

                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full lg:w-0 flex-1 py-8 px-5 lg:px-11 max-h-[calc(100vh-95px)] min-h-[calc(100vh-85px)] lg:min-h-[calc(100vh-95px)] overflow-auto">
                <div class="flex justify-between items-center mb-4 lg:hidden">
                    <div class="flex items-center text-primary-500 cursor-pointer" @click="showMenu = true">
                        <Bars3Icon class="w-6 h-6 mr-2"/>
                        <p class="font-bold">Menu</p>
                    </div>
                    <div>
                        <button-component
                        class="w-full"
                        :show-icon="false"
                        text="+ Book Now"
                        @click="router.push('/booking')"
                        />
                    </div>
                </div>
                <div class="flex flex-wrap lg:flex-nowrap items-center justify-between mb-6">
                    <h1 v-if="defaultTitle" class="text-4xl font-bold text-primary-700 mb-5 lg:mb-0">{{ title }}</h1>
                    <slot v-else name="title"/>
                    <div class="flex items-center w-full lg:w-fit">
                        <slot name="fields"/>
                        <!-- @TODO: to implement notification page -->
                        <!-- <div class="w-7 h-7 relative" v-if="!hideNotification">
                            <span v-if="notifCount != 0" class="w-2.5 h-2.5 rounded-full bg-red-600 block absolute top-0.5 right-0.5 border-2 border-white cursor-pointer"></span>
                            <BellIcon @click="router.push('/notification')" class="w-7 h-7 text-darkgray-900/[0.72] cursor-pointer"/>
                        </div> -->
                    </div>
                </div>
                <slot />
            </div>
        </div>
    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MenuButton from '@/components/MenuButton.vue';
import OverviewIcon from '@/components/icons/OverviewIcon.vue';
import TestResultIcon from '@/components/icons/TestResultIcon.vue';
import AppointmentIcon from '@/components/icons/AppointmentIcon.vue';
import MedicalRecordIcon from '@/components/icons/MedicalRecordIcon.vue';
import { BellIcon, Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";

import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const currentRouteName = router.currentRoute.value.name;
const showMenu = ref(false);

defineProps ({
    notifCount: {
        type: Number,
        default: 0
    },
    title: {
        type: String,
    },
    defaultTitle: {
        type: Boolean,
        default: true
    },
    hideNotification: {
        type: Boolean,
        default: false
    },
});

</script>