<template>
    <TransitionRoot appear :show="show" as="template">
        <Dialog as="div" @close="$emit('close')" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 text-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                        :class="width"
                        class="transform overflow-hidden rounded bg-white py-16 px-14 text-center align-middle transition-all"
                        >
                            <DialogTitle
                                as="p"
                                class="text-xl font-bold text-red-500"
                            >
                                {{ title }}
                            </DialogTitle>
                            <div v-if="!viewErrors" class="mt-3 text-darkgray-800 leading-7">
                                <p v-if="content">{{ content }}</p>
                                <slot name="content" />
                            </div>

                            <div v-if="viewErrors" class="max-h-[150px] overflow-y-auto">
                                <ul>
                                    <li
                                    v-for="(error, index) in errors"
                                    :key="index"
                                    class="text-red-500">
                                        {{ error[0] }}
                                    </li>
                                </ul>
                            </div>

                            <div class="mt-6">
                                <!-- <button-component
                                @click="$emit('confirm')"
                                :text="actionText"
                                /> -->
                                <!-- <p 
                                @click="$emit('close')"
                                class="mt-5 text-red-500 cursor-pointer" 
                                v-if="cancelText">{{ cancelText }}
                                </p> -->
                                <button
                                v-if="Object.keys(errors).length !== 0"
                                @click="viewErrors = !viewErrors"
                                :type="'button'"
                                class="
                                    mr-2
                                    inline-flex 
                                    items-center 
                                    text-center
                                    justify-center
                                    border-solid
                                    border-primary-500
                                    border-2
                                    h-[50px] 
                                    rounded-full 
                                    pl-2
                                    transition-colors
                                    ease-in
                                    relative
                                    focus:border-primary-500
                                    focus:after:opacity-0
                                    after:absolute
                                    after:left-0
                                    after:w-[70%]
                                    after:h-full
                                    after:z-[-1]
                                    after:rounded-l-full
                                    after:opacity-0
                                    after:transition-all
                                    after:duration-300
                                    after:bg-gradient-to-r 
                                    after:from-white/50
                                    after:to-transparent
                                    hover:after:opacity-100
                                    hover:after:z-10
                                    disabled:after:opacity-0
                                    disabled:bg-neutral-200
                                    disabled:border-primary-500
                                "
                                >
                                    <span class="font-bold pl-4 mx-auto pr-5 text-primary-500">
                                        {{ viewErrors ? 'Hide Errors' : 'View Errors' }}
                                    </span>
                                </button>

                                <button
                                @click="$emit('close')"
                                :type="'button'"
                                class="
                                    inline-flex 
                                    items-center 
                                    text-center
                                    justify-center
                                    border-solid
                                    border-red-500
                                    border-2
                                    h-[50px] 
                                    rounded-full 
                                    pl-2
                                    transition-colors
                                    ease-in
                                    relative
                                    focus:border-red-500
                                    focus:after:opacity-0
                                    after:absolute
                                    after:left-0
                                    after:w-[70%]
                                    after:h-full
                                    after:z-[-1]
                                    after:rounded-l-full
                                    after:opacity-0
                                    after:transition-all
                                    after:duration-300
                                    after:bg-gradient-to-r 
                                    after:from-white/50
                                    after:to-transparent
                                    hover:after:opacity-100
                                    hover:after:z-10
                                    disabled:after:opacity-0
                                    disabled:bg-neutral-200
                                    disabled:border-red-500
                                "
                                >
                                    <span class="font-bold pl-4 mx-auto pr-5 text-red-500">
                                        Go Back
                                    </span>
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue'
import { ref } from 'vue';

defineProps({
    show: {
        type: Boolean
    },
    title: {
        type: String,
    },
    content: {
        type: String,
    },
    actionText: {
        type: String,
    },
    cancelText: {
        type: String,
    },
    width: {
        type: String,
    },
    errors : {
        type: Array,
        default: () => {
            return [];
        }
    }
})

const viewErrors = ref(false);
</script>