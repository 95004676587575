<template>
    <portal-layout
    title="Japan Standard Health Checkup"
    :hide-notification="true">
        <!-- <template #fields>
            <ButtonComponent
            text="View Results"
            />
        </template> -->
        
        <div class="flex flex-wrap border-b text-darkgray-900 pb-7 mb-7 border-gray-40">
            <div class="w-1/2">
                <div class="grid grid-cols-2 gap-1">
                    <div class="col-span-1">
                        <p>Date Booked</p>
                    </div>
                    <div class="col-span-1 text-right">
                        <p class="font-bold">January 24, 2023</p>
                    </div>
                    <div class="col-span-1">
                        <p>Time Booked</p>
                    </div>
                    <div class="col-span-1 text-right">
                        <p class="font-bold">10:00 AM - 10:15 AM</p>
                    </div>
                </div>
            </div>
            <div class="w-1/2 pl-12">
                <div class="grid grid-cols-2 gap-1">
                    <div class="col-span-1">
                        <p>Schedule Logs</p>
                    </div>
                    <div class="col-span-1 text-right">
                        <p class="font-bold text-primary-500">Show</p>
                    </div>
                    <div class="col-span-1">
                        <p>Medical Questionnaire</p>
                    </div>
                    <div class="col-span-1 text-right">
                        <p class="font-bold text-primary-500">View</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex items-center space-x-3 mb-7">
            <button type="button" class="px-5 py-2 text-sm font-bold leading-loose transition rounded-3xl"
                :class="tabSelected == 'Information' ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'"
                @click="tabSelected = 'Information'"
            >
                Information
            </button>
            <button type="button" class="px-5 py-2 text-sm font-bold leading-loose transition rounded-3xl"
                :class="tabSelected == 'Test Results' ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'"
                @click="tabSelected = 'Test Results'"
            >
                Test Results
            </button>
        </div>

        <div v-if="tabSelected == 'Information'">

            <div class="flex flex-wrap">
                <div class="w-1/2">
                    <div class="border-b pb-7 mb-7 border-gray-40">
                        <ul class="pl-5 list-disc">
                            <li>Consultation/Counselling</li>
                            <li>Physical Exam</li>
                            <li>Eye/Hearing</li>
                            <li>Blood Exam (CBC, Blood Glucose /HbA1c, Lipid Profile, Liver)</li>
                            <li>Urinalysis</li>
                            <li>Fecalysis</li>
                            <li>Chest X-Ray</li>
                            <li>ECG</li>
                        </ul>
                    </div>
                    <p class="mb-1 font-bold">Doctor’s Notes:</p>
                    <!-- @TODO : update copywriting -->
                    <!-- <p class="leading-7">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p> -->
                </div>
                <div class="w-1/2 pl-12">
                    <div class="rounded-[10px] bg-white py-7 px-5 text-darkgray-900">
                        <div class="grid grid-cols-12 gap-2.5 border-b border-gray-40 pb-4 mb-4">
                            <div class="col-span-4">
                                <p class="font-bold">Branch</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>Shinagawa BGC</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Package</p>
                                <p class="pl-5 font-bold">Add-on</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>Japan Standard Health Checkup</p>
                                <p>N/A</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Date</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>January 31, 2023</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Time</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>10:00 AM - 10:15 AM</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Package Fee</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>P21,000</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Misc. Fees</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>P1,500</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2.5 text-primary-500">
                            <div class="col-span-6">
                                <p class="text-xl font-bold">Total</p>
                            </div>
                            <div class="col-span-6 text-right">
                                <p class="text-xl font-bold">P20,500</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="tabSelected != 'Information'" class="min-h-[calc(100vh-500px)]">
            <div class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
                <TestResultCard
                    :badge="false"
                />
                <TestResultCard
                    :badge="false"
                />
                <TestResultCard
                    :badge="false"
                />
                <TestResultCard
                    :badge="false"
                />
            </div>
        </div>

        <div class="mt-12">
            <a href="/test-results" class="flex items-center font-bold text-primary-500">
                <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                Go back
            </a>
        </div>
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import TestResultCard from './TestResultCard.vue';

import { ref } from 'vue';

const tabSelected = ref('Information');

</script>