<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">II. For Company Health Check-Up</p>
        
        <!-- <p class="font-bold text-darkgray-800">For Corporate Health Checkups</p>
        <p class="mb-4 text-xs font-bold text-darkgray-800">(企業健診の場合)</p> -->
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="company_name"
                    :disabled="disableInputs"
                    v-model="item.company_name"
                    type="text"
                    placeholder="Company Name / 会社名"
                />
                    <!-- :error="errors['personal_information.company_name'] ? errors['personal_information.company_name'][0] : ''" -->
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="company_no"
                    :disabled="disableInputs"
                    v-model="item.company_no"
                    type="text"
                    placeholder="Company No. / 会社番号 :"
                />
                    <!-- :error="errors['personal_information.company_no'] ? errors['personal_information.company_no'][0] : ''" -->
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="hmo_name"
                    :disabled="disableInputs"
                    v-model="item.hmo_name"
                    type="text"
                    placeholder="HMO Name / 保険会社名"
                />
                    <!-- :error="errors['personal_information.hmo_name'] ? errors['personal_information.hmo_name'][0] : ''" -->
            </div>
            <div class="col-span-full lg:col-span-6">
                <text-input
                    id="hmo_no"
                    :disabled="disableInputs"
                    v-model="item.hmo_no"
                    type="text"
                    placeholder="HMO No. / 保険番号 :"
                />
                    <!-- :error="errors['personal_information.hmo_no'] ? errors['personal_information.hmo_no'][0] : ''" -->
            </div>
        </div>

        <div class="mb-5 mt-8 border-b border-black/[.04]"></div>

        <p class="mb-4 font-bold text-darkgray-800">Job Category / 職種</p>
        <!-- <p class="mb-4 text-xs font-bold text-darkgray-800">職種</p> -->
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="rank_and_file"
                    v-model="item.rank_and_file"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.rank_and_file"
                >
                    <!-- :error="errors['personal_information.rank_and_file'] ? errors['personal_information.rank_and_file'][0] : ''" -->
                    <div class="ml-2">
                        <label for="rank_and_file" class="block leading-5 text-darkgray-400">
                            Rank and File / 一般社員
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="supervisory"
                    v-model="item.supervisory"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.supervisory"
                >
                    <!-- :error="errors['personal_information.supervisory'] ? errors['personal_information.supervisory'][0] : ''" -->
                    <div class="ml-2">
                        <label for="supervisory" class="block leading-5 text-darkgray-400">
                            Supervisory / 主任
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="managerial"
                    v-model="item.managerial"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.managerial"
                >
                    <!-- :error="errors['personal_information.managerial'] ? errors['personal_information.managerial'][0] : ''" -->
                    <div class="ml-2">
                        <label for="managerial" class="block leading-5 text-darkgray-400">
                            Managerial / 管理職
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
            <div class="col-span-full lg:col-span-6">
                <checkbox-input
                    id="top_management"
                    v-model="item.top_management"
                    :default-slot="false"
                    :rounded="false"
                    :is-checked="item.top_management"
                >
                    <!-- :error="errors['personal_information.top_management'] ? errors['personal_information.top_management'][0] : ''" -->
                    <div class="ml-2">
                        <label for="top_management" class="block leading-5 text-darkgray-400">
                            Top Management / 経営者
                        </label>
                    </div>
                    
                </checkbox-input>
            </div>
        </div>

        <div class="mb-5 mt-8 border-b border-black/[.04]"></div>

        <div class="grid grid-cols-1 mb-10 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full">
                <p class="mb-2 font-bold">Please check the following option for test results / 結果表の受け取り方について、以下のオプションをご確認ください。</p>
                <div class="grid grid-cols-1 space-y-2 lg:grid-cols-12">
                    <radio-group
                        :options="[
                            { id: 'pick_up', label: 'Pick-up/来院', value: 'pick_up'},
                            { id: 'same_as_above', label: 'Same as above/同上', value: 'same_as_above'},
                            { id: 'delivery', label: 'Delivery/配送', value: 'delivery'},
                            { id: 'others', label: 'Others/別の住所', value: 'others'},
                        ]"
                        name="test_result_option"
                        customClass="col-span-6"
                        v-model="item.test_result_option"
                    />
                    <!-- :disabled="disableInputs"
                    v-model="item.test_result_option -->
                    <div class="col-span-6"></div>
                    <div class="col-span-6 mt-3 pl-7">
                        <text-input
                            v-if="item.test_result_option == 'others'"
                            id="specify_others_option"
                            type="text"
                            placeholder="Type here"
                            v-model="item.specify_others_option"
                        />
                        <!-- :disabled="disableInputs"
                        v-model="item.specify_others_option -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import patient from "@/classes/UserData";
import { ref } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Array,
        required: true
    },
    disableInputs: {
        type: Boolean,
        default: false
    }
})

const gender = patient.getGender()
const item = ref(props.form)

</script>