<template>
    <portal-layout
    :defaultTitle="false">
        <template #title>
                <h1 class="text-4xl text-primary-700 font-bold">Account Details</h1> 
        </template>
        <div class="bg-white rounded-lg px-5 py-9">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-[45px]" v-if="item">
                <div class="space-y-3">
                    <div class="flex justify-between items-center">
                        <p>First Name</p>
                        <p class="font-bold">{{ item.First_Name || '--' }}</p>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Middle Name</p>
                        <p class="font-bold">{{ item.Middle_Name || '--' }}</p>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Last Name</p>
                        <p class="font-bold">{{ item.Last_Name || '--' }}</p>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Suffix</p>
                        <p class="font-bold">{{ item.Suffix || '--' }}</p>
                    </div>
                </div>
                <div class="space-y-3">
                    <div class="flex justify-between items-center">
                        <p>Email</p>
                        <p class="font-bold">{{ item.Email || '--' }}</p>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Mobile Number</p>
                        <div class="flex items-center space-x-2">
                            <PencilSquareIcon class="w-5 h-5 text-[#23A2B4] cursor-pointer" @click="router.push('/account-details/edit')"  /><p class="font-bold">+63{{ item.Mobile || '--' }}</p>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Birthday</p>
                        <p class="font-bold">{{ format(new Date(item.Date_of_Birth ), 'MMMM dd, Y') || '--' }}</p>
                    </div>
                    <div class="flex justify-between items-center">
                        <p>Sex</p>
                        <p class="font-bold">{{ item.Sex || '--' }}</p>
                    </div>
                </div>
            </div>
            
        </div>

        
        <PageLoader v-if="loader" />
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import PageLoader from "@/components/PageLoader.vue";
import { PencilSquareIcon } from '@heroicons/vue/24/outline';

import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import patient from "@/classes/UserData";
import { format } from 'date-fns'

const router = useRouter();

const loader = ref(false)

const patientData = computed(() => patient.getCurrentUser())
const item = ref(null)

const fetchData = () => {
    loader.value = true
    

    patient.getPatient(patientData.value.email_address)
        .then((result: any) => {
            loader.value = false
            item.value = result.data;
        })
        .catch((err) => {
            loader.value = false
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

onMounted(() => {
    fetchData();
})


</script>