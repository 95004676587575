<template>
    <div class="p-6 lg:p-8 bg-white rounded-lg mb-8">
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <text-input
                    :disabled="disableInputs"
                    id="patient_id"
                    v-model="item.patient_id"
                    type="text"
                    placeholder="Patient ID / 受診者様ID"
                />
            </div>
            <div class="col-span-full lg:col-span-6">
                <CalendarPicker 
                    :disabled="disableInputs"
                    :key="item.booking_date"
                    v-model="item.booking_date"
                    id="date-of-examination" 
                    :inline="false"
                    placeholder="Date of Examination(mm/dd/yyyy) / 健診日(月/日/年)"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import { ref } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        default: false
    }
})

const item = ref(props.form)

</script>