<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">IX. OB/GYN History (For female patients only) 婦人科問診（女性のみ）</p>
        <!-- <p class="mb-6 text-xs font-bold text-primary-500">産婦人科の病歴（女性のみ）</p> -->
        <p class="mb-6 font-bold">1. Menstrual history 月経（生理）について</p>


        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="1">
                <li>
                    <p class="text-darkgray-800">At what age did your first menstrual period start? 初めて生理があったのはいつですか</p>
                </li>
            </ol>
            <div class="flex space-x-[70px] mt-2.5">
                <div class="w-full lg:w-1/2">
                    <text-input
                        id="first_menstruation_age"
                        type="text"
                        placeholder="Age 年齢"
                        :disabled="disableInputs"
                        v-model="item.first_menstruation_age"
                        :error="errors['ob_gyn_history.first_menstruation_age'] ? errors['ob_gyn_history.first_menstruation_age'][0] : null"
                    />
                </div>
                <div class="hidden w-1/2 lg:block"></div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="2">
                <li>
                    <p class="text-darkgray-800">Have you reached menopause? 閉経はいつですか</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                    <radio-group
                        :options="[
                            { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                            { id: 'no', label: 'No/いいえ', value: 'No'},
                        ]"
                        name="is_menopause_reached"
                        :disabled="disableInputs"
                        v-model="item.is_menopause_reached"
                        :error="errors['ob_gyn_history.is_menopause_reached'] ? errors['ob_gyn_history.is_menopause_reached'][0] : null"
                    />
                </div>
                <div 
                    class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5"
                    v-if="item.is_menopause_reached == 'Yes'"
                >
                    <div class="pl-7">
                        <text-input
                            id="menopause_age"
                            type="text"
                            placeholder="If yes, what age? 年齢"
                            :disabled="disableInputs"
                            v-model="item.menopause_age"
                            :error="errors['ob_gyn_history.menopause_age'] ? errors['ob_gyn_history.menopause_age'][0] : null"
                        />
                    </div>
                </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="3">
                <li>
                    <p class="text-darkgray-800">Are your periods regular? 生理は順調ですか</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                        name="is_period_regulars"
                    :disabled="disableInputs"
                    v-model="item.is_period_regular"
                    :error="errors['ob_gyn_history.is_period_regular'] ? errors['ob_gyn_history.is_period_regular'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_period_regular']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_period_regular'][0] }}
            </p>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="4">
                <li>
                    <p class="text-darkgray-800">Interval 生理周期について</p>
                </li>
            </ol>
            <div class="flex space-x-[70px] mt-2.5">
                <div class="w-full lg:w-1/2">
                    <text-input
                        id="period_day_interval"
                        type="text"
                        placeholder="Days 日間"
                        :disabled="disableInputs"
                        v-model="item.period_day_interval"
                        :error="errors['ob_gyn_history.period_day_interval'] ? errors['ob_gyn_history.period_day_interval'][0] : null"
                    />
                </div>
                <div class="hidden w-1/2 lg:block"></div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="5">
                <li>
                    <p class="text-darkgray-800">How long does your period usually last? 生理は何日間ありますか</p>
                </li>
            </ol>
            <div class="flex space-x-[70px] mt-2.5">
                <div class="w-full lg:w-1/2">
                    <text-input
                        id="bleeding-duration"
                        type="text"
                        placeholder="How long does your period usually last? 生理は何日間ありますか"
                        v-model="item.bleeding_duration_days"
                        :error="errors['ob_gyn_history.bleeding_duration_days'] ? errors['ob_gyn_history.bleeding_duration_days'][0] : null"
                        :disabled="disableInputs"
                    />
                </div>
                <div class="hidden w-1/2 lg:block"></div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="6">
                <li>
                    <p class="text-darkgray-800">Describe your period flow 生理の量</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'Heavy', label: 'Heavy / 多い', value: 'Heavy'},
                        { id: 'Light', label: 'Light / 少ない', value: 'Light'},
                        { id: 'Regular', label: 'Regular / 普通', value: 'Regular'},
                    ]"
                    name="period_flow"
                    :disabled="disableInputs"
                    v-model="item.period_flow"
                />
                <p
                    v-if="errors['ob_gyn_history.period_flow']" 
                    class="mt-2 text-sm text-red-600"
                >
                    {{ errors['ob_gyn_history.period_flow'][0] }}
                </p>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="7">
                <li>
                    <p class="text-darkgray-800">Do you experience period pain? 生理痛は</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'no', label: 'No / いいえ', value: 'No'},
                        { id: 'always', label: 'Always / いつも', value: 'Always'},
                        { id: 'yes', label: 'Yes / はい', value: 'Yes'},
                        { id: 'sometimes', label: 'Sometimes / 時々', value: 'Sometimes'},
                    ]"
                    name="is_period_pain_experienced"
                    :disabled="disableInputs"
                    v-model="item.is_period_pain_experienced"
                />
                <p
                    v-if="errors['ob_gyn_history.is_period_pain_experienced']" 
                    class="mt-2 text-sm text-red-600"
                >
                    {{ errors['ob_gyn_history.is_period_pain_experienced'][0] }}
                </p>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="8">
                <li>
                    <p class="text-darkgray-800">Do you use painkillers for period pain? 生理痛で薬を飲みますか</p>
                </li>
            </ol>
         
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="are_painkillers_used"
                    :disabled="disableInputs"
                    v-model="item.are_painkillers_used"
                />
                <p
                    v-if="errors['ob_gyn_history.are_painkillers_used']" 
                    class="mt-2 text-sm text-red-600"
                >
                    {{ errors['ob_gyn_history.are_painkillers_used'][0] }}
                </p>
            </div>
            <div 
                class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5"
                v-if="item.are_painkillers_used == 'Yes'"
            >
                <div class="pl-7">
                    <text-input
                        id="medication_name"
                        type="text"
                        placeholder="Name of medication/薬品名"
                        :disabled="disableInputs"
                        v-model="item.medication_name"
                        :error="errors['ob_gyn_history.medication_name'] ? errors['ob_gyn_history.medication_name'][0] : null"
                    />
                </div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="9">
                <li>
                    <p class="text-darkgray-800">Date of your last period 最終月経はいつです</p>
                </li>
            </ol>
            <div class="flex lg:flex-row flex-col lg:space-x-[70px] lg:space-y-0 space-y-2.5 mt-2.5">
                <div class="w-full lg:w-1/2">
                    <CalendarPicker 
                        id="last_period_start_date"
                        :inline="false"
                        placeholder="Start Day/開始日"
                        :disabled="disableInputs"
                        v-model="item.last_period_start_date"
                        :error="errors['ob_gyn_history.last_period_start_date'] ? errors['ob_gyn_history.last_period_start_date'][0] : null"
                    />
                </div>
                <div class="w-full lg:w-1/2">
                    <CalendarPicker 
                        id="last_period_end_date"
                        :inline="false"
                        placeholder="End Day/終了日"
                        :disabled="disableInputs"
                        v-model="item.last_period_end_date"
                        :error="errors['ob_gyn_history.last_period_end_date'] ? errors['ob_gyn_history.last_period_end_date'][0] : null"
                    />
                </div>
            </div>
        </div>



        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">

            <p class="text-darkgray-800">2. Have you ever had sexual intercourse? 性交渉の経験はありますか</p>

            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes' },
                        { id: 'no', label: 'No/いいえ', value: 'No' },
                    ]"
                    name="is_period_regular"
                    :disabled="disableInputs"
                    v-model="item.had_sexual_intercourse"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_period_regular']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.had_sexual_intercourse'][0] }}
            </p>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">3. Have you ever had any STDs? 性感染症にかかったことはありますか</p>
            
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    :disabled="disableInputs"
                    name="had_sexual_intercourse"
                    v-model="item.is_stds"
                    :error="errors['ob_gyn_history.is_stds'] ? errors['ob_gyn_history.is_stds'][0] : null"
                />
                <p
                    v-if="errors['ob_gyn_history.is_stds']" 
                    class="mt-2 text-sm text-red-600"
                >
                    {{ errors['ob_gyn_history.is_stds'][0] }}
                </p>
            </div>
            <div 
                class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5"
                v-if="item.is_stds == 'Yes'"
            >
                <div class="pl-7">
                    <div class="pl-7 flex flex-col space-y-2.5">
                        <checkbox-input 
                            v-for="i in std"
                            :key="i"
                            :id="i.label"
                            :label="i.label"
                            :rounded="false"
                            labelClass="!text-base"
                            text-color="text-darkgray-800"
                            :isChecked="inStdType(i.value)"
                            :disabled="disableInputs"
                            @click="addRemoveStdType(i.value)"
                        />

                        <text-input
                            v-if="inStdType('other')"
                            id="stds_type"
                            type="text"
                            placeholder="Type here"
                            v-model="item.stds_type[getOtherValue()]"
                            :error="errors['ob_gyn_history.stds_type'] ? errors['ob_gyn_history.stds_type'][0] : null"
                            :disabled="disableInputs"
                        />
                    </div>
                </div>
            </div>
            <div class="flex space-x-[70px] mt-2.5">
                <div class="pl-8 w-1/2 flex flex-col space-y-2.5">
                    
                </div>
                <div class="hidden w-1/2 lg:block"></div>
            </div>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">4. Are you administering hormone? ホルモン剤を使用していますか</p>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="is_administering_hormone"
                    :disabled="disableInputs"
                    v-model="item.is_administering_hormone"
                    :error="errors['ob_gyn_history.is_administering_hormone'] ? errors['ob_gyn_history.is_administering_hormone'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_administering_hormone']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_administering_hormone'][0] }}
            </p>
            <div 
                v-if="item.is_administering_hormone == 'Yes'"
                class="flex space-x-[70px] mt-2.5"
            >
                <div class="w-full pl-8 lg:w-1/2">
                    <text-input
                        id="specify_hormone"
                        type="text"
                        placeholder="Please specify/薬品名"
                        :disabled="disableInputs"
                        v-model="item.specify_hormone"
                        :error="errors['ob_gyn_history.specify_hormone'] ? errors['ob_gyn_history.specify_hormone'][0] : null"
                    />
                </div>
                <div class="hidden w-1/2 lg:block"></div>
            </div>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <p class="mb-6 font-bold">5. Pregnancy history 妊娠歴について</p>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="1">
                <li>
                    <p class="text-darkgray-800">Are you pregnant or possibly pregnant? 妊娠していますか、またその可能性はありますか</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                        { id: '3', label: 'Do not know / わからない', value: 'Do not know'},
                    ]"
                    name="is_pregnant"
                    :disabled="disableInputs"
                    v-model="item.is_pregnant"
                    :error="errors['ob_gyn_history.is_pregnant'] ? errors['ob_gyn_history.is_pregnant'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_pregnant']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_pregnant'][0] }}
            </p>
            <div 
                class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5"
                v-if="item.is_pregnant == 'Yes'"
            >
                <div class="pl-7">
                    <text-input
                        id="weeks_pregnant"
                        type="text"
                        placeholder="Weeks/週"
                        :disabled="disableInputs"
                        v-model="item.weeks_pregnant"
                        :error="errors['ob_gyn_history.weeks_pregnant'] ? errors['ob_gyn_history.weeks_pregnant'][0] : null"
                    />
                </div>
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="2">
                <li>
                    <p class="text-darkgray-800">Have you ever been pregnant? これまでに妊娠したことがありますか</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="been_pregnant"
                    :disabled="disableInputs"
                    v-model="item.been_pregnant"
                    :error="errors['ob_gyn_history.been_pregnant'] ? errors['ob_gyn_history.been_pregnant'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.been_pregnant']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.been_pregnant'][0] }}
            </p>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="3">
                <li>
                    <p class="text-darkgray-800">Have you ever had a miscarriage or abortion? 自然流産や人工流産の経験はありますか</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: '1', label: 'Yes/はい', value: 'Yes'},
                        { id: '2', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="has_miscarriage_abortion"
                    :disabled="disableInputs"
                    v-model="item.has_miscarriage_abortion"
                    :error="errors['ob_gyn_history.has_miscarriage_abortion'] ? errors['ob_gyn_history.has_miscarriage_abortion'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.has_miscarriage_abortion']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.has_miscarriage_abortion'][0] }}
            </p>
            <div 
                class="space-y-2 PRAXXYSlive2023 mt-2.5"
                v-if="item.has_miscarriage_abortion == 'Yes'"
            >
                <div 
                    class="pl-7 flex flex-col space-y-2.5"
                  
                >
                    <label class="block ml-2 text-primary-600">
                        Miscarriage/自然流産
                    </label>
                    <div class="space-y-2 pl-7">
                        <radio-group
                            :options="[
                                { id: '1', label: 'Once / １回', value: 'Once'},
                                { id: '2', label: 'Twice / 2 回', value: 'Twice'},
                                { id: '3', label: '3 times or more / ３回以上', value: 'More'},
                            ]"
                            name="miscarriage_count"
                            :disabled="disableInputs"
                            v-model="item.miscarriage_count"
                            :error="errors['ob_gyn_history.miscarriage_count'] ? errors['ob_gyn_history.miscarriage_count'][0] : null"
                        />
                    </div>

                    <label class="block ml-2 text-primary-600">
                        Abortion/人工中絶
                    </label>
                    <div class="space-y-2 pl-7">
                        <radio-group
                            :options="[
                                { id: '1', label: 'Once / １回', value: 'Once'},
                                { id: '2', label: 'Twice / 2 回', value: 'Twice'},
                                { id: '3', label: '3 times or more / ３回以上', value: 'More'},
                            ]"
                            name="abortion_count"
                            :disabled="disableInputs"
                            v-model="item.abortion_count"
                            :error="errors['ob_gyn_history.abortion_count'] ? errors['ob_gyn_history.abortion_count'][0] : null"
                        />
                    </div>
                </div>

                
            </div>
        </div>

        <div class="pl-5 mb-6">
            <ol class="list-[lower-alpha]" start="4">
                <li>
                    <p class="text-darkgray-800">Do you have any children? If yes, please give details お子様がいらっしゃる方はご記入ください</p>
                </li>
            </ol>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="has_children"
                    v-model="item.has_child"
                    @update:modelValue="hasChild"
                    :disabled="disableInputs"
                />
            </div>
        </div>

        <div 
            class="mb-6"
            v-if="item.has_child == 'Yes'"
        >
            <div 
                v-for="(children, index) in item.children_delivery" :key="index"
                class="grid grid-cols-12 gap-x-8 gap-y-2.5 bg-primary-50 rounded-2xl p-5 lg:p-7 mb-2" 
            >
                <div class="col-span-8 lg:col-span-6">
                    <p class="font-bold text-darkgray-800">
                        {{ children.child_order}} {{getOrdinal(children.child_order) }} child/第{{children.child_order}}子
                    </p>
                </div>
                <div class="col-span-4 text-right lg:col-span-6">
                    <small 
                        v-if="index != 0 && !disableInputs"
                        class="text-sm text-red-500 cursor-pointer"
                        @click="removeChild(index)"
                    >
                        Remove
                    </small>
                </div>
                <div class="col-span-full lg:col-span-6">
                    <text-input
                        id="age_deliver"
                        type="text"
                        placeholder="Age/年齢"
                        :disabled="disableInputs"
                        v-model="children.age_deliver"
                    />
                </div>
                <div class="lg:col-span-6 col-span-full">
                    <p class="mb-2 font-bold text-darkgray-800">Delivery method/分娩方法</p>
                    <div class="space-y-2">
                        <radio-group
                            :options="[
                                { id: '1', label: 'Vaginal delivery/経腟分娩', value: 'is_vaginal_delivery'},
                                { id: '2', label: 'C-section/帝王切開', value: 'is_csection_delivery'},
                            ]"
                            :name="'delivery'+index"
                            :disabled="disableInputs"
                            v-model="children.is_vaginal_delivery"
                            />
                            <!-- @update:modelValue="(value) => (children.is_vaginal_delivery = value)" -->
                    </div>
                </div>
            </div>
            <p 
                v-if="!disableInputs"
                class="text-sm font-bold transition cursor-pointer text-primary-500 hover:text-primary-700 w-fit"
                @click="addChild()"
                :disabled="disableInputs"
            >
                Add another +
            </p>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">6. Are you breastfeeding? 授乳中ですか</p>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="is_breastfeeding"
                    :disabled="disableInputs"
                    v-model="item.is_breastfeeding"
                    :error="errors['ob_gyn_history.is_breastfeeding'] ? errors['ob_gyn_history.is_breastfeeding'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_breastfeeding']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_breastfeeding'][0] }}
            </p>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">7. Did you undergo breast augmentation surgery and breast reconstruction? 豊胸手術、乳房再建術をしていますか</p>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                            { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="is_breast_surgery"
                    :disabled="disableInputs"
                    v-model="item.is_breast_surgery"
                    :error="errors['ob_gyn_history.is_breast_surgery'] ? errors['ob_gyn_history.is_breast_surgery'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_breast_surgery']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_breast_surgery'][0] }}
            </p>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">8. Have you ever been tested for breasts? 乳房の検査を受けたことはありますか</p>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'no', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="is_breast_tested"
                    :disabled="disableInputs"
                    v-model="item.is_breast_tested"
                    :error="errors['ob_gyn_history.is_breast_tested'] ? errors['ob_gyn_history.is_breast_tested'][0] : null"
                />
            </div>
            <p
                v-if="errors['ob_gyn_history.is_breast_tested']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.is_breast_tested'][0] }}
            </p>
            <div v-if="item.is_breast_tested == 'Yes'" class=" space-y-2 gap-x-[70px] mt-2.5">

                <div class="pl-7 flex flex-col space-y-2.5 w-1/4">
                    <checkbox-input
                        id="Mammography/マンモグラフィ"
                        label="Mammography/マンモグラフィ"
                        :rounded="false"
                        labelClass="!text-base"
                        text-color="text-darkgray-800"
                        :disabled="disableInputs"
                        :isChecked="item.breast_mammography"
                        @update:modelValue="(value) => setDate(value, item,'breast_mammography')"
                    />

                    <div class="space-y-2 pl-7">
                        <CalendarPicker 
                            v-if="item.breast_mammography"
                            id="breast_mammography"
                            :inline="false"
                            placeholder="Date of last 最後に受けた時期"
                            :disabled="disableInputs"
                            v-model="item.breast_mammography"
                            :error="errors['ob_gyn_history.breast_mammography'] ? errors['ob_gyn_history.breast_mammography'][0] : null"
                        />
                    </div>
                    <checkbox-input
                        id="Breast Ultrasound/乳腺超音波"
                        label="Breast Ultrasound/乳腺超音波"
                        :rounded="false"
                        labelClass="!text-base"
                        text-color="text-darkgray-800"
                        :disabled="disableInputs"
                        :isChecked="item.breast_ultrasound"
                        @update:modelValue="(value) => setDate(value, item, 'breast_ultrasound')"
                    />
                    <div class="space-y-2 pl-7">
                        <CalendarPicker 
                            v-if="item.breast_ultrasound"
                            id="breast_ultrasound"
                            :inline="false"
                            placeholder="Date of last 最後に受けた時期"
                            :disabled="disableInputs"
                            v-model="item.breast_ultrasound"
                            :error="errors['ob_gyn_history.breast_ultrasound'] ? errors['ob_gyn_history.breast_ultrasound'][0] : null"
                        />
                    </div>
                    <checkbox-input
                        id="Visual Palpation/視触診"
                        label="Visual Palpation/視触診"
                        :rounded="false"
                        labelClass="!text-base"
                        text-color="text-darkgray-800"
                        :disabled="disableInputs"
                        v-model="item.breast_visual_palpation"
                        :error="errors['ob_gyn_history.breast_visual_palpation'] ? errors['ob_gyn_history.breast_visual_palpation'][0] : null"
                        :isChecked="item.breast_visual_palpation"
                        @update:modelValue="(value) => setDate(value, item, 'breast_visual_palpation')"
                    />
                    <div class="space-y-2 pl-7">
                        <CalendarPicker 
                            v-if="item.breast_visual_palpation"
                            id="breast_ultrasound"
                            :inline="false"
                            placeholder="Date of last 最後に受けた時期"
                            :disabled="disableInputs"
                            v-model="item.breast_visual_palpation"
                            :error="errors['ob_gyn_history.breast_visual_palpation'] ? errors['ob_gyn_history.breast_visual_palpation'][0] : null"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="my-8 border-b border-black/[.04]"></div>

        <div class="mb-6">
            <p class="text-darkgray-800">9. Have you ever had a pap smear? 子宮がん検診を受けたことがありますか</p>
            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <radio-group
                    :options="[
                        { id: 'Yes', label: 'Yes/はい', value: 'Yes'},
                        { id: 'No', label: 'No/いいえ', value: 'No'},
                    ]"
                    name="had_pap_smear"
                    :disabled="disableInputs"
                    v-model="item.had_pap_smear"
                    :error="errors['ob_gyn_history.had_pap_smear'] ? errors['ob_gyn_history.had_pap_smear'][0] : null"
                />
            </div>

            <p
                v-if="errors['ob_gyn_history.had_pap_smear']" 
                class="mt-2 text-sm text-red-600"
            >
                {{ errors['ob_gyn_history.had_pap_smear'][0] }}
            </p>

            <div class="lg:columns-2 space-y-2 gap-x-[70px] mt-2.5">
                <div 
                    v-if="item.had_pap_smear == 'Yes'"
                    class="pl-7 flex flex-col space-y-2.5"
                >
                    <div class="mb-3">
                        <CalendarPicker 
                            id="last_pap_smear_date"
                            :inline="false"
                            placeholder="Date of last pap smear 最後に受けた時期"
                            :disabled="disableInputs"
                            v-model="item.last_pap_smear_date"
                            :error="errors['ob_gyn_history.last_pap_smear_date'] ? errors['ob_gyn_history.last_pap_smear_date'][0] : null"
                        />
                    </div>
                    <p class="font-bold text-darkgray-800">Result 検査結果</p>

                    <radio-group
                        :options="[
                            { id: '1', label: 'Normal/異常なし', value: 'normal'},
                            { id: '2', label: 'Abnormal/異常あり', value: 'abnormal'},
                        ]"
                        name="delivery"
                        :disabled="disableInputs"
                        @update:modelValue="(value) => (item.pap_smear_result = value)"
                        v-model="item.pap_smear_result"
                        :error="errors['ob_gyn_history.pap_smear_result'] ? errors['ob_gyn_history.pap_smear_result'][0] : null"
                    />

                    <!-- <checkbox-input
                        id="Normal/異常なし"
                        label="Normal/異常なし"
                        :rounded="false"
                        labelClass="!text-base"
                        text-color="text-darkgray-800"
                        :disabled="disableInputs"
                        v-model="item.pap_smear_result"
                        :error="errors['ob_gyn_history.pap_smear_result'] ? errors['ob_gyn_history.pap_smear_result'][0] : null"
                    />
                    <checkbox-input
                        id="Abnormal/異常あり"
                        label="Abnormal/異常あり"
                        :rounded="false"
                        labelClass="!text-base"
                        text-color="text-darkgray-800"
                        :disabled="disableInputs"
                        v-model="item.pap_smear_result_details"
                        :error="errors['ob_gyn_history.pap_smear_result_details'] ? errors['ob_gyn_history.pap_smear_result_details'][0] : null"
                    /> -->
                </div>

                <div class="hidden lg:block spacer h-36"></div>
            </div>
        </div>


        <!-- <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-6">
            <div class="col-span-full lg:col-span-6">
                <p class="font-bold">Are you currently menstruating?</p>
                <p class="mb-3 text-xs">現在生理中ですか？ （チェックしてください）</p>
                <div class="space-y-2">
                    <radio-group
                        :options="menstruationOptions"
                        name="options"
                        v-model="item.is_currently_menstruating"
                        :error="errors['ob_gyn_history.is_currently_menstruating'] ? errors['ob_gyn_history.is_currently_menstruating'][0] : null"
                        :isDisabled="disableInputs"
                    />
                </div>
                <div class="mt-3 lg:pl-7">
                    <CalendarPicker 
                        id="last-period"
                        :inline="false"
                        placeholder="If no, when was your last period? "
                        v-model="item.last_menstruation_date"
                        :error="errors['ob_gyn_history.last_menstruation_date'] ? errors['ob_gyn_history.last_menstruation_date'][0] : null"
                        :disabled="item.is_currently_menstruating == 'Yes' || disableInputs"
                        :key="item.last_menstruation_date"
                        helper="いいえの場合、最後の生理はいつでしたか:(月/日/年)"
                    />
                </div>
            </div>
        </div> -->
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import RadioGroup from '@/components/inputs/RadioGroup.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import MedicalService from '@/classes/MedicalService';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';

import { 
    IChildrenDelivery
} from '@/interfaces/PatientInterface'

import { onMounted, ref, watch } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    errors: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: true
    },
})

const item = ref(props.form)
const menstruationOptions = MedicalService.getDefaultOptions('menstruation')
const menopauseOptions = MedicalService.getDefaultOptions('menopause')

const std = [
    { 
        label: 'Chlamydia/クラミジア',
        value: 'chlamydia' 
    },
    { 
        label: 'Gonorrhoea/淋病',
        value: 'gonorrhoea' 
    },
    { 
        label: 'Trichomonas/トリコモナス',
        value: 'trichomonas' 
    },
    { 
        label: 'HIV/エイズ',
        value: 'hiv' 
    },
    { 
        label: 'Other/その他',
        value: 'other' 
    },
]

const inStdType = (val : string): boolean => {
    return item.value.stds_type.findIndex((type:any) => type == val) != -1
}

const addRemoveStdType = (val : string):void => {
    const index = item.value.stds_type.findIndex((member:any) => member == val)

    if(index != -1){
        item.value.stds_type.splice(index, 1);
    } else {
        item.value.stds_type.push(val);
    }
}

const getOtherValue = ():number => {

    const otherValue = item.value.stds_type.filter((val:any) => !std.map((type:any) => type.value).includes(val));
    const index = item.value.stds_type.indexOf(otherValue[0])

    if(index != -1){
        return index;
    } 
    
    if(inStdType('other')){
        return item.value.stds_type.length;
    } 
    
    return -1 ;
}

const hasChild = (value:any):void => {
    if(value == "Yes"){
        addChild()
    } else {
        item.value.children_delivery = [];
    }
}

const addChild = ():void => {
    item.value.children_delivery.push({
        child_order : item.value.children_delivery.length + 1,
        age_deliver : null,
        is_vaginal_delivery : 'No',
        is_csection_delivery : 'No'
    })
}

const removeChild = (index:number):void => {
    item.value.children_delivery.splice(index, 1)
}

const setDate = (value:boolean, param:any, test:string):void => {
    if(value){
        param[test] = new Date();
    } else {
        param[test] = null;
    }
}
const getOrdinal = (n:number):string => {
  let ord = 'th';

  if (n % 10 == 1 && n % 100 != 11)
  {
    ord = 'st';
  }
  else if (n % 10 == 2 && n % 100 != 12)
  {
    ord = 'nd';
  }
  else if (n % 10 == 3 && n % 100 != 13)
  {
    ord = 'rd';
  }

  return ord;
}

watch(() => item.value.is_currently_menstruating,
    (val) => {
        if(val === 'Yes') item.value.last_menstruation_date = null
    },
    { immediate: true }
);

onMounted(() => {
    setTimeout(() => {
        if(!item.value.is_currently_menstruating || item.value.is_currently_menstruating === 'Yes') {
            item.value.last_menstruation_date = null
        }
    }, 2000)
})

</script>