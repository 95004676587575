<template>
    <user-layout>
        <div class="pb-32 pt-16 w-[90%] mx-auto">
            <h1 class="font-bold text-center mb-5 text-primary-500 text-4xl lg:text-5xl">
                Forgot Password
            </h1>
            <p class="text-center mb-7">Kindly provide your email for us to send you a reset password link.</p>
            <form class="w-full lg:w-[350px] mx-auto" @submit.prevent="login">
                <div class="mb-8">
                    <text-input
                        type="email"
                        placeholder="Email"
                        v-model="form.email"
                        :error="errors.email ? errors.email[0] : ''"
                    />
                </div>
                <div class="text-center">
                    <button-component
                        :disabled="loader"
                        text="Submit"
                        :type="'submit'"
                        @click="submit()"
                    />
                </div>
            </form>

            <div class="text-center mt-8">
                <p class="text-darkgray-400">Dont have an account yet? <a href="/signup" class="font-bold text-primary-500">Sign up now</a></p>
            </div>
        </div>
        <PageLoader v-if="loader" />

        <GeneralModal
            width="w-[416px]"
            title="Password reset link sent!"
            content="Kindly check your email to access the password reset link we sent you."
            action-text="Okay"
            :show="show"
            @close="show = false"
            @confirm="show = false"
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import GeneralModal from '@/components/GeneralModal.vue';
import { useRouter } from 'vue-router';
import AuthService from '@/classes/AuthService';
import { ref } from 'vue';

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref()
const errors = ref([])
const show = ref(false);

const router = useRouter();

const form = ref({
    email: '',
})

const submit = async() => {
    try {
        loader.value = true;

        const response = await AuthService.forgotPassword(form.value.email)
        
        loader.value      = false;
        show.value = true
        form.value.email  = '';
        errorMsg.value    = '';
    }catch(error){
        loader.value = false;
        showError.value = true;
        errorMsg.value = error.response.data.message
        errors.value = error.response.data.errors
    }
}



</script>