<template>
    <div v-if="appointment">
        <portal-layout
        :title="appointment.package.name"
        :hide-notification="true">
            <div class="flex flex-wrap text-darkgray-900 pb-7 mb-7 border-b border-gray-40 space-y-1 lg:space-y-0">
                <div class="w-full lg:w-1/2">
                    <div class="grid grid-cols-2 gap-1">
                        <div class="col-span-1">
                            <p>Date Booked</p>
                        </div>
                        <div class="col-span-1 text-right">
                            <p class="font-bold">{{ format(new Date(appointment.booking_date), 'LLLL dd, yyyy') }}</p>
                        </div>
                        <div class="col-span-1">
                            <p>Time Booked</p>
                        </div>
                        <div class="col-span-1 text-right">
                            <p class="font-bold">{{ startAndEndTime }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="w-full lg:w-1/2">
                    <div class="lg:pb-7 mb-7 lg:border-b lg:border-gray-40">
                        <ul class="list-disc pl-5">
                            <div v-if="appointment.package.items.length > 0">
                                <div v-for="(item, index) in appointment.package.items" :key="index">
                                    <li>{{ item.item_name }}</li>
                                </div>
                            </div>
                            <div v-else>
                                <li>N/A</li>
                            </div>
                        </ul>
                    </div>
                    <div class="mb-2">
                        <DatePicker
                        v-if="selectedDate"
                        v-model="selectedDate"
                        label="Booking Date"
                        placeholder="MM/DD/YYYY"
                        :min-date="minDate"
                        :fully-booked-dates="fullyBookedDates"
                        hide-offset-dates
                        highlight-disabled-days
                        @updateMonth="updateMonth"
                        />
                    </div>

                    <div class="lg:mb-0 mb-7">
                        <MultiselectInput
                        v-model="selectedTime"
                        label="Booking Time"
                        placeholder="Booking Time"
                        :options="timeSlots"
                        />
                    </div>
                    <!-- <p class="font-bold mb-1">Doctor’s Notes:</p> -->
                    <!-- <p class="leading-7">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident.</p> -->
                    
                    <!-- Todo: Troubleshoot back to appointments/test results -->
                    <div class="mt-12 hidden lg:flex">
                        <a @click.prevent="backToAppointment"  class="text-primary-500 font-bold flex items-center cursor-pointer">
                            <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                            Go back
                        </a>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:pl-12">
                    <div class="rounded-[10px] bg-white py-7 px-5 text-darkgray-900">
                        <div class="grid grid-cols-12 gap-2.5 border-b border-gray-40 pb-4 mb-4">
                            <div class="col-span-4">
                                <p class="font-bold">Branch</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ appointment.branch.name }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Package</p>
                                <p class="pl-5 font-bold">Add-on</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ appointment.package.name }}</p>
                                <div v-if="appointment.other_charges_line.length > 0">
                                    <div v-for="rider in appointment.other_charges_line" :key="rider.item_id">
                                        <p>{{ rider.item_name }}</p>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>N/A</p>
                                </div>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Date</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ format(new Date(appointment.booking_date), 'LLLL dd, yyyy')}}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Time</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ startAndEndTime }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Package Fee</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ formatter.format(appointment.package_fee) }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Misc. Fees</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ formatter.format(appointment.other_charges_fee) }}</p>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2.5 text-primary-500">
                            <div class="col-span-6">
                                <p class="font-bold text-xl">Total</p>
                            </div>
                            <div class="col-span-6 text-right">
                                <p class="font-bold text-xl"> {{ formatter.format(appointment.total_fee) }}</p>
                            </div>
                        </div>
                        <div class="mt-6">
                            <ButtonComponent
                            :disabled="loader"
                            @click.prevent="updateBooking"
                            class="w-full"
                            text="Update Soft Booking"
                            />
                        </div>
                        <div class="mt-2">
                            <ButtonComponent
                            :disabled="loader"
                            @click.prevent="submitBooking"
                            class="w-full"
                            text="Submit Soft Booking"
                            />
                        </div>
                    </div>

                    <div class="mt-12 flex lg:hidden">
                        <a @click.prevent="backToAppointment"  class="text-primary-500 font-bold flex items-center cursor-pointer">
                            <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                            Go back
                        </a>
                    </div>
                </div>
            </div>

            <GeneralModal
            width="w-[548px]"
            check-icon
            title="Booking Confirmed!"
            action-text="View Appointment"
            cancel-text="Book another"
            :show="show"
            @close="bookAnother"
            @confirm="viewBoking" 
            >
                <template #content>
                    <p>Your booking appointment has been received.</p>
                    <br>
                    <p>A medical questionnaire has been provided in your appointment details, which you may access by clicking the button below. Please ensure that this form has been filled before your appointment.</p>
                    <br>
                    <p>Thank you!</p>
                </template>
            </GeneralModal>

            <PageLoader v-if="loader" />

            <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            >
                <template #content>
                    {{ errorMsg }}
                </template>
            </ErrorModal>

            <SuccessModal
            :show="showSuccess"
            @cancel="showSuccess = false"
            @confirm="showSuccess = false"
            action-text="Confirm"
            title="Booking Submitted!"
            content="Booking has been updated successfully"
            />

            <ConfirmationModal
            :show="showConfirmation"
            @cancel="showConfirmation = false"
            @confirm="showConfirmation = false; updateSoftBooking() "
            cancel-text="Cancel"
            action-text="Confirm"
            title="Submit Booking?"
            content="Are you sure you wish to update this booking?"
            />
            
        </portal-layout>
    </div>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import SuccessModal from '@/components/modal/SuccessModal.vue';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';
import GeneralModal from '@/components/GeneralModal.vue';
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { useRoute } from 'vue-router';

import { ref, computed, onMounted, watch } from 'vue';
import patient from "@/classes/UserData";
import { format, parse } from 'date-fns';
import { formatter } from '@/helpers/NumberHelper';

import booking from "@/classes/Booking";
import ButtonComponent from '@/components/ButtonComponent.vue';
import DatePicker from '@/components/inputs/DatePicker.vue';
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';

const route = useRoute();

const patientData = computed(() => patient.getCurrentUser())

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const showSuccess = ref(false)
const showConfirmation = ref(false)
const appointment = ref()
const bookingId = ref(route.params.id as unknown as string);

const startAndEndTime = computed(() => {
    if(appointment.value.booking_start_time && appointment.value.booking_end_time) {
        return format(parse(appointment.value.booking_start_time, 'hh:mm:ss', new Date()), 'h:mm a') + ' - ' + format(parse(appointment.value.booking_end_time, 'hh:mm:ss', new Date()), 'h:mm a')
    } else {
        return "--"
    }
});

const show = ref(false);
const hybrainBookingId = ref(null);

const selectedTime = ref(null)
const fullyBookedDates = ref([]);
const unformattedTimeSlots = ref([]);
const timeSlots = ref([]);

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth();
const timezoneOffset = 8; // Philippines time zone is UTC+8
const threeDaysLater = new Date(currentDate.getTime() + 3 * 24 * 60 * 60 * 1000 + timezoneOffset * 60 * 60 * 1000); // For 3 days advance booking

const minDate = computed(() => threeDaysLater.toISOString().slice(0, 10));
const from = ref(null);
const to = ref(null);

const selectedDate = ref(minDate.value)

const payload = ref({
    soft_booking_id: null,
    is_patient_portal: true,
    patient_id: patientData.value.patient_id,
    branch_id: null,
    package_id: null,
    package_fee: null,
    other_charges_line: null,
    other_charges_fee: null,
    booking_date: null,
    booking_start_time: null,
    booking_end_time: null,
    // For Validation
    package_name: null,
    branch_name: null,
    branch_address: null,
    riders: [],
});

const soft_payload = ref({
    id: null,
    branch_id: null,
    booking_date: null,
    booking_start_time: null,
    booking_end_time: null,
})

const fetchSelectedAppointment = () => {
    loader.value = true
    
    patient.fetchSoftBooking(bookingId.value)
        .then((result: any) => {
            loader.value = false;
            appointment.value = result.data;

            initPayload();
            fetchDateAndTime();
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const initPayload = () => {
    payload.value.soft_booking_id = appointment.value.id;
    payload.value.branch_id = appointment.value.branch.id;
    payload.value.package_id = appointment.value.package_id;
    payload.value.package_fee = appointment.value.package_fee;
    payload.value.other_charges_line = appointment.value.other_charges_line;
    payload.value.other_charges_fee = appointment.value.other_charges_fee;

    // For Validations
    payload.value.branch_name = appointment.value.branch.name;
    payload.value.branch_address = appointment.value.branch.address_line;
    payload.value.package_name = appointment.value.package.name;
    payload.value.riders = appointment.value.riders;

    if(appointment.value.booking_date) {
        // payload.value.booking_date = selectedDate.value;
        payload.value.booking_date = appointment.value.booking_date;
    }

    if(appointment.value.booking_start_time && appointment.value.booking_start_time) {
        // selectedTime.value = `${formatTime(appointment.value.booking_start_time)}-${formatTime(appointment.value.booking_end_time)}`
        payload.value.booking_start_time = appointment.value.booking_start_time;
        payload.value.booking_end_time = appointment.value.booking_end_time;
    }

    // Soft Booking
    soft_payload.value.id = appointment.value.id;
    soft_payload.value.branch_id = appointment.value.branch.id;
}

const formatTime = (time) => {
  const [hours, minutes] = time.split(":");
  const suffix = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // handle midnight (00:00:00) as 12 AM
  return `${formattedHours}:${minutes} ${suffix}`;
};

const backToAppointment = () => {
    window.location.replace('appointment')
}

const formatTimeSlots = () => {
    timeSlots.value = [];
    for(const unformattedTimeSlot in unformattedTimeSlots.value) {
        unformattedTimeSlots.value[unformattedTimeSlot].forEach(slot => {
            if(slot.available_slots != 0) {
                timeSlots.value.push(slot);
            }
        });

    }
}

const fetchDateAndTime = () => {
    loader.value = true;
    
    const payload = {
        branch_id: soft_payload.value.branch_id,
        selected_date: new Date(selectedDate.value).toISOString().split('T')[0],
        from: from.value,
        to: to.value,
    }
    booking.getTimeSlots(payload)
        .then((result: any) => {
            loader.value = false
            fullyBookedDates.value = result.data.response.fully_booked_dates;         
            unformattedTimeSlots.value = result.data.response.time_slots; 
            
            if(selectedDate.value) {
                formatTimeSlots();
            }
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;
            
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const updateMonth = (val = null) => {
    // Get First and Last Day for Active Month
    const monthYear = {
        year: currentYear,
        month: currentMonth,
    }

    from.value = firstDayOfMonth(val ? val : monthYear)
    to.value = lastDayOfMonth(val ? val : monthYear);

    // Fetch fetchDateAndTime for Active Month
   // Fetch fetchDateAndTime for Active Month
    if(soft_payload.value.branch_id) {
        fetchDateAndTime();
    }
}

const firstDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-01`;

    return formattedDate;
}

const lastDayOfMonth = (val) => {
    const year = val.year;
    const month = val.month + 1;
    const lastDay = new Date(year, month, 0).getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    return formattedDate;    
}

const selectTimeSlot = (val) => {
    if(val) {
        soft_payload.value.booking_start_time = val.split('-')[0];
        soft_payload.value.booking_end_time = val.split('-')[1];
    }
}

watch(
    () => selectedTime.value,
    (val) => {
        if(val)
        selectTimeSlot(val);
    },
    { immediate: true }
);

watch(
    () => selectedDate.value,
    (val) => {
        if(val)
            // selectedTime.value = null;
            soft_payload.value.booking_date = new Date(selectedDate.value).toISOString().split('T')[0];
            updateMonth();
    },
    { immediate: true }
);

const submitBooking = () => {
    loader.value = true;
    
    booking.submitBooking(payload.value)
    .then((result: any) => {
            loader.value = false;
            show.value = true;

            hybrainBookingId.value = result.data.response.response.booking_id;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const bookAnother = () => {
    window.location.href = '/booking'
}
const viewBoking = () => {
    show.value = false
    window.location.replace(`/appointment/show/${hybrainBookingId.value}`);
}

const updateBooking = () => {
    loader.value = true;


    booking.updateSoftBooking(soft_payload.value)
        .then((result: any) => {
            loader.value = false;
            if(result.data.response.result_status == 200 && result.data.response.message == 'Success') {
                showSuccess.value = true;

                fetchSelectedAppointment();
            }
        })
        .catch((error: any) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = error.response.data.message;

            if(error.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

onMounted(() => { fetchSelectedAppointment() })

</script>
