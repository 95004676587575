<template>
    <SelectOptions
        name="gender"
        id="gender"
        :options="gender"
    />
</template>

<script setup lang="ts">
import SelectOptions from "@/components/inputs/SelectOptions.vue";
defineProps({
});

const gender = [
    { id: '1', label: 'Male', value: 'Male'},
    { id: '2', label: 'Female', value: 'Female'},
    // { id: '3', label: 'Prefer not to say', value: 'Prefer not to say'}
]
</script>
