<template>
    <user-layout>

        <div v-if="maintenanceMode" class="py-14 w-[90%] mx-auto">
            <h1 class="mb-6 text-4xl font-bold text-center text-primary-500 lg:text-5xl">
               Welcome to <br> Shinagawa Patient Portal
            </h1>

            <h3 class="mb-6 text-2xl font-bold text-center text-black-500 lg:text-2xl">
                We are currently conducting maintenance to our system. <br>
                Sorry for the inconvenience.<br>
                We will be back soon. <br>
                For any inquiries regarding your booking, please contact us using the information found below.
            </h3>
        </div>
        <div v-else class="py-14 w-[90%] mx-auto">
            <h1 class="mb-6 text-4xl font-bold text-center text-primary-500 lg:text-5xl">
                Welcome to <br> Shinagawa Patient Portal
            </h1>
            <form class="w-full md:w-[450px] mx-auto" @submit.prevent="login">
                <div class="mb-5">
                    <text-input
                        type="email"
                        placeholder="Email"
                        v-model="form.email"
                        :error="errors.email ? errors.email[0] : ''"
                    />
                </div>
                <div class="mb-1">
                    <password-input
                        id="password"
                        v-model="form.password"
                        placeholder="Password"
                    />
                </div>
                <div class="text-right mb-7">
                    <a href="/forgot-password" class="m-0 text-xs text-darkgray-400">Forgot Password?</a>
                </div>
                <div class="text-center">
                    <button-component
                        :disabled="loader"
                        text="Login"
                        :type="'submit'"
                    />
                </div>
            </form>

            <div class="mt-8 text-center">
                <p class="text-darkgray-400">Dont have an account yet? <a href="/signup" class="font-bold text-primary-500">Sign up now</a></p>
            </div>
        </div>
        <PageLoader v-if="loader" />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
// import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import { ref, onMounted } from 'vue';
import Storage from '@/helpers/storage';
import AuthService from "@/classes/AuthService";
import { useRoute } from 'vue-router';

const route = ref<any>(null);

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref()
const errors = ref([])
const maintenanceMode = ref<boolean>(false)
const token = ref<string>('')

const form = ref({
    email: '',
    password: '',
})

const login = () => {
    loader.value = true;

    AuthService.login(form.value.email, form.value.password)
    .then((result: any) => {
        loader.value = false;

        if(result.data.access_token) {
            const tokens = new Storage();
            const accessToken = `${result.data.token_type} ${result.data.access_token}`;
            const hybrainToken = result.data.hybrain;

            tokens.set('access_token', accessToken)
            tokens.set('hybrain_token', hybrainToken)
            tokens.set('patient', JSON.stringify(result.data.patient))

            window.location.replace('/overview');
        }
    }).catch( (err: any) => {
        loader.value = false;
        showError.value = true;
        errorMsg.value = err.response.data.message;

        if(err.response.status == 422) {
            errors.value = err.response.data.errors;
        }
    })
}

onMounted(() => {
    route.value = useRoute();
    if (route.value?.query?.token) {
        token.value = String(route.value.query.token);
    }

    maintenanceMode.value = AuthService.checkMode(token.value);
})

</script>