<template>
    <div class="flex flex-wrap space-y-5 lg:space-y-0" v-if="appointmentToday">
        <div class="relative w-full lg:w-[300px] rounded-2xl overflow-hidden bg-white border border-gray-900/[.04]">
            <div class="relative aspect-w-16 aspect-h-9">
                <img :src="branchImage" alt="" class="absolute top-0 left-0 w-full h-full object-cover">
            </div>
            <div class="p-4">
                <p class="font-bold text-darkgray-800">
                    {{ appointmentToday.branch_name }}
                </p>
            </div>
        </div>
        <div class="w-full lg:w-[calc(100%-300px)] pl-5 space-y-1 text-darkgray-900">
            <p class="font-bold">
                {{ appointmentToday.package_name ?? 'Japanese Standard Health Checkup' }} 
                {{ appointmentToday.booking_id }}
            </p>
            <p class="">
                {{ 
                    appointmentToday.booking_date ? 
                    format(new Date(appointmentToday.booking_date ), 'EEE, MMM dd') 
                    : format(new Date(), 'EEE, MMM dd') 
                }} 
                <span v-if="appointmentToday.booking_type == 'Individual'">
                    | {{ appointmentToday.booking_start_time ? format(new Date(appointmentToday.booking_start_time), 'h:mm a'): '1:15pm' }} -
                    {{ appointmentToday.booking_end_time ? format(new Date(appointmentToday.booking_end_time), 'h:mm a') : '1:30pm' }}
                </span>
            </p>
           
        </div>
    </div>
    <div v-else>
        No appointment scheduled for today
    </div>
</template>

<script setup lang="ts">
import { format } from 'date-fns'
import { computed, onMounted, ref } from 'vue';
import BranchService from "@/classes/Branch";

const props = defineProps ({
    appointmentToday: {
        type: Object,
    }
});

const branchImage = ref(null)
const storageUrl = computed(() => process.env.VUE_APP_API_STORAGE_URL);

const fetchBranch = async () => {
    console.log("Wew")
    try {
        const response = await BranchService.editBranch(props.appointmentToday?.branch_id)

        const branchResponse = response.data.branch;

        if(branchResponse) {
            // branchImage.value = branchResponse.image_path ? `${storageUrl.value}${branchResponse.image_path}` : '/assets/images/shinagawa-bgc-branch.jpeg';
            branchImage.value = branchResponse.image_path ? branchResponse.full_image_path : '/assets/images/shinagawa-bgc-branch.jpeg';
        }
    }
    catch(error: any) {
        console.log(error)
    }
}

onMounted( () => {
    setTimeout(() => {
        if(props.appointmentToday?.branch_id) {
            fetchBranch();
        }   
    }, 1000);
})
</script>