<template>
    <user-layout
    :showFooter="false"
    >
        <div class="flex flex-wrap lg:flex-nowrap relative bg-zinc-50">
            <div class="flex flex-col w-full lg:w-0 flex-1 max-h-[calc(100vh-95px)]">
                <div class="py-6 px-8 bg-white overflow-hidden relative">
                    <div class="h-[30px] relative">
                        <div class="flex items-center justify-between lg:w-full absolute"
                        :class="step == '1' ? 'left-0' : step == '2' ? 'left-1/2 -translate-x-1/2' : step == '3' ? 'right-0' : ''">
                            <div class="flex items-center">
                                <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center "
                                :class="previousStep == 1 || previousStep == 2 ? 'bg-primary-200' : 'bg-primary-500'">
                                    <span class="font-bold text-white">1</span>
                                </div>
                                <p class="text-sm font-bold ml-3 whitespace-nowrap"
                                :class="previousStep == 1 || previousStep == 2 ? 'text-primary-200' : ''">Choose Branch</p>
                            </div>
                            <ChevronRightIcon class="w-4 h-4 flex-shrink-0 mx-4"
                            :class="previousStep == 1 || previousStep == 2 ? 'text-primary-500' : 'text-primary-200'"/>
                            <div class="flex items-center"
                            :class="[
                                step == 2 || previousStep == 2 ? '' : 'grayscale opacity-[0.25]',
                            ]"
                            >
                                <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center"
                                :class="previousStep == 2 ? 'bg-primary-200' : 'bg-primary-500'">
                                    <span class="font-bold text-white">2</span>
                                </div>
                                <p class="text-sm font-bold ml-3 whitespace-nowrap"
                                :class="previousStep == 2 ? 'text-primary-200' : ''">Choose Package</p>
                            </div>
                            <ChevronRightIcon class="w-4 h-4 flex-shrink-0 mx-4"
                            :class="[step == 2 ? 'text-primary-200' : 'text-darkgray-400', previousStep == 2 ? 'text-primary-500' : '']"/>
                            <div class="flex items-center"
                            :class="step == 3 ? '' : 'grayscale opacity-[0.25]'">
                                <div class="w-[30px] h-[30px] rounded-full flex items-center justify-center bg-primary-500">
                                    <span class="font-bold text-white">3</span>
                                </div>
                                <p class="text-sm font-bold ml-3 whitespace-nowrap">Select Date  & Time</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-5 lg:p-8 h-full overflow-auto">
                    <slot />
                </div>
            </div>
            <div class="m-5 lg:m-0 w-full rounded-[10px] lg:rounded-none lg:w-[30%] max-h-[calc(100vh-95px)] lg:min-h-[calc(100vh-95px)] bg-white lg:border-l flex flex-col py-8 px-5 lg:px-7">
                <p class="text-xl font-bold text-primary-700 mb-6">Booking Summary</p>
                <BookingSummary
                @book-now="router.push('/booking/confirmation')"
                />
            </div>

            <div class="text-center mt-7 w-full pb-20 space-x-8 block lg:hidden">
                <button-component
                secondary
                :show-icon="false"
                text="Go Back" 
                @click.prevent="$emit('back')"
                />

                <button-component
                v-if="!hideBackButton"
                :disabled="!selected"
                text="Continue"
                @click.prevent="$emit('proceed')"
                />
            </div>
            
        </div>
    </user-layout>
</template>

<script setup lang="ts">
import UserLayout from '@/layouts/UserLayout.vue';
import BookingSummary from '@/components/cards/BookingSummary.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';

import { ChevronRightIcon } from "@heroicons/vue/24/outline";

import { useRouter } from 'vue-router';

const router = useRouter();

defineProps ({
    title: {
        type: String,
    },
    defaultTitle: {
        type: Boolean,
        default: true
    },
    hideNotification: {
        type: Boolean,
        default: false
    },
    step: {
        type: String,
    },
    previousStep: {
        type: String,
    },
    selected: {
        type: String
    },
    hideBackButton: {
        type: Boolean,
        default: false
    },
});

</script>