<template>
    <portal-layout
    title="Your Appointments">
        <p class="mb-5 text-neutral-700">You have <strong class="text-primary-500">{{ appointmentCount }} {{ appointmentCount == 1 ? 'Appointment ' : 'Appointments '}} </strong>scheduled today</p>

        <div class="border-b border-black/[0.04] pb-10 mb-10">
            <div v-if="appointmentToday.length == 0">
                No appointment scheduled for today
            </div>
            <AppointmentImageCard v-else :appointment-today="appointmentToday"/>

            <div 
                v-if="overview !== ''"
                class="flex p-4 mt-5 text-sm bg-primary-50 rounded-2xl space-x-2.5"
            >
                <img src="/assets/icon/ic-info.svg" alt="icon_info" class="object-contain w-6 h-6">
                <p v-html="overview"/>
            </div>
        </div>

        <div class="pb-10">
            <p class="text-xl font-bold mb-7">Your Appointments</p>

            <TabGroup>
                <TabList class="flex space-x-3 overflow-auto mb-9">
                    <Tab
                    as="template"
                    v-slot="{ selected }" 
                    >
                        <button
                        @click="params.status = 'Booked'"
                        class="py-2 text-sm font-bold transition rounded-full outline-none px-7 ring-0 h-11"
                        :class="selected ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'">
                            Upcoming
                        </button>
                    </Tab>
                    <Tab
                    as="template"
                    v-slot="{ selected }" 
                    >
                        <button
                        @click="params.status = 'Done'"
                        class="py-2 text-sm font-bold transition rounded-full outline-none px-7 ring-0 h-11"
                        :class="selected ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'">
                            Completed
                        </button>
                    </Tab>
                    <!-- <Tab
                    as="template"
                    v-slot="{ selected }" 
                    >
                        <button
                        class="py-2 text-sm font-bold transition rounded-full outline-none px-7 ring-0 h-11"
                        :class="selected ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'">
                            No Show
                        </button>
                    </Tab> -->
                    <Tab
                    as="template"
                    v-slot="{ selected }" 
                    >
                        <button
                        @click="params.status = 'Cancelled/No-Show'"
                        class="py-2 text-sm font-bold transition rounded-full outline-none px-7 ring-0 h-11"
                        :class="selected ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'">
                            Cancelled
                        </button>
                    </Tab>
                </TabList>



                <div class="flex flex-wrap items-center justify-between mb-6 space-y-4 lg:space-y-0">
                    <div class="w-full lg:w-[30%]">
                        <label for="" class="text-xs">Filter by Booking Type</label>
                        <MultiselectInput
                        v-model="params.bookingType"
                        placeholder="Show: All Appointments"
                        :options="bookingTypes"
                        />
                    </div>
                    <div class="w-full lg:w-[30%]">
                        <label for="" class="text-xs">Filter by Created Date</label>
                        <CalendarPicker 
                        v-model="params.createdDateRange"
                        placeholder="Filter by Created Date: All"
                        range
                        :inline="false"/>
                    </div>
                    <div class="w-full lg:w-[30%]">
                        <label for="" class="text-xs">Filter by Booking Date</label>
                        <CalendarPicker 
                        v-model="params.dateRange"
                        placeholder="Filter by Booking Date: All"
                        range
                        :inline="false"/>
                    </div>
                </div>

                <div v-if="appointments.length" class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
                    <div class=""
                    v-for="appointment in appointments"
                    :key="appointment">
                        <AppointmentCard 
                        @click="redirectToAppointment(appointment)"
                        :item="appointment"
                        :badge="checkReadStatus(appointment)"
                        />
                    </div>
                </div>

                <div v-else>
                    <p>No appointments yet.</p>
                </div>
           
            </TabGroup>
        </div>

        <PageLoader v-if="loader" />

        <ErrorModal
        width="w-[548px]"
        title="Error"
        action-text="Close"
        cancel-text="Go back"
        :show="showError"
        @close="showError = false"
        @confirm="showError = false"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import AppointmentImageCard from '@/components/cards/AppointmentImageCard.vue';
import AppointmentCard from '@/components/cards/AppointmentCard.vue';
import CalendarPicker from "@/components/CalendarPicker.vue";
import MultiselectInput from "@/components/inputs/MultiselectInput.vue";
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import patient from "@/classes/UserData";
import { TabGroup, TabList, Tab } from '@headlessui/vue';
import { useRouter } from 'vue-router';
import { ref, computed, watch, onMounted } from 'vue';

const router = useRouter();

const patientData = computed(() => patient.getCurrentUser())

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref(null)
const appointments = ref([]);
const appointmentToday = ref([]);
const appointmentCount = ref(0);
const appointmentStatus = ref([]);
const overview = ref<string>('');

const bookingTypes = [
    { id: '1', label: 'All Appointments', value: 'All' },
    { id: '2', label: 'Individual Appointments', value: 'Individual' },
    { id: '3', label: 'Corporate Appointments', value: 'Corporate' },
    { id: '4', label: 'Soft Booking Appointments', value: 'Soft' },
];

const currentDate = new Date();

const presetDateRange = [
    new Date(currentDate.getFullYear(), 0, 1),
    new Date(currentDate.getFullYear(), 11, 31)
];

const params = ref({
    // Booked, Done, Cancelled/No-Show
    status: 'Booked',
    bookingType: 'All',
    dateRange: presetDateRange,
    createdDateRange: presetDateRange,
    patientId: patientData.value.patient_id
});

const redirectToAppointment = (appointment: object) => {
    if(appointment.booking_type == 'Soft') {
        window.location.assign(`/soft-booking-appointment/show/${appointment.id}`)
    } else {
        window.location.assign(`/appointment/show/${appointment.booking_id}`)
    }
}

const fetchData = () => {
    loader.value = true
    
    patient.getAppointments(params.value)
        .then((result: any) => {
            loader.value = false;
            appointments.value = result.data.appointments;
            appointmentToday.value = result.data.appointmentToday;
            appointmentCount.value = appointmentToday.value.length
            appointmentStatus.value = result.data.appointmentStatus;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const fetchPatientOverview = () => {
    loader.value = true
    
    patient.fetchPatientOverview()
        .then((result: any) => {
            loader.value = false;
            overview.value = result.data;
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const checkReadStatus = (appointment) => {
    if(appointment.booking_type == 'Soft') {
        return appointmentStatus.value.find(x => x.booking_id == appointment.id).read_at == null;
    } else {
        return appointmentStatus.value.find(x => x.booking_id == appointment.booking_id ? appointment.booking_id : appointment.id).read_at == null;
    }
}

watch(params.value, () => {
    fetchData();
    fetchPatientOverview();
})

onMounted(() => { fetchData() })
</script>