<template>
    <user-layout>
        <div class="w-[90%] md:w-[350px] mx-auto py-14">
            <form @submit.prevent="submit">
                <h1 class="mb-8 text-4xl font-bold text-center text-primary-500 lg:text-5xl">Sign Up</h1>
                <div class="mb-5">
                    <text-input
                        type="text"
                        placeholder="First Name"
                        v-model="form.first_name"
                        :error="errors.first_name ? errors.first_name[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <text-input
                        type="text"
                        placeholder="Middle Name"
                        v-model="form.middle_name"
                        :error="errors.middle_name ? errors.middle_name[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <text-input
                        type="text"
                        placeholder="Last Name"
                        v-model="form.last_name"
                        :error="errors.last_name ? errors.last_name[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <text-input
                        name="reg_suffix"
                        id="reg_suffix"
                        type="text"
                        placeholder="Suffix"
                        v-model="form.suffix"
                        :error="errors.suffix ? errors.suffix[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <CalendarPicker 
                        placeholder="Date of Birth"
                        v-model="form.date_of_birth"
                        :inline="false"
                        :error="errors.date_of_birth ? errors.date_of_birth[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <MultiselectInput
                        name="gender"
                        id="gender"
                        v-model="form.sex"
                        placeholder="Gender"
                        :options="genderOpt"
                        :error="errors.sex ? errors.sex[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <text-input
                        type="email"
                        v-model="form.email"
                        placeholder="Email"
                        :error="errors.email ? errors.email[0] : ''"
                        :disabled="disableEmail"
                    />
                </div>
                <div class="mb-5">
                    <text-input
                        :add_on_left="true"
                        add_on_text="+63"
                        @keypress="numberonly"
                        :placeholder="'Mobile Number'"
                        maxlength="10"
                        v-model="form.mobile_number"
                        :error="errors.mobile_number ? errors.mobile_number[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <password-input
                        id="password"
                        v-model="form.password"
                        placeholder="Password"
                        helper
                        :error="errors.password ? errors.password[0] : ''"
                    />
                </div>
                <div class="mb-5">
                    <password-input
                        id="confirm_password"
                        v-model="form.password_confirmation"
                        placeholder="Confirm Password"
                        :error="errors.password_confirmation ? errors.password_confirmation[0] : ''"
                    />
                </div>
                <div class="mb-8">
                    <checkbox-input
                        id="agree"
                        v-model="form.terms_agreed"
                        :error="errors.terms_agreed ? errors.terms_agreed[0] : ''"
                        :default-slot="false"
                    >
                        <div class="ml-2">
                            <label for="agree" class="block text-sm leading-5 text-darkgray-400">
                                By clicking “Sign Up”, I hereby agree with Shinagawa’s <a href="https://shinagawa.ph/data-privacy-notice/" target="_blank" class="text-sm text-primary-500">Terms and Conditions</a> and <a href="https://shinagawa.ph/data-privacy-notice/" target="_blank" class="text-sm text-primary-500">Privacy Policy</a>. 
                            </label>
                        </div>
                      
                    </checkbox-input>
                    <p
                        v-if="errors.terms_agreed"
                        class="text-[10px] mt-1"
                        :class="errors.terms_agreed ? 'text-red-600' : 'text-gray-800'"
                        id="terms_agreed-error"
                    >
                        {{errors.terms_agreed[0]}}
                    </p>
                </div>
                <div class="flex flex-wrap justify-center mb-10">
                    <vue-recaptcha
                        :sitekey="siteKey"
                        ref="grecaptcha"
                        @verify="verify"
                        @expired="expiredRecaptcha"
                    ></vue-recaptcha>
                    <p
                        v-if="errors.grecaptcha"
                        class="text-[10px] mt-1 w-full text-center"
                        :class="errors.grecaptcha ? 'text-red-600' : 'text-gray-800'"
                        id="grecaptcha-error"
                    >
                        {{errors.grecaptcha[0]}}
                    </p>
                </div>

                <div class="text-center">
                    <button-component
                        :disabled="loader"
                        :type="'submit'"
                        text="Sign Up"
                    />
                </div>
            </form>

            <div class="mt-8 text-center">
                <p class="text-sm text-darkgray-400">Already have an account yet? <a href="/login" class="font-bold text-primary-500">Login now</a></p>
            </div>
        </div>

        <GeneralModal
            width="w-[416px]"
            title="Congratulations!"
            content="Your account has been successfully created. Kindly verify your account through the link we sent to your email."
            action-text="Login"
            :show="show"
            @close="show = false"
            @confirm="router.push('/login'); show = false"
        />

        <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            :errors="errors"
        >
            <template #content>
                {{ errorMsg }}
            </template>
        </ErrorModal>

        <PageLoader v-if="loader" />

    </user-layout>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router';
import UserLayout from '@/layouts/UserLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TextInput from '@/components/inputs/TextInput.vue';
// import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import GeneralModal from '@/components/GeneralModal.vue';
// import SelectOptions from "@/components/inputs/SelectOptions.vue";
import MultiselectInput from '@/components/inputs/MultiselectInput.vue';
import CalendarPicker from "@/components/CalendarPicker.vue";
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';

import AuthService from "@/classes/AuthService";
import patient from "@/classes/UserData";

import { numberonly } from "@/helpers/NumberHelper";
import { VueRecaptcha } from 'vue-recaptcha';

const router = useRouter();
const route = useRoute()
const show = ref(false);
const errorMsg = ref()
const showError = ref(false)
const loader = ref(false);
const errors = ref([]);
const siteKey = process.env.VUE_APP_SITE_KEY;
const disableEmail = ref(false);

const form = ref({
    suffix: null,
    email: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    date_of_birth: null,
    sex: null,
    mobile_number: null,
    password: null,
    password_confirmation: null,
    terms_agreed: null,
    grecaptcha: null,
});

const genderOpt = patient.getGender()

const verify = (response) => { 
    form.value.grecaptcha = response;
}

const expiredRecaptcha = () => {
    form.value.grecaptcha = null;
}

const submit = () => {
    loader.value = true;

    AuthService.register(form.value)
    .then( () => {
        show.value = true;
        loader.value = false;

        setTimeout(() => {
            show.value = false;
            router.push('/login')
        }, 30000);

    }).catch( (err : any) => {
        loader.value = false;
        showError.value = true;
        errorMsg.value = err.response.data.message;

        if(err.response.status == 422) {
            errors.value = err.response.data.errors;
        }
    })
}

onMounted(() => {

    if('email' in route.query){
        form.value.email  =route.query.email
        disableEmail.value = true
    }
})
</script>