<template>
    <footer class="relative">
        <div class="py-20 frm-footer__bg-top relative z-10">
            <div
            class="frm-footer__bg-img absolute top-0 left-0 z-[-1] h-full w-full bg-no-repeat bg-cover bg-center"
            style="background-image: url('/assets/footer-bg.png')"></div>
            <div class="text-center mb-20">
                <a href="">
                    <img src="/assets/shinagawa-logo.svg" alt="logo" class="h-[55px] mx-auto invert brightness-0 contrast-100 saturate-100">
                </a>
            </div>
            <div class="flex flex-wrap justify-center px-[5%] mb-20 space-y-10 lg:space-y-0">
                <div class="text-center w-full lg:w-[20%]">
                    <MapPinIcon class="w-10 mx-auto text-primary-200 mb-4"/>
                    <a target="_blank" ref="noreferrer" href="https://goo.gl/maps/5Wjpd6hTxudHPACt8" class="text-white text-sm font-medium block mb-4">
                        8F & 23F Ore Central Tower, Bonifacio Global City, Taguig
                    </a>
                    <p class="text-primary-200 font-medium">Address</p>
                </div>
                <span class="hidden lg:block w-[1px] h-20 bg-primary-200 mx-10 self-center" />
                <div class="text-center w-full lg:w-[20%]">
                    <EnvelopeIcon class="w-10 mx-auto text-primary-200 mb-4"/>
                    <a target="_blank" ref="noreferrer" href="mailto:info@shinagawa-healthcare.ph" class="text-white text-sm font-medium block">
                        info@shinagawa-healthcare.ph
                    </a>
                    <a target="_blank" ref="noreferrer" href="mailto:japanesedesk@shinagawa-healthcare.ph" class="text-white text-sm font-medium block mb-4">
                        japanesedesk@shinagawa-healthcare.ph
                    </a>
                    <p class="text-primary-200 font-medium">Mailbox</p>
                </div>
                <span class="hidden lg:block w-[1px] h-20 bg-primary-200 mx-10 self-center" />
                <div class="text-center w-full lg:w-[20%]">
                    <PhoneIcon class="w-10 mx-auto text-primary-200 mb-4"/>
                    <a target="_blank" ref="noreferrer" href="tel:(+63) 917-846-6762" class="text-white text-sm font-medium block">
                        (+63) 917-846-6762 – GLOBE
                    </a>
                    <a target="_blank" ref="noreferrer" href="tel:(+63) 962-067-3669" class="text-white text-sm font-medium block mb-4">
                        (+63) 962-067-3669 – SMART
                    </a>
                    <p class="text-primary-200 font-medium">Trunkline</p>
                </div>
                <span class="hidden lg:block w-[1px] h-20 bg-primary-200 mx-10 self-center" />
                <div class="text-center w-full lg:w-[20%]">
                    <ClockIcon class="w-10 mx-auto text-primary-200 mb-4"/>
                    <a href="#" class="text-white text-sm font-medium block mb-4">
                        Monday to Saturday 8AM to 5PM
                    </a>
                    <p class="text-primary-200 font-medium">Operating Hours</p>
                </div>
            </div>

            <div class="px-[5%] text-center">
                <div class="flex items-center justify-center space-x-2 mb-4">
                    <a
                    v-for="i in socialaccts"
                    :key="i" 
                    :href="i.link"
                    target="_blank" 
                    class="w-8 h-8 bg-white rounded-full flex items-center justify-center" 
                    ref="noreferrer">
                        <img :src="i.icon" alt="">
                    </a>
                </div>
                <p class="text-primary-200 font-medium">Copyright 2023 Shinagawa Diagnostics and Preventive Care. All Rights Reserved.</p>
            </div>
        </div>
        <div class="bg-primary-700 flex items-center justify-center flex-wrap px-[5%] pt-4 space-x-14">
            <!-- <a href="" class="text-white font-medium whitespace-nowrap mb-4">About Us</a>
            <a href="" class="text-white font-medium whitespace-nowrap mb-4">Patient Guide</a>
            <a href="" class="text-white font-medium whitespace-nowrap mb-4">News</a>
            <a href="" class="text-white font-medium whitespace-nowrap mb-4">Health Package</a>
            <a href="" class="text-white font-medium whitespace-nowrap mb-4">Specialized Health Package</a>
            <a href="" class="text-white font-medium whitespace-nowrap mb-4">Individual Rates</a> -->
            <a href="https://shinagawa.ph/data-privacy-notice/" target="_blank" class="text-white font-medium whitespace-nowrap mb-4">Terms and Conditions</a>
            <a href="https://shinagawa.ph/data-privacy-notice/" target="_blank" class="text-white font-medium whitespace-nowrap mb-4">Privacy Policy</a>
            <!-- <a href="" class="text-white font-medium whitespace-nowrap mb-4">Cookie Policy</a> -->
        </div>
    </footer>
</template>

<script setup lang="ts">
import { MapPinIcon, EnvelopeIcon, PhoneIcon, ClockIcon } from "@heroicons/vue/24/solid";

const socialaccts = [
    {
        icon: '/assets/icon/ic-facebook.svg',
        link: 'https://www.facebook.com/shinagawadiagnostic',
    },
    {
        icon: '/assets/icon/ic-instagram.svg',
        link: 'https://www.instagram.com/shinagawadiagnostic/',
    },
    {
        icon: '/assets/icon/ic-youtube.svg',
        link: 'https://www.youtube.com/@ShinagawaPhilippines',
    },
    {
        icon: '/assets/icon/ic-linkedin.svg',
        link: 'https://www.linkedin.com/in/shinagawa-philippines/',
    }
]
</script>
