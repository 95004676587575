<template>
    <div v-if="appointment">
        <portal-layout
            :title="appointment.package_name || 'TBA'"
            :hide-notification="true"
        >
            <template #fields>
                <!-- Todo: Replace with actual link once Hybrain resolves issue with API -->
                <!-- <a v-if="appointment.laboratory_results.length > 0 && testResult.lab_result_base_64" target="_blank" :href="testResultFile"> -->
                <!-- <a v-if="appointment.laboratory_results.length > 0" target="_blank" :href="testResultFile">
                    <ButtonComponent
                    text="View Results"
                    />
                </a> -->
            </template>
            
            <div v-if="!appointment.medical_questionnaire" class="flex w-full p-4 space-x-2 bg-primary-50 rounded-2xl mb-7">
                <ExclamationCircleIcon class="flex-shrink-0 w-6 h-6 text-orange-600"/>
                <div class="flex flex-wrap justify-between w-full space-y-2 lg:flex-nowrap lg:space-y-0">
                    <div class="w-full">
                        <p class="text-sm font-bold text-darkgray-800">Pending medical questionnaire</p>
                        <p class="text-sm text-darkgray-800">Please fill up your medical questionnaire before your appointment on {{ format(new Date(appointment.booking_date ), 'LLLL dd, yyyy') }}.</p>
                    </div>
                    <p 
                    class="self-center font-bold cursor-pointer text-primary-500 whitespace-nowrap"
                    @click="router.push('/medical-questionnaire/' + appointment.booking_id)">Fill Up Now</p>
                </div>
            </div>
            
            <div class="flex flex-wrap space-y-1 border-b text-darkgray-900 pb-7 mb-7 border-gray-40 lg:space-y-0">
                <div class="w-full lg:w-1/2">
                    <div class="grid grid-cols-2 gap-1">
                        <div class="col-span-1">
                            <p>Date Booked</p>
                        </div>
                        <div class="col-span-1 text-right">
                            <p class="font-bold">{{ format(new Date(appointment.booking_date ), 'LLLL dd, yyyy') }}</p>
                        </div>
                        <template v-if="appointment.booking_type == 'Individual'">
                            <div class="col-span-1">
                                <p>Time Booked</p>
                            </div>
                            <div class="col-span-1 text-right">
                                <p class="font-bold">
                                    {{
                                        appointment.booking_start_time ?
                                        new Date(appointment.booking_start_time).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute:'2-digit' }) :
                                        'TBA'
                                    }}
                                    -
                                    {{
                                        appointment.booking_end_time ?
                                        new Date(appointment.booking_end_time).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute:'2-digit' }) :
                                        'TBA'
                                    }}
                                </p>
                            </div>
                        </template>
                    </div>
                </div>
    
                <div class="w-full lg:w-1/2 lg:pl-12">
                    <div class="grid grid-cols-2 gap-1">
                        <div class="col-span-1">
                            <p>Schedule Logs</p>
                        </div>
                        <div @click="viewScheduleLogs(appointment.booking_type)" class="col-span-1 text-right cursor-pointer">
                            <p class="font-bold text-primary-500">Show</p>
                        </div>
                        <template v-if="appointment.medical_questionnaire">
                            <div class="col-span-1">
                                <p>Medical questionnaire</p>
                            </div>
                            <div class="col-span-1 text-right">
                                <p @click="viewMedicalQuestionnaire(appointment.booking_id)" 
                                class="font-bold cursor-pointer text-primary-500">View</p>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <div class="flex items-center space-x-3 mb-7">
                <button type="button" class="px-5 py-2 text-sm font-bold leading-loose transition rounded-3xl"
                    :class="tabSelected == 'Information' ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'"
                    @click="tabSelected = 'Information'"
                >
                    Information
                </button>
                <button type="button" class="px-5 py-2 text-sm font-bold leading-loose transition rounded-3xl"
                    :class="tabSelected == 'Test Results' ? 'bg-primary-500 text-white' : 'bg-zinc-40 text-primary-500 hover:bg-primary-500 hover:text-white'"
                    @click="tabSelected = 'Test Results'"
                >
                    Test Results
                </button>
            </div>


            <div
                v-if="tabSelected == 'Information'" 
                class="flex flex-wrap"
            >
                <div class="w-full lg:w-1/2">
                    <div class="lg:pb-7 mb-7 lg:border-b lg:border-gray-40">
                        <ul class="pl-5 list-disc">
                            <div v-if="appointment.package_details.length > 0">
                                <div v-for="service in appointment.package_details[0].trans_line" :key="service.package_id">
                                    <li>{{ service.items.description }}</li>
                                </div>
                            </div>
                            <div v-else>
                                <li>N/A</li>
                            </div>
                        </ul>
                    </div>
                    
                    <!-- Todo: Troubleshoot back to appointments/test results -->
                    <div class="hidden mt-12 lg:flex">
                        <a @click.prevent="backToAppointment"  class="flex items-center font-bold cursor-pointer text-primary-500">
                            <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                            Go back
                        </a>
                    </div>
                </div>
                <div class="w-full lg:w-1/2 lg:pl-12">
                    <div class="rounded-[10px] bg-white py-7 px-5 text-darkgray-900">
                        <div class="grid grid-cols-12 gap-2.5 border-b border-gray-40 pb-4 mb-4">
                            <div class="col-span-4">
                                <p class="font-bold">Branch</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ appointment.branch_name }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Package</p>
                                <p class="pl-5 font-bold">Add-on</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ appointment.package_name }}</p>
                                <div v-if="appointment.other_charges_details.length > 0">
                                    <div v-for="other_charge in appointment.other_charges_details" :key="other_charge.transaction_code">
                                        <div v-for="rider in other_charge.trans_line" :key="rider.item_id">
                                            <p>{{ rider.items?.description }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p>N/A</p>
                                </div>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Date</p>
                            </div>
                            <div class="col-span-8 text-right">
                                <p>{{ format(new Date(appointment.booking_date ), 'LLLL dd, yyyy') }}</p>
                            </div>
                            <div class="col-span-4">
                                <p class="font-bold">Time</p>
                            </div>
                            <div class="col-span-8 text-right">
                                {{
                                    appointment.booking_start_time ?
                                    new Date(appointment.booking_start_time).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute:'2-digit' }) :
                                    'TBA'
                                }}
                                -
                                {{
                                    appointment.booking_end_time ?
                                    new Date(appointment.booking_end_time).toLocaleTimeString([], { timeZone: 'UTC', hour: '2-digit', minute:'2-digit' }) :
                                    'TBA'
                                }}
                            </div>
                            <template v-if="appointment.booking_type != 'Corporate'">
                                <div class="col-span-4">
                                    <p class="font-bold">Package Fee</p>
                                </div>
                                <div class="col-span-8 text-right">
                                    <p>{{ formatter.format(packageFee) }}</p>
                                </div>
                                <div class="col-span-4">
                                    <p class="font-bold">Misc. Fees</p>
                                </div>
                                <div class="col-span-8 text-right">
                                    <p>{{ formatter.format(riderFee) }}</p>
                                </div>
                            </template>
                        </div>
                        <div class="grid grid-cols-12 gap-2.5 text-primary-500" v-if="appointment.booking_type != 'Corporate'">
                            <div class="col-span-6">
                                <p class="text-xl font-bold">Total</p>
                            </div>
                            <div class="col-span-6 text-right">
                                <p class="text-xl font-bold"> {{ formatter.format(appointment.total_fee) }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex mt-12 lg:hidden">
                    <a @click.prevent="backToAppointment"  class="flex items-center font-bold cursor-pointer text-primary-500">
                        <ChevronLeftIcon class="w-4 h-4 mr-2"/>
                        Go back
                    </a>
                </div>
            </div>

            <div v-if="tabSelected != 'Information'" class="min-h-[calc(100vh-500px)]">
                <template v-if="!testResultAdvisory">
                    <div 
                        v-if="appointment?.laboratory_results.length > 0"
                        class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6"
                    >
                        <div v-for="(item, index) in appointment?.laboratory_results" :key="index">       
                            <TestResultCard
                                :badge="false"
                                :item="item"
                                @click="viewTestResult(item.laboratory_id)"
                            />  
                        </div>
                    </div>
                    <div 
                        v-else
                        class="grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6"
                    >
                        No results 
                    </div>
                </template>

                <template v-else>
                    <h1 class="mb-6 text-4xl font-bold text-primary-500 lg:text-5xl">
                    ADVISORY
                    </h1>

                    <h3 class="mb-6 text-xl text-black-500 lg:text-xl">
                        We would like to inform you that our Test Results Page is currently underway and will soon be available on our patient portal. With this feature, you can get convenient access to your test results and receive any health information you may need with ease.
                        <br>
                        <br>
                        Stay tuned for further updates, and thank you for your patience and understanding.
                    </h3>
                </template>
                
            </div>

            <PageLoader v-if="loader" />

            <ErrorModal
            width="w-[548px]"
            title="Error"
            action-text="Close"
            cancel-text="Go back"
            :show="showError"
            @close="showError = false"
            @confirm="showError = false"
            >
                <template #content>
                    {{ errorMsg }}
                </template>
            </ErrorModal>
            
        </portal-layout>
    </div>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import PageLoader from "@/components/PageLoader.vue";
import ErrorModal from '@/components/ErrorModal.vue';
import TestResultCard from '@/views/dashboard/test-results/TestResultCard.vue';

import { ChevronLeftIcon, ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import { useRouter, useRoute } from 'vue-router';

import { ref, onMounted, computed } from 'vue';

import patient from "@/classes/UserData";
import TestResults from "@/classes/TestResults";

import { format } from 'date-fns';
import { formatter } from '@/helpers/NumberHelper';

const router = useRouter();
const route = useRoute();

const loader = ref(false)
const showError = ref(false)
const errorMsg = ref()
const appointment = ref()
const packageFee = ref(0);
const riderFee = ref(0);
const bookingId = ref(route.params.id as unknown as string);
const laboratoryId = ref(route.query.laboratory_id as unknown as string);
const testResult = ref({})
const testResultFile = ref(null);
const sampleBase64 = ref(null);

const tabSelected = ref('Information');

const calculatePackageFee = () => {
    if(appointment.value.package_details.length > 0) {
        appointment.value.package_details.forEach(packageDetail => {
            packageDetail.trans_line.forEach(trans_line => {
                packageFee.value += trans_line.price * trans_line.qty;
            });
        });
    }
}

const calculateRiderFee = () => {
    if(appointment.value.other_charges_details.length > 0) {
        appointment.value.other_charges_details.forEach(otherCharges => {
            otherCharges.trans_line.forEach(trans_line => {
                riderFee.value += trans_line.price * trans_line.qty;
            });
        });
    }
}

const fetchSelectedAppointment = () => {
    loader.value = true
    
    patient.getSelectedAppointment(bookingId.value)
        .then((result: any) => {
            loader.value = false;
            appointment.value = result.data.response;

            if(appointment.value) {
                calculatePackageFee();
                calculateRiderFee();

                // laboratoryId.value = appointment.value.laboratory_results.length > 0 ? appointment.value.laboratory_results[0].laboratory_id : null;
                // if(laboratoryId.value) {
                //     getSelectedTestResults();
                // }
            } else if(result.data.response.result_status == 500) {
                showError.value = true;
                errorMsg.value = "Something went wrong. Please try again later";
            }
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const getSelectedTestResults = () => {
    loader.value = true
    
    patient.getSelectedTestResults(laboratoryId.value)
        .then((result: any) => {
            loader.value = false;
            testResult.value = result.data.response;

            // getSampleBase64Pdf();
            setTimeout(() => {
                convertBase64ToPdf();
            }, 100)
        })
        .catch((err) => {
            loader.value = false;
            showError.value = true;
            errorMsg.value = err.response.data.message;

            if(err.response.status == 401) {
                window.location.replace('/login');
            }
        })
}

const viewTestResult = (laboratory_id:string) => {
 
    loader.value = true

    TestResults.view(laboratory_id)
        .then(({data}) => {
            loader.value = false

            const decodedString = atob(data.response.lab_result_base_64);
            const uint8Array = Uint8Array.from([...decodedString].map(char => char.charCodeAt(0)));
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const testResultFile = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = testResultFile;
            link.target = '_blank';
            link.click();

        })
        .catch((err) => {
            loader.value = false
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })


}

const getSampleBase64Pdf = () => {

    const xhr = new XMLHttpRequest();
      xhr.open('GET', '/assets/file/sampleBase64.txt', true);
      xhr.onload = () => {
        if (xhr.readyState === xhr.DONE && xhr.status === 200) {
            sampleBase64.value = xhr.responseText;
        }
      };
      xhr.send();

}

const convertBase64ToPdf = () => {
    // const decodedString = atob(testResult.value.lab_result_base_64 ? testResult.value.lab_result_base_64 : sampleBase64.value);

    if(testResult.value.lab_result_base_64) {
        const decodedString = atob(testResult.value.lab_result_base_64);
        const uint8Array = Uint8Array.from([...decodedString].map(char => char.charCodeAt(0)));
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        testResultFile.value = URL.createObjectURL(blob);
    }
}

const viewMedicalQuestionnaire = (booking_id: number) => {
    router.push('/medical-questionnaire/' + booking_id)
}

const viewScheduleLogs = (booking_type: string) => {
    if(booking_type == 'Individual') {
        window.location.assign(`/schedule-logs/${appointment.value.booking_id}?booking_type=${booking_type}`);
    } else {
        window.location.assign(`/schedule-logs/${appointment.value.corporate_booking_id}?booking_type=${booking_type}&booking_id_of_coporate_booking=${appointment.value.booking_id}`);
    }
}

const backToAppointment = () => {
    window.location.replace('appointment')
}

const testResultAdvisory = computed(() => {
    return 'VUE_APP_MODE' in process.env && process.env.VUE_APP_MODE == 'test_results';
})

onMounted(() => { 
    fetchSelectedAppointment()
})

</script>