<template>
    <div>
        <label v-if="label" class="block text-sm text-gray-900">{{
            label
        }}</label>
        <!-- <p 
            class="text-xs uppercase text-gray-900"
            :class="`${label ? 'mb-1' : ''}`"
        >{{ label }}</p> -->
        <Multiselect
            :id="id"
            :name="name"
            :mode="mode"
            :searchable="searchable"
            :placeholder="placeholder"
            :disabled="disabled"
            :options="options"
            v-model="value"
            @change="$emit('change')"
            @select="$emit('select')"
            @clear="$emit('clear')"
            class="frm-multiselect"
            :class="[error ? 'error' : '']"
        />

        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="form-error"
        >
            {{ error }}
        </p>
    </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import Multiselect from '@vueform/multiselect';

const props = defineProps({
    modelValue: {
        default: null,
        required: true
    },
    error: {
        type: String,
        default: null,
    },
    mode: {
        type: String,
        default: 'single' // single | multiple | tags
    },
    searchable: {
        type: Boolean,
        default: true
    },
    id: {
        type: String,
    },
    name: {
        type: String,
    },
    label: {
        type: String,
    },
    placeholder: {
        type: String,
        default: 'Placeholder'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    options: {
        type: Object,
        default: () => ({
            1: 'Option 1',
            2: 'Option 2',
            3: 'Option 3'
        })
    },
    helper: {
        type: String,
        default: null,
    },
});

const emit = defineEmits<{
    ( e: 'update:modelValue', val: object): void
}>()

const value = computed<object>({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
})
</script>