<template>
    <text-input
        :add_on_left="true"
        add_on_text="+63"
        @keypress="numberonly"
        :placeholder="placeholder"
        maxlength="10"
        :value="modelValue"
        :error="error"
    />
</template>

<script setup lang="ts">
import TextInput from "@/components/inputs/TextInput.vue";

defineProps({
    modelValue: {
        default: null,
        required: true,
    },
    error: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: "Mobile Number",
    },
});

const numberonly = (e) => {
    e = e ? e : window.event;
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
    } else {
        return true;
    }
};
</script>