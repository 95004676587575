<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">VI. Subjective Symptoms 自覚症状</p>
        <div class="mb-4 space-y-1 text-darkgray-800">
            <p>Check if you are experiencing any of the following</p>
            <p class="text-xs">次のいずれかの症状がある場合には、下記のボックスにチェックを入れてください。</p>
        </div>
        <div class="space-y-3 lg:columns-2">
            <checkbox-input 
                v-for="i in symptoms"
                :key="i"
                :id="i.label"
                :label="i.label"
                :rounded="false"
                :disabled="disableInputs"
                :is-checked="selectedVal.includes(i.value)"
                text-color="text-darkgray-800"
                @change="addSymptom(i.value)"
            />

            <template v-if="hasOthers?.is_checked">
                <checkbox-input
                    :is-checked="true"
                    :disabled="disableInputs"
                    text-color="text-darkgray-800"
                    :rounded="false"
                    label="Others /その他"
                />

                <div 
                    class="col-span-6 mt-3 pl-7"
                >
                    <text-input
                        v-model="hasOthers.others_description"
                        id="test_result_others"
                        type="text"
                        placeholder="Type here"
                        :disabled="disableInputs"
                    />
                </div>
            </template>
            <template v-else>
                <checkbox-input
                    v-model="showOthers"
                    id="others"
                    :rounded="false"
                    :disabled="disableInputs"
                    text-color="text-darkgray-800"
                    
                    label="Others /その他"
                />

                <div 
                    class="col-span-6 mt-3 pl-7"
                >
                    <text-input
                        v-if="showOthers"
                        v-model="others"
                        id="test_result_others"
                        type="text"
                        placeholder="Type here"
                    />
                </div>
            </template>

        </div>
    </div>
</template>

<script setup lang="ts">
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import MedicalService from '@/classes/MedicalService';
import TextInput from '@/components/inputs/TextInput.vue';
import { computed, ref, watch, onMounted } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: false
    },
})

const symptoms = MedicalService.getSymptoms()
const item = ref(props.form)
const others = ref<string>('')
const showOthers = ref<boolean>(false)

const selectedVal = computed(() => item.value?.map(a => a.symptoms));

const hasOthers = computed(() => {
    
    const index = item.value.findIndex(i => i.symptoms === "Others");
    
    return index != -1 ? item.value[index] : {};
})

const addSymptom = (symptoms: string) => {
    if(!item.value.some(e => e.symptoms === symptoms)) {
        item.value.push({ symptoms,  is_checked: true })
    } else {
        item.value.splice(item.value.findIndex(i => i.symptoms === symptoms), 1);
    }
}

const othersSymptoms = () => {
    const index = item.value.findIndex(i => i.symptoms === "Others");
    if (!props.disableInputs) {
    
        if (index != -1) {
            item.value.splice(index, 1);
        } 
        
        item.value.push({ symptoms: "Others", is_checked: true, "others_description": others.value })
    } 
}

watch(
    () => others.value,
    othersSymptoms
);
</script>