<template>
    <portal-layout
    :notifCount="notifCount"
    :defaultTitle="false">
        <template #title>
            <div class="flex items-center justify-between w-full">
                <h1 class="text-darkgray-400 text-4xl">Good morning, <span class="text-primary-700 font-bold"> {{ patientData?.first_name }}!</span></h1>
                <div class="relative cursor-pointer" @click.prevent="router.push('/notifications')">
                    <BellIcon class="w-7 h-7" />
                    <div class="has-notif w-4 h-4 absolute right-0 top-0 border-4 border-white rounded-full bg-red-700"></div>
                </div>
            </div>
        </template>
        <p class="text-neutral-700 mb-5">You have <strong class="text-primary-500">{{ ongoingCount }} {{ ongoingCount == 1 ? 'Appointments ' : 'Appointment '}} </strong>scheduled today</p>

        <div class="border-b border-black/[0.04] pb-10 mb-10">
            <AppointmentImageCard 
                :appointment-today="ongoingAppointment[0]" 
            />
        </div>

        <div class="pb-10">
            <p class="text-xl font-bold mb-3">Upcoming Appointments</p>
            <div class="flex flex-wrap items-end">
                <div class="w-full mb-8 lg:mb-0 lg:w-[336px]">
                    <CalendarPicker  :appointments="upcomingAppointments" />
                </div>
                <div class="w-full lg:w-[calc(100%-336px)] lg:pl-6 relative" v-if="upcomingAppointments">
                    <div class="text-right mb-12 hidden lg:block"  v-if="upcomingAppointments?.length > 0">
                        <p @click.prevent="router.push('/appointment')" class="font-semibold text-sm text-primary-500 cursor-pointer">View All</p>
                    </div>
                    <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
                        <div class="" v-for="(upcoming, key) in upcomingAppointments" :key="key">
                            <AppointmentCard 
                                @click="redirectToAppointment(upcoming)" 
                                :item="upcoming"
                                :badge="checkReadStatus(upcoming)"
                            />
                        </div>
                    </div>
                    <div class="text-left mt-4 block lg:hidden"  v-if="upcomingAppointments?.length > 0">
                        <p @click.prevent="router.push('/appointment')" class="font-semibold text-sm text-primary-500 cursor-pointer">View All</p>
                    </div>
                    <div class="h-[300px]" v-if="upcomingAppointments?.length == 0">
                        No Upcoming Appointment
                    </div>
                </div>
              
            </div>
        </div>
        <PageLoader v-if="loader" />
    </portal-layout>
</template>

<script setup lang="ts">
import PortalLayout from '@/layouts/PortalLayout.vue';
import CalendarPicker from '@/components/CalendarPicker.vue';
import AppointmentImageCard from '@/components/cards/AppointmentImageCard.vue';
import AppointmentCard from '@/components/cards/AppointmentCard.vue';
import PageLoader from "@/components/PageLoader.vue";
import { BellIcon } from '@heroicons/vue/24/outline';

import patient from "@/classes/UserData";
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const loader = ref(false)
const upcomingAppointments = ref([]);
const ongoingAppointment = ref([]);
const notifCount = ref(0);
const ongoingCount = ref(0);
const appointmentStatus = ref([]);

const patientData = computed(() => patient.getCurrentUser())

const redirectToAppointment = (appointment: object) => {
    if(appointment.booking_type == 'Soft') {
        window.location.assign(`/soft-booking-appointment/show/${appointment.id}`)
    } else {
        window.location.assign(`/appointment/show/${appointment.booking_id}`)
    }
}

const fetchData = () => {
    loader.value = true

    patient.getDashboard(patientData.value.patient_id)
        .then((result: any) => {
            loader.value = false
            upcomingAppointments.value = result.data.upcoming
            ongoingAppointment.value = result.data.ongoing

            notifCount.value = result.data.notifCount
            ongoingCount.value = ongoingAppointment.value.length

            appointmentStatus.value = result.data.appointmentStatus;
        })
        .catch((err) => {
            loader.value = false
            if(err.response.status == 401) {
                window.location.replace('/login')
            }
        })
}

const checkReadStatus = (appointment) => {
    if(appointment.booking_type == 'Soft') {
        return appointmentStatus.value.find(x => x.booking_id == appointment.id).read_at == null;
    } else {
        return appointmentStatus.value.find(x => x.booking_id == appointment.booking_id ? appointment.booking_id : appointment.id).read_at == null;
    }
}

onMounted( () => fetchData())

</script>