<template>
    <div class="flex flex-col lg:h-full">
        <!-- <p class="text-xl font-bold text-primary-700 mb-6">Booking Summary</p> -->
        <div class="w-full grid grid-cols-12 gap-4 pb-4 text-darkgray-800">
            <div class="col-span-4">
                <p class="font-bold">Branch</p>
            </div>
            <div class="col-span-8 text-right">
                <p>{{ selectedBranch || '--' }}</p>
            </div>
            <div class="col-span-4">
                <p class="font-bold">Package</p>
                <p class="pl-5 font-bold">Add-ons</p>
            </div>
            <div class="col-span-8 text-right">
                <p>{{ selectedPackage || '--' }}</p>
                <template v-if="selectedAddOns">
                    <p class="text-xs" v-for="(selectedAddOn, index) in selectedAddOns" :key="index">{{ selectedAddOn.item_name }}</p>
                </template>
                <template v-else>
                    <p>--</p>
                </template>
            </div>
            <div class="col-span-4">
                <p class="font-bold">Date</p>
            </div>
            <div class="col-span-8 text-right">
                <p>{{ selectedDate || '--' }}</p>
            </div>
            <div class="col-span-4">
                <p class="font-bold">Time</p>
            </div>
            <div class="col-span-8 text-right">
                <p>{{ selectedTime || '--' }}</p>
            </div>
        </div>
        <div class="w-full mt-auto text-darkgray-800">
            <div class="grid grid-cols-12 gap-4 pb-4 mb-4 border-b border-gray-40">
                <div class="col-span-4">
                    <p class="font-bold">Package Fee</p>
                </div>
                <div class="col-span-8 text-right">
                    <p>{{ formatter.format(packageFee) || 0.00 }}</p>
                </div>
                <div class="col-span-4">
                    <p class="font-bold">Misc. Fees</p>
                </div>
                <div class="col-span-8 text-right">
                    <p>{{ formatter.format(miscFee) || 0.00 }}</p>
                </div>
            </div>
            <div class="grid grid-cols-12 gap-2.5 mb-5"
            :class="totalColor ? totalColor : 'text-darkgray-800'">
                <div class="col-span-6">
                    <p class="font-bold text-xl">Total</p>
                </div>
                <div class="col-span-6 text-right">
                    <p class="font-bold text-xl">{{ formatter.format(totalFee) || 0.00 }}</p>
                </div>
            </div>
            <ButtonComponent
            :disabled="!bookingStoreState.canSubmitBooking"
            @click.prevent="$emit('book-now')"
            class="w-full"
            text="Book Now"
            />
        </div>
    </div>
    <PageLoader v-if="loader" />
</template>
<script setup lang="ts">
import ButtonComponent from '@/components/ButtonComponent.vue';

import PageLoader from "@/components/PageLoader.vue";
import { useBookingStore } from '@/store/booking';
import { computed, ref } from 'vue';
import { formatter } from "@/helpers/NumberHelper";


defineProps ({
    totalColor: {
        type: String,
    },
    defaultTitle: {
        type: Boolean,
        default: true
    },
    hideNotification: {
        type: Boolean,
        default: false
    },
});

// State Instance
const bookingStoreState = useBookingStore();

// Data
const loader = ref(false);
const selectedBranch = computed(() => bookingStoreState.bookingSummary?.selectedBranch);
const selectedPackage = computed(() => bookingStoreState.bookingSummary?.selectedPackage);
const selectedAddOns = computed(() => bookingStoreState.bookingSummary?.selectedAddOns);
const selectedDate = computed(() => bookingStoreState.bookingSummary?.selectedDate);
const selectedTime = computed(() => bookingStoreState.bookingSummary?.selectedTime);
const packageFee = computed(() => bookingStoreState.bookingSummary?.packageFee);
const miscFee = computed(() => bookingStoreState.bookingSummary?.miscFee);
const totalFee = computed(() => bookingStoreState.bookingSummary?.totalFee);

</script>