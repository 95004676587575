<template>
    <div class="p-6 mb-8 bg-white rounded-lg lg:p-8">
        <p class="mb-6 text-xl font-bold text-primary-500">VII. Occupation 職業</p>
        <div class="grid grid-cols-1 lg:grid-cols-12 gap-x-8 gap-y-4">
            <div class="col-span-full lg:col-span-6">
                <p class="font-bold text-darkgray-800">1. Job description/仕事内容</p>
                <text-input
                    v-model="item.job_title"
                    id="job-title"
                    type="text"
                    placeholder="Job description/仕事内容"
                    :disabled="disableInputs"
                />
            </div>
            <div class="space-y-3 col-span-full lg:col-span-6">
                <p class="font-bold text-darkgray-800">2. Work hours / 労働時間</p>
                <text-input
                    id="days_per_week"
                    type="text"
                    placeholder="Days per week / 週の勤務日数"
                    :disabled="disableInputs"
                    @keypress="numberonly()"
                    v-model="item.days_per_week"
                />
                <text-input
                    v-model="item.hours_per_day"
                    id="work-hours"
                    type="text"
                    placeholder="Hours per day / 1日の勤務時間"
                    @keypress="numberonly()"
                    :disabled="disableInputs"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import TextInput from '@/components/inputs/TextInput.vue';
import { numberonly } from '@/helpers/NumberHelper'
import { ref } from 'vue';

const props = defineProps({
    form: {
        type: Object,
        required: true
    },
    disableInputs: {
        type: Boolean,
        required: true
    },
})

const item = ref(props.form)

</script>