<template>
    <div class="w-full">
        
        <div
            class="relative rounded-full bg-zinc-50 border border-gray-900/[.04]"
            :class="[add_on_right || add_on_left ? 'flex' : '', label ? 'mt-1' : '', error ? 'border border-red-300' : '']"
        >
            <textarea
                v-if="textarea"
                :name="name"
                :id="id"
                :rows="textAreaRows"
                class="block w-full pr-10 text-sm rounded-lg  focus:outline-none focus:ring-0 disabled:bg-gray-100"
                :class="
                    error
                        ? 'text-red-900 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
                        : 'text-gray-800 border-gray-300 placeholder-gray-500 focus:border-gray-900'
                "
                :placeholder="placeholder"
                :value="modelValue"
                @input="$emit('update:modelValue', $event.target.value)"
                :disabled="disabled"
                :maxlength="maxlength"
            />

            <span
                v-if="add_on_left"
                class="
                    inline-flex
                    items-center
                    px-5
                    rounded-l-full
                    border-r
                    border-gray-900/[.04]
                    bg-transparent
                    text-gray-500
                    text-sm
                    m-0
                "
                >{{ add_on_text }}</span
            >

            <div class="relative w-full">
                <input
                    v-if="!textarea"
                    :type="type"
                    :name="name"
                    :id="id"
                    ref="inputField"
                    class="block w-full h-12 px-5 pt-4 pb-1 text-sm border-0  frm-input disabled:bg-gray-100 focus:outline-none focus:ring-0 bg-zinc-50 peer"
                    :class="[
                        customClass,
                        error
                            ? 'rounded-full'
                            : add_on_left
                            ? 'text-gray-800 border-gray-900/[.04] placeholder-gray-20 flex-1 min-w-0 block w-full px-3 py-2.5 rounded-none rounded-r-full focus:ring-gray-900 focus:border-primary-500 text-sm'
                            : add_on_right
                            ? 'text-gray-800 border-gray-900/[.04] placeholder-gray-20 flex-1 min-w-0 block w-full px-3 py-2.5 rounded-none rounded-l-full focus:ring-gray-900 focus:border-primary-500 text-sm'
                            : 'text-gray-800 border-gray-900/[.04] placeholder-gray-20 focus:border-primary-500 rounded-full'
                    ]"
                    placeholder=" "
                    :value="modelValue"
                    @input="$emit('update:modelValue', $event.target.value)"
                    :disabled="disabled"
                    :min="min"
                    :max="max"
                    :step="step"
                    :oninput="oninput"
                    :maxlength="maxlength"
                />

                <label 
                :for="id" 
                @click="focusInput()"
                class="
                    absolute 
                    duration-300 
                    transform 
                    -translate-y-2.5
                    top-3.5
                    left-5
                    scale-[.60] 
                    origin-[0] 
                    text-sm
                    text-gray-20
                    cursor-text	
                    peer-focus:text-primary-500 
                    peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:translate-y-0 
                    peer-focus:scale-[.60] 
                    peer-focus:-translate-y-2.5"
                >{{
                    placeholder
                }}</label>
            </div>

            <slot name="password"/>

            <span
                v-if="add_on_right"
                class="
                    inline-flex
                    items-center
                    px-5
                    rounded-r-full
                    border-l
                    border-gray-900/[.04]
                    bg-zinc-50
                    text-gray-500
                    text-sm
                    m-0
                "
                >{{ add_on_text }}</span
            >
            <div
                v-if="error"
                class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none "
            >
                <ExclamationCircleIcon
                    class="w-5 h-5 text-red-500"
                    aria-hidden="true"
                />
            </div>
        </div>

        <small
            class="block mt-1 text-right"
            :class="[
                modelValue.length >= maxlength
                    ? 'text-red-600'
                    : 'text-darkgray-400',
            ]"
            v-if="showInputLimit"
        >
            {{ modelValue.length }}/{{ maxlength }}
        </small>

        <p 
            v-if="helper"
            class="text-[10px] text-gray-600 mt-1"
        >
            {{ helper }}
        </p>

        <p
            v-if="error"
            class="text-[10px] mt-1"
            :class="error ? 'text-red-600' : 'text-gray-800'"
            id="email-error"
        >
            {{ error }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { ref } from 'vue';

defineProps({
    modelValue: {
        default: null,
        required: true,
    },
    helper: {
        type: String,
        default: null,
    },
    error: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    id: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: "text",
    },
    textarea: {
        type: Boolean,
        default: false,
    },
    textAreaRows: {
        type: Number,
        default: 10,
    },
    oninput: {
        type: String,
        default: null,
    },
    add_on_right: {
        type: Boolean,
        default: false,
    },
    add_on_text: {
        type: String,
        default: null,
    },
    add_on_left: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
    },
    step: {
        type: Number,
        default: 1,
    },
    maxlength: {
        type: Number,
    },
    showInputLimit: {
        type: Boolean,
        default: false,
    },
    customClass: {
        type: String,
        default: "",
    },
});

const inputField = ref()
const focusInput = () => {
    inputField.value.focus();
};
</script>